import React, {lazy, Suspense} from 'react';
import {IPlayerProps} from '@lottiefiles/react-lottie-player';

const Animation = lazy(() => import('./animation'));

export type IProps = Omit<IPlayerProps, 'src'>;

const Positive: React.FC<IProps> = props => {
    return (
        <Suspense fallback={<div style={props?.style} />}>
            <Animation {...props} />
        </Suspense>
    );
};

export default Positive;
