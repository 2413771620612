import React from 'react';
import Paper from '@ff/web-components/components/Paper';
import Facepalm from '@/components/mascot/Facepalm';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Button from '@ff/web-components/components/Button';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';

interface IProps {
    onRetry?: () => void;
    title: React.ReactNode;
    subTitle?: React.ReactNode;
}

const GeneralError = ({onRetry, title, subTitle}: IProps) => {
    const locale: Dictionary['app'] = getLocale().app;

    return (
        <Box w={47} center>
            <Paper df ai="center" gap={4} fd="column" p={5}>
                <Box df ai="center" gap={3} fd="column">
                    <Box w="300px">
                        <Facepalm />
                    </Box>
                    <Box df fd="column" gap={1}>
                        <Text size="xl3" bold align="center">
                            {title}
                        </Text>
                        {subTitle ? (
                            <Text color="secondary" size="xs" align="center">
                                {subTitle}
                            </Text>
                        ) : null}
                    </Box>
                </Box>
                {onRetry ? (
                    <Button primary onClick={onRetry} wide>
                        {locale.btnRetry}
                    </Button>
                ) : null}
            </Paper>
        </Box>
    );
};

export default GeneralError;
