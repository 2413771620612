import React from 'react';
import defaultTheme from '../theme';
import moment from 'moment/moment';
import QuarterRoot from '../style/quarter';
import Text from '../../Text';
import Shortcut from '../style/shortcut';
import {getLocale, placeholder} from 'utils/l10n';

interface IProps {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
}

const current = moment().startOf('quarter');

const list = [
    current,
    moment(current).add(-1, 'quarter'),
    moment(current).add(-2, 'quarter'),
    moment(current).add(-3, 'quarter'),
];

export const displayAlias = {
    '1': 'I',
    '2': 'II',
    '3': 'III',
    '4': 'IV',
};

const Quarter = ({onSelect, theme}: IProps) => {
    const locale = getLocale().datePicker;
    return (
        <QuarterRoot $theme={theme}>
            {list.map((date, i) => (
                <Shortcut
                    key={i}
                    onClick={() => onSelect(date, moment(date).endOf('quarter'))}
                    $theme={theme}
                >
                    <Text size="m" color="primary">
                        {current.get('quarter') === date.get('quarter')
                            ? locale?.currentQuarter
                            : placeholder(locale?.selectedQuarter, {
                                  q: displayAlias[date.get('quarter')],
                                  y: date.format('YYYY'),
                              })}
                    </Text>
                </Shortcut>
            ))}
        </QuarterRoot>
    );
};

export default Quarter;
