import React, {useMemo} from 'react';
import SearchIcon from '@ff/web-components/components/Icon/Search';
import Input, {IProps} from '@ff/web-components/components/Input';
import useTheme from '@/hooks/useTheme';

const Search = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
    const {c} = useTheme();
    const style = useMemo(
        () => ({fontSize: c.text.fontSize.xs, lineHeight: c.text.lineHeight.xs}),
        [c],
    );
    return (
        <Input
            style={style}
            startIcon={<SearchIcon color={c.palette.grey['600']} />}
            ref={ref}
            {...props}
        />
    );
});

Search.displayName = 'Search';

export default Search;
