import {Step} from '@/features/Auth';
import axios from 'axios';
import {API_URL} from '@/features/Application/constants';

export interface QROptions {
    available: boolean;
    content: string;
}

export interface Response {
    result: Step;
    options?: {
        qr?: QROptions;
    };
    csrfToken: string;
    alias: string;
}

export default async function init(): Promise<Response> {
    return axios.post(API_URL + '/api/v1/session/init');
}
