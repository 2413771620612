import axios from 'axios';
import url from '@/api/url';

export type Destination = {
    code: string | number;
    description: string;
};

export type Response = {
    details: Destination[];
};

export interface Params {
    paymentTypeId: string;
    voen: string;
}

export default async function getDestinations(params: Params): Promise<Response> {
    return axios.post(url(`tax/get-payment-details`), params);
}
