import React, {useEffect, useState} from 'react';
import UserPreview from './style/userPreview';
import Text from '@ff/web-components/components/Text';
import icon from '@/features/TransferToAccount/img/service.svg';
import useTheme from '@/hooks/useTheme';
import UserRoot from './style/root';
import UserPicture from './style/picture';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import {get} from '@/store/runtime';

interface IProps {
    imageUrl?: string;
    initialsBgColor?: string;
    initials?: string;
}

const User = ({imageUrl, initialsBgColor, initials}: IProps) => {
    const {c} = useTheme();
    const [visible, setVisible] = useState(false);
    const userPicture = imageUrl ? categoryIcon(imageUrl, get('ENTERPRISE')) : null;

    useEffect(() => {
        const i = new Image();

        if (userPicture) {
            i.src = userPicture;
            i.onload = () => setVisible(true);
        }

        return () => {
            i.onload = undefined;
        };
    }, []);

    return imageUrl || initials ? (
        <UserRoot>
            {initials ? (
                <UserPreview $bg={visible ? 'transparent' : initialsBgColor}>
                    <Text align="center" color="white">
                        {initials}
                    </Text>
                </UserPreview>
            ) : null}
            {visible && imageUrl ? (
                <UserPicture
                    src={userPicture}
                    width={c.gutter * 8 + 'px'}
                    height={c.gutter * 8 + 'px'}
                />
            ) : null}
        </UserRoot>
    ) : (
        <img src={icon} width={c.gutter * 8 + 'px'} height={c.gutter * 8 + 'px'} alt="Transfer" />
    );
};

export default User;
