import React, {useEffect, useRef, useState} from 'react';
import Overlay from '@ff/web-components/components/Overlay';
import {set} from '@/features/Application/reducers/lang';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Chips from '@ff/web-components/components/Chips';
import Paper from '@ff/web-components/components/Paper';
import ClickAwayListener from '@ff/web-components/components/ClickAwayListener';
import {RootState} from '@/store';
import {useDispatch, useSelector} from 'react-redux';
import UserPic from '@/features/Accounts/userPic';
import {getLocale, placeholder, wordForm} from '@ff/web-components/utils/l10n';
import ArrowDown from '@ff/web-components/components/Icon/ArrowDown';
import useTheme from '@/hooks/useTheme';
import User from '@/features/Application/style/private/header/user';
import Globe from '@ff/web-components/components/Icon/Globe';
import Logout from '@ff/web-components/components/Icon/Logout';
import Option from '@/features/Application/style/private/header/option';
import {useNavigate} from 'react-router-dom';
import auth from '@/features/Auth/makeLink';
import useLang from '@/hooks/useLang';
import IconFx from '@ff/web-components/components/IconFx';
import {useMutation, useQuery} from '@tanstack/react-query';
import getList from '@/features/Accounts/api/getList';
import UserIcon from '@ff/web-components/components/Icon/User';
import accounts from '@/features/Accounts/makeLink';
import {set as setAccount} from '@/features/Accounts/reducers/account';
import {set as setCsrfToken} from '@/features/Application/reducers/csrfToken';
import queryClient from '@/api/queryClient';
import logout from '@/features/Application/api/logout';
import SnackBar from '@ff/web-components/components/SnackBar';
import {findError, ResponseError} from '@/api/protocol';

const getStore = ({account}: RootState) => account;

const Account = () => {
    const locale = getLocale().header;
    const root = useRef(null);
    const dispatch = useDispatch();
    const lang = useLang();
    const account = useSelector(getStore);
    const [active, setActive] = useState(false);
    const {c} = useTheme();
    const navigate = useNavigate();
    const listReq = useQuery({
        queryKey: ['/accounts', lang],
        queryFn: getList,
        staleTime: 1000,
    });
    const logoutReq = useMutation<{[index: string]: any}, ResponseError>({mutationFn: logout});

    useEffect(() => {
        if (logoutReq.isSuccess) {
            dispatch(setCsrfToken(null));
            dispatch(setAccount(null));
            queryClient.clear();
            navigate(auth());
        }
    }, [logoutReq.status]);

    return (
        <Box pos="relative" ref={root}>
            <User onClick={() => setActive(!active)}>
                <Box mr={1.5}>
                    <UserPic account={account} />
                </Box>
                <Box fd="column" df>
                    <Text size="xs2">{account.name}</Text>
                    <Text size="xs2" color="secondary">
                        {locale?.settings}
                    </Text>
                </Box>
                <Box ml={0.5} fs={0}>
                    <IconFx rotate={active ? '180deg' : undefined}>
                        <ArrowDown color={c.palette.grey['600']} />
                    </IconFx>
                </Box>
            </User>
            <Overlay
                active={active}
                offsetTop={8}
                position={{x: 'left', y: 'bottom'}}
                minWidth="auto"
            >
                <Box w={32}>
                    <Paper overlay py={1}>
                        {listReq.data?.length > 1 ? (
                            <Option onClick={() => navigate(accounts())}>
                                <Box mr={2.5}>
                                    <UserIcon />
                                </Box>
                                <Box>
                                    <Text>{locale?.changeAcc}</Text>
                                    <Text size="xs" color="secondary">
                                        {wordForm(
                                            placeholder(locale?.changeAccHint, {
                                                n: String(listReq.data.length),
                                            }),
                                            {
                                                n: String(listReq.data.length),
                                            },
                                        )}
                                    </Text>
                                </Box>
                            </Option>
                        ) : null}
                        <Option>
                            <Box mr={2.5}>
                                <Globe />
                            </Box>
                            <Box mr={2.5}>
                                <Text>{locale?.lang}</Text>
                            </Box>
                            <Chips
                                active={lang.toUpperCase() === 'AZ'}
                                onClick={() => dispatch(set('AZ'))}
                            >
                                AZ
                            </Chips>
                            <Chips
                                active={lang.toUpperCase() === 'RU'}
                                onClick={() => dispatch(set('RU'))}
                            >
                                RU
                            </Chips>
                        </Option>
                        <Option onClick={() => logoutReq.mutate()}>
                            <Box mr={2.5}>
                                <Logout />
                            </Box>
                            <Box mr={2.5}>
                                <Text>{locale?.logout}</Text>
                            </Box>
                        </Option>
                    </Paper>
                </Box>
            </Overlay>
            <ClickAwayListener
                node={root}
                onClickAway={active ? () => setActive(false) : undefined}
            />
            <SnackBar open={logoutReq.isError} warning>
                {logoutReq.isError ? findError(logoutReq.error)?.message : null}
            </SnackBar>
        </Box>
    );
};

export default Account;
