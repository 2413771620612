import runtime from '@/store/runtime';
import {
    FILTER_ALL,
    FILTER_INCOME,
    FILTER_SPEND,
    FILTER_TEMPLATES,
} from '@/features/Dashboard/constants';
import {IData, toSearchString} from '@/utils/url';

export interface SearchParams {
    tab?: typeof FILTER_ALL | typeof FILTER_TEMPLATES | typeof FILTER_INCOME | typeof FILTER_SPEND;
}

const link = (search?: SearchParams) => {
    const queryParams = search ? toSearchString(search as IData) : '';

    return `/u/${runtime.get('ALIAS')}/${runtime.get('ENTERPRISE')}/dashboard${
        queryParams ? '?' + queryParams : ''
    }`;
};

export const templates = () => link({tab: FILTER_TEMPLATES});

export default link;
