import axios from 'axios';
import url from '@/api/url';

export type Params = {
    fingerprint: string;
};

export default async function fingerprint(data: Params): Promise<Response> {
    return axios.post(url('auth/fingerprint'), data);
}
