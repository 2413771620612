import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
import Box from '../Box';
import Text from '../Text';
import CheckedIcon from '../Icon/Checkmark';
import Root from './style/primary/option';
import {DeepPartial} from 'themes/index';

export interface IProps {
    theme?: DeepPartial<ReturnType<typeof defaultTheme>>;
    style?: Pick<DeepPartial<ReturnType<typeof defaultTheme>>, 'primary'>;
    children?: React.ReactNode;
    value: string | number;
    title?: string;
    subTitle?: string;
    onSelect: (value: string | number) => void;
    checked?: boolean;
}

const DropDownOption = ({theme, children, title, subTitle, checked, onSelect, value}: IProps) => {
    return (
        <Root $theme={theme} title={title || String(value)} onClick={() => onSelect(value)}>
            <Box flex="2" df fd="column">
                {children ?? (
                    <>
                        <Text size="m" color="primary">
                            {title || value}
                        </Text>
                        {subTitle ? (
                            <Text size="xs" color="secondary">
                                {subTitle}
                            </Text>
                        ) : null}
                    </>
                )}
            </Box>
            <Box fs={0} pl={2.5}>
                {checked ? <CheckedIcon /> : null}
            </Box>
        </Root>
    );
};

export default theme<IProps, HTMLDivElement>(DropDownOption, 'dropDown', 'DropDownOption');
