import React from 'react';
import Icon from '../Icon/CrossFilled';
import {Theme} from 'themes/index';
import CloseActionRoot from './style/closeActionRoot';

interface IProps {
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    theme: Theme;
}

const CloseAction = ({onClick, theme}: IProps) => {
    return (
        <CloseActionRoot onClick={onClick} $theme={theme}>
            <Icon width={(theme.gutter ?? 0) * 2 + 'px'} height={(theme.gutter ?? 0) * 2 + 'px'} />
        </CloseActionRoot>
    );
};

export default CloseAction;
