'use client';
'use strict';
import React from 'react';
import theme from '../theme';
import Box, {IProps as BoxProps} from '../Box';
import rootTheme from './theme';
import Root from './style/root';

export interface IProps extends BoxProps {
    theme?: Partial<ReturnType<typeof rootTheme>>;
    children?: React.ReactNode;
}

const Skeleton = ({theme, children, ...rest}: IProps) => {
    return (
        <Box of="hidden" rad={(theme?.theme?.gutter ?? 0) * 2.5 + 'px'} {...rest}>
            <Root $theme={theme}>{children}</Root>
        </Box>
    );
};

export default theme<IProps, HTMLDivElement>(Skeleton, 'skeleton', 'Skeleton');
