import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Root = styled.div<RootProps>`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        background: transparent;
        ${({$theme}) => ({
            width: `calc(100% + ${$theme.gutter * 2}px)`,
            height: `calc(100% + ${$theme.gutter * 2}px)`,
            top: `-${$theme.gutter}px`,
            left: `-${$theme.gutter}px`,
        })}
    }
`;

export default Root;
