import React from 'react';
import {getLocale} from '@ff/web-components/utils/l10n';
import Text from '@ff/web-components/components/Text';

interface IProps {
    delay: number;
}

const Alternate: React.FC<IProps> = () => {
    const locale = getLocale().auth?.alt;

    return (
        <div>
            <Text size="xs" color="secondary" align="center">
                {locale?.label}
            </Text>
            <Text size="xs" bold align="center" cursor="pointer">
                {locale?.request}
            </Text>
        </div>
    );
};

export default Alternate;
