'use client';
'use strict';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IDropDownSearchTheme} from './theme';
import Root from './style/root';
import theme from '../theme';
import makeEvent, {SyntheticEvent} from 'utils/syntheticEvent';
import LabelWrapper from './style/labelWrapper';
import LabelHolder from '../Input/style/labelHolder';
import LabelStyle from '../Input/style/label';
import mergeTheme from 'themes/utils/merge';
import SelectStyle from './style/select';
import SelectWrapper from './style/selectWrapper';
import ArrowDown from '../Icon/ArrowDown';
import IconFx from '../IconFx';
import Box from '../Box';
import Overlay from '../Overlay';
import Paper from '../Paper';
import ClickAwayListener from '../ClickAwayListener';
import List from './style/list';
import {DeepPartial} from 'themes/index';
import MainWrapper from './style/mainWrapper';
import {FixedSizeList} from 'react-window';
import Option from '../DropDown/Option';
import pixelToNumber from 'themes/utils/pixelToNumber';
import escapeRegExp from 'utils/escapeRegExp';

export interface IOption {
    value: string | number;
    title?: string;
    subTitle?: string;
    content?: React.ReactNode;
}

export interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style' | 'onChange'> {
    style?: DeepPartial<IDropDownSearchTheme>;
    theme?: DeepPartial<IDropDownSearchTheme>;
    value?: string | number | null;
    name?: string;
    onChange?: (e: SyntheticEvent) => void;
    disabled?: boolean;
    label?: React.ReactNode;
    options?: IOption | IOption[];
    error?: boolean;
    placeholder?: string;
    itemSize?: number;
    virtualized?: boolean;
}

const DropDownSearch = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            style,
            theme,
            options,
            label,
            error,
            value,
            disabled,
            onChange,
            name,
            placeholder,
            itemSize = 42,
            virtualized,
            ...rest
        }: IProps,
        ref,
    ) => {
        const root = useRef<null | HTMLDivElement>(null);
        const input = useRef<null | HTMLInputElement>(null);
        const [hover, setHover] = useState(false);
        const [open, setOpen] = useState(false);
        const [search, setSearch] = useState('');
        const optionsList = useMemo(
            () => (options ? (Array.isArray(options) ? options : [options]) : []),
            [options],
        );
        const selected = value ? optionsList.find(item => item.value === value) : null;
        const filteredList = useMemo(() => {
            if (search) {
                const reg = new RegExp(escapeRegExp(search.trim()), 'i');

                return optionsList.filter(({title, value}) =>
                    title ? reg.test(title) : reg.test(String(value)),
                );
            } else {
                return optionsList;
            }
        }, [optionsList, search]);
        const compiledTheme = useMemo(() => {
            const compiled = mergeTheme(theme, style);
            return {
                input: {
                    ...compiled,
                    label: undefined,
                },
                label: {
                    theme: compiled.theme,
                    ...compiled.label,
                },
                list: {
                    theme: compiled.theme,
                    ...compiled.list,
                },
                paper: {
                    background: compiled.list.background,
                },
            };
        }, [theme, style]);
        const handleMouseEnter = () => setHover(true);
        const handleMouseLeave = () => setHover(false);
        const handleSelect = useCallback(
            (value: IOption['value'] | null) => {
                if (typeof onChange === 'function') {
                    onChange(
                        makeEvent({
                            name,
                            value,
                        }),
                    );
                    setOpen(false);
                }
            },
            [onChange, name],
        );
        const findOption = (query: string): IOption | undefined => {
            if (filteredList.length) {
                const reg = new RegExp(escapeRegExp(query.trim()), 'i');
                return filteredList.find(({title, value}: IOption) =>
                    title ? reg.test(title) : reg.test(String(value)),
                );
            } else {
                return undefined;
            }
        };
        const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                if (search) {
                    if (selected) {
                        // must confirm selection or clear all
                        const exact = filteredList.find(
                            item => (item.title || String(item.value)) === search,
                        );
                        if (exact && selected !== exact) {
                            handleSelect(exact.value);
                            return;
                        }

                        const found = findOption(search);

                        if (found) {
                            handleSelect(found.value);
                        }
                    } else {
                        const exact = findOption(search);

                        if (exact) {
                            handleSelect(exact.value);
                        }
                    }
                }
            }
        };
        const VirtualizedRow = ({index, style}: {index: number; style: object}) => {
            const {value: itemValue, title, subTitle, content} = filteredList[index];
            return (
                <div style={style}>
                    <Option
                        value={itemValue}
                        title={title}
                        subTitle={subTitle}
                        onSelect={handleSelect}
                        key={itemValue}
                        checked={selected?.value === itemValue}
                    >
                        {content}
                    </Option>
                </div>
            );
        };
        const handleOpenList = () => {
            setOpen(true);
            input.current?.focus();
        };
        const handleCloseList = () => {
            setOpen(false);
            if (selected) {
                if (search) {
                    // must confirm selection or clear all
                    const exact = filteredList.find(
                        item => (item.title || String(item.value)) === search,
                    );
                    if (exact) {
                        if (selected !== exact) {
                            handleSelect(exact.value);
                        }
                    } else {
                        handleSelect(null);
                    }
                } else {
                    // clear selected
                    handleSelect(null);
                }
            } else {
                if (search) {
                    // try to find selected
                    const exact = findOption(search);

                    if (exact) {
                        handleSelect(exact.value);
                    } else {
                        setSearch('');
                    }
                }
            }
        };
        const maxListHeight = pixelToNumber(compiledTheme.list.maxHeight);
        const maxOptionsHeight = itemSize * filteredList.length;

        useEffect(() => {
            setSearch(selected ? selected.title || String(selected.value) : '');
        }, [selected]);

        return (
            <Root {...rest} ref={ref || root}>
                <MainWrapper
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    $theme={compiledTheme.input}
                    $disabled={disabled}
                    $error={error}
                    $hover={hover}
                    $focus={open}
                >
                    <SelectWrapper
                        onClick={
                            disabled || !filteredList.length
                                ? undefined
                                : open
                                ? handleCloseList
                                : handleOpenList
                        }
                        $theme={compiledTheme.label}
                    >
                        <Box flex="2">
                            {label ? (
                                <LabelWrapper $theme={compiledTheme.label}>
                                    <Box pos="relative">
                                        <LabelHolder $theme={compiledTheme.label}>
                                            &nbsp;
                                        </LabelHolder>
                                        <LabelStyle
                                            $theme={compiledTheme.label}
                                            $error={error}
                                            $top={!!selected || open}
                                            $toFontSize={compiledTheme.input.fontSize}
                                            $toLineHeight={compiledTheme.input.lineHeight}
                                            $gap={2}
                                            $disabled={disabled}
                                            $defaultCursor="text"
                                        >
                                            {label}
                                        </LabelStyle>
                                    </Box>
                                </LabelWrapper>
                            ) : null}
                            <SelectStyle
                                $theme={compiledTheme.input}
                                $withLabel={!!label}
                                value={search}
                                onChange={e => {
                                    setSearch(e.currentTarget.value);
                                    if (!open) {
                                        handleOpenList();
                                    }
                                }}
                                ref={input}
                                onKeyDown={handleEnter}
                                placeholder={placeholder}
                                disabled={disabled}
                            />
                        </Box>
                        <Box pl={1.5} df ai="center" fs={0}>
                            <IconFx rotate={open ? '180deg' : '0'}>
                                <ArrowDown
                                    width="16px"
                                    height="16px"
                                    color={compiledTheme.input.theme.palette.grey['500']}
                                />
                            </IconFx>
                        </Box>
                    </SelectWrapper>
                    {filteredList.length ? (
                        <Overlay active={open} offsetTop={compiledTheme.input.theme.gutter}>
                            <Paper py={1} overlay style={compiledTheme.paper}>
                                <List $theme={compiledTheme.list}>
                                    {virtualized ? (
                                        <FixedSizeList
                                            height={
                                                maxListHeight <= maxOptionsHeight
                                                    ? maxListHeight
                                                    : maxOptionsHeight
                                            }
                                            width="100%"
                                            itemSize={itemSize}
                                            itemCount={filteredList.length}
                                        >
                                            {VirtualizedRow}
                                        </FixedSizeList>
                                    ) : (
                                        filteredList.map(
                                            ({
                                                value: itemValue,
                                                title,
                                                content,
                                                subTitle,
                                            }: IOption) => (
                                                <Option
                                                    value={itemValue}
                                                    title={title}
                                                    subTitle={subTitle}
                                                    onSelect={handleSelect}
                                                    key={itemValue}
                                                    checked={selected?.value === itemValue}
                                                >
                                                    {content}
                                                </Option>
                                            ),
                                        )
                                    )}
                                </List>
                            </Paper>
                        </Overlay>
                    ) : null}
                </MainWrapper>
                <ClickAwayListener onClickAway={handleCloseList} node={ref || root} />
            </Root>
        );
    },
);

DropDownSearch.displayName = 'DropDownSearch';

export default theme<IProps, HTMLDivElement>(DropDownSearch, 'dropDownSearch', 'DropDownSearch');
