import React from 'react';
import Shortcut from '../style/shortcut';
import {getLocale} from 'utils/l10n';
import moment from 'moment';
import Text from '../../Text';
import defaultTheme from '../theme';

export interface IProps {
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
    theme: Partial<ReturnType<typeof defaultTheme>>;
}

const Week = ({onSelect, theme}: IProps) => {
    return (
        <Shortcut onClick={() => onSelect(moment().startOf('week'), moment())} $theme={theme}>
            <Text size="m" color="primary">
                {getLocale().datePicker?.week}
            </Text>
        </Shortcut>
    );
};

export default Week;
