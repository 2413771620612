import styled from 'styled-components';

interface RootProps {
    $theme;
    $visible;
}

const Root = styled.div<RootProps>`
    position: fixed;
    margin: 0 auto;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
    flex-direction: column;
    ${({$theme, $visible}) => ({
        width: $theme.width,
        paddingTop: $theme.paddingTop,
        gap: $theme.theme.gutter + 'px',
        display: $visible ? 'flex' : 'none',
    })};
`;

export default Root;
