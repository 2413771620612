'use client';
'use strict';
import React from 'react';
import Root from './style/root';
import theme from '../theme';
import defaultTheme from './theme';

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    children: React.ReactNode;
    rotate?: string;
    scale?: string;
}

const IconFx = React.forwardRef<HTMLDivElement, IProps>(
    ({children, rotate, scale, theme}: IProps, ref) => {
        return (
            <Root ref={ref} $rotate={rotate} $scale={scale} $theme={theme}>
                {children}
            </Root>
        );
    },
);

IconFx.displayName = 'IconFx';

export default theme<IProps, HTMLDivElement>(IconFx, 'iconFx', 'IconFx');
