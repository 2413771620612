import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const CrossClose: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.707 4.293 5 3.586 3.586 5l.707.707L10.586 12l-6.293 6.293-.707.707L5 20.414l.707-.707L12 13.414l6.293 6.293.707.707L20.414 19l-.707-.707L13.414 12l6.293-6.293.707-.707L19 3.586l-.707.707L12 10.586 5.707 4.293Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(CrossClose, 'icon', 'CrossClose');
