import axios from 'axios';
import moment from 'moment/moment';
import runtime from '@/store/runtime';

const tz = 'GMT' + moment().format('ZZ');

axios.interceptors.request.use(config => {
    const enterprise = runtime.get('ENTERPRISE');
    const lang = runtime.get('LANG');
    const token = runtime.get('TOKEN');
    config.params = config.params || {};
    config.headers.set('X-Timezone', tz);
    config.params.tz = tz;

    if (enterprise) {
        config.headers.set('X-Enterprise-Id', enterprise);
        config.params.enterpriseId = enterprise;
    }

    if (lang) {
        config.headers.set('X-Language', lang.toLowerCase());
        config.params.lang = lang.toLowerCase();
    }

    if (token) {
        config.headers.set('X-Csrf-Token', token);
    }

    return config;
});

axios.interceptors.response.use(
    response => response.data,
    error => {
        if (error.response?.status === 401) {
            window.location.href = '/auth';
            return Promise.reject([]);
        } else if (error.response?.status === 469) {
            window.location.href = '/maintenance.html';
            return Promise.reject([]);
        } else {
            return Promise.reject(
                error.response
                    ? error.response.data?.errors
                        ? // bp errors
                          error.response.data.errors
                        : // 404 and other internal errors
                          [{message: error.response.statusText}]
                    : // Network Error
                      [{message: error.message}],
            );
        }
    },
);
