import axios from 'axios';
import url from '@/api/url';

export type Type = {
    id: string;
    name?: string;
    description?: string;
};

export type Response = {
    types: Type[];
};

export interface Params {
    voen: string;
}

export default async function getTypes(params: Params): Promise<Response> {
    return axios.post(url('tax/get-payment-types'), params);
}
