'use client';
'use strict';
import React, {useMemo} from 'react';
import merge from 'deepmerge';
import Context from './context';
import button from '../components/Button/theme';
import box from '../components/Box/theme';
import text from '../components/Text/theme';
import spinner from '../components/Spinner/theme';
import input from '../components/Input/theme';
import paper from '../components/Paper/theme';
import divider from '../components/Divider/theme';
import hint from '../components/Hint/theme';
import dropDown from '../components/DropDown/theme';
import error from '../components/Error/theme';
import checkbox from '../components/Checkbox/theme';
import radio from '../components/Radio/theme';
import badge from './Badge/theme';
import options from './Options/theme';
import datePicker from './DatePicker/theme';
import iconButton from './IconButton/theme';
import chips from './Chips/theme';
import iconFx from './IconFx/theme';
import skeleton from './Skeleton/theme';
import modal from './Modal/theme';
import textArea from './TextArea/theme';
import snackBar from './SnackBar/theme';
import link from './Link/theme';
import badgeButton from './BadgeButton/theme';
import dropDownSearch from './DropDownSearch/theme';
import {Mode, Theme} from 'themes/index';
import lightPalette, {LIGHT_MODE} from 'themes/light';
import darkPalette, {DARK_MODE} from 'themes/dark';
import icon from './Icon/theme';

const getValue = (custom, mode) => {
    const defaultPalette = mode
        ? mode === DARK_MODE
            ? darkPalette
            : mode === LIGHT_MODE
            ? lightPalette
            : lightPalette
        : lightPalette;
    custom = custom || {};
    const computedPalette: Theme = {...defaultPalette, ...custom.theme};

    return merge(
        {
            box: box(computedPalette),
            text: text(computedPalette),
            button: button(computedPalette),
            spinner: spinner(computedPalette),
            input: input(computedPalette),
            paper: paper(computedPalette),
            divider: divider(computedPalette),
            hint: hint(computedPalette),
            dropDown: dropDown(computedPalette),
            error: error(computedPalette),
            checkbox: checkbox(computedPalette),
            radio: radio(computedPalette),
            badge: badge(computedPalette),
            options: options(computedPalette),
            datePicker: datePicker(computedPalette),
            iconButton: iconButton(computedPalette),
            chips: chips(computedPalette),
            iconFx: iconFx(computedPalette),
            skeleton: skeleton(computedPalette),
            modal: modal(computedPalette),
            textArea: textArea(computedPalette),
            snackBar: snackBar(computedPalette),
            link: link(computedPalette),
            badgeButton: badgeButton(computedPalette),
            icon: icon(computedPalette),
            dropDownSearch: dropDownSearch(computedPalette),
        },
        {
            ...(custom || {}),
            theme: computedPalette,
        },
    );
};

interface IThemeProvider {
    theme?: {
        theme?: Theme;
        button?: Partial<ReturnType<typeof button>>;
        text?: Partial<ReturnType<typeof text>>;
        spinner?: Partial<ReturnType<typeof spinner>>;
        input?: Partial<ReturnType<typeof input>>;
        box?: Partial<ReturnType<typeof box>>;
        paper?: Partial<ReturnType<typeof paper>>;
        divider?: Partial<ReturnType<typeof divider>>;
        hint?: Partial<ReturnType<typeof hint>>;
        dropDown?: Partial<ReturnType<typeof dropDown>>;
        error?: Partial<ReturnType<typeof error>>;
        checkbox?: Partial<ReturnType<typeof checkbox>>;
        radio?: Partial<ReturnType<typeof radio>>;
        badge?: Partial<ReturnType<typeof badge>>;
        options?: Partial<ReturnType<typeof options>>;
        datePicker?: Partial<ReturnType<typeof datePicker>>;
        iconButton?: Partial<ReturnType<typeof iconButton>>;
        chips?: Partial<ReturnType<typeof chips>>;
        iconFx?: Partial<ReturnType<typeof iconFx>>;
        skeleton?: Partial<ReturnType<typeof skeleton>>;
        modal?: Partial<ReturnType<typeof modal>>;
        textArea?: Partial<ReturnType<typeof textArea>>;
        snackBar?: Partial<ReturnType<typeof snackBar>>;
        link?: Partial<ReturnType<typeof link>>;
        badgeButton?: Partial<ReturnType<typeof badgeButton>>;
        icon?: Partial<ReturnType<typeof icon>>;
        dropDownSearch?: Partial<ReturnType<typeof dropDownSearch>>;
    };
    children?: React.ReactNode;
    mode?: Mode;
}

const ThemeProvider = ({theme, children, mode}: IThemeProvider) => {
    const value = useMemo(() => getValue(theme, mode), [theme, mode]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ThemeProvider;
