'use client';
'use strict';
import React, {useMemo} from 'react';
import theme from '../theme';
import defaultTheme from './theme';
import Root from './style/root';

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    children?: React.ReactElement;
    primary?: boolean;
    small?: boolean;
    secondary?: boolean;
}

const IconButton = React.forwardRef<HTMLDivElement, IProps>(
    ({primary, small, secondary, theme, children, ...rest}: IProps, ref) => {
        const computedTheme = useMemo(() => {
            if (primary) {
                return theme?.primary;
            } else if (small) {
                return theme?.small;
            } else if (secondary) {
                return theme?.secondary;
            } else {
                return theme?.primary;
            }
        }, [primary, small, theme]);

        return (
            <Root $theme={computedTheme} {...rest} ref={ref}>
                {children
                    ? React.cloneElement(children, {
                          width: '100%',
                          height: '100%',
                          color: computedTheme?.color,
                      })
                    : children}
            </Root>
        );
    },
);

IconButton.displayName = 'IconButton';

export default theme<IProps, HTMLDivElement>(IconButton, 'iconButton', 'IconButton');
