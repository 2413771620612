import axios from 'axios';
import url from '@/api/url';

export type Response = {
    response: {
        data: {
            fee: number;
        };
    };
};

export type Params = {
    senderCardId: string;
    iban: string;
    amount: number;
};

export default async function getCommission(params: Params): Promise<Response> {
    return axios.post(url('business/payment/fee'), params);
}
