import axios from 'axios';
import url from '@/api/url';

export type Response = {
    data: {
        isLate?: boolean;
        title: string;
    };
};

export default async function getCreditState(): Promise<Response> {
    return axios.get(url('credit-state'));
}
