import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Question: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7-2.154C9 8.2 10.42 7 12 7s3 1.2 3 2.846c0 1.302-.926 1.963-1.46 2.296h-.002c-.226.14-.362.25-.443.35a.396.396 0 0 0-.095.277v.731h-2v-.73c0-1.318.935-1.987 1.483-2.327.22-.137.351-.242.428-.338a.368.368 0 0 0 .089-.26C13 9.452 12.63 9 12 9s-1 .452-1 .846H9ZM11 17v-2h2v2h-2Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Question, 'icon', 'Question');
