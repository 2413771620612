import axios from 'axios';
import url from '@/api/url';

export type Response = {
    data: {
        cardBankId: string;
        iban: string;
        displayedInfo: {
            currencySymbol: string;
            maskedNumber: string;
            ownerShortName: string;
            title: string;
            subtitle: string;
        };
        balances: {
            amount: number;
            creditLimit: number;
            creditUsed: number;
            internetLimit: number;
            internetUsed: number;
            selfBalance: number;
            creditMinPayment: number;
            creditMinPaymentDate: number;
            currency: string;
        };
        id: number;
    };
};

export const QUERY_KEY = '/balances';

export default async function getBalance(): Promise<Response> {
    return axios.get(url('balances'));
}
