import {createSlice} from '@reduxjs/toolkit';
import runtime from '@/store/runtime';

const csrfToken = createSlice({
    name: 'csrfToken',
    initialState: null,
    reducers: {
        set(state, action) {
            if (action.payload !== state) {
                runtime.set('TOKEN', action.payload);
                return action.payload;
            } else {
                return state;
            }
        },
    },
});

export const {set} = csrfToken.actions;
export default csrfToken.reducer;
