import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Calendar: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17 2v2h4v17H3V4h4V2h2v2h6V2h2ZM7 6v2h2V6h6v2h2V6h2v4H5V6h2Zm-2 6v7h14v-7H5Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Calendar, 'icon', 'Calendar');
