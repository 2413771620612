import styled from 'styled-components';

interface QuarterRootProps {
    $theme;
}

const QuarterRoot = styled.div<QuarterRootProps>`
    ${({$theme}) => ({
        background: $theme.theme.palette.background.default,
        padding: `${$theme.theme.gutter}px 0`,
        borderTopRightRadius: `${$theme.theme.gutter * 2.5}px`,
        borderBottomRightRadius: `${$theme.theme.gutter * 2.5}px`,
        borderLeft: `1px solid ${$theme.theme.palette.grey['400']}`,
        boxShadow: $theme.theme.palette.shadow.default,
    })}
`;

export default QuarterRoot;
