import axios from 'axios';
import {Params as SaveParams} from '@/features/TransferToTaxService/api/saveForm';
import {Template} from '@/features/Templates/api/getList';
import url from '@/api/url';
import {PAYMENT_TYPE} from '@/features/TransferToTaxService/constants';
import {Response as BalanceResponse} from '@/features/Accounts/api/getBalance';

export type Response = {
    [index: string]: string;
};

export interface Params extends SaveParams {
    id: Template['id'];
    cardId: BalanceResponse['data']['id'];
}

export default async function saveTaxTemplate(params: Params): Promise<Response> {
    return axios.put(url('payments/templates'), {...params, type: PAYMENT_TYPE});
}
