import React, {useCallback, useState} from 'react';
import Text from '@ff/web-components/components/Text';
import Box from '@ff/web-components/components/Box';
import Copy from '@ff/web-components/components/Icon/Copy';
import useTheme from '@/hooks/useTheme';
import copyText from '@/utils/copyText';
import SnackBar from '@ff/web-components/components/SnackBar';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';

interface IProps {
    name: string;
    value: string;
}

const Item = ({name, value}: IProps) => {
    const locale: Dictionary = getLocale();
    const {c} = useTheme();
    const [snack, setSnack] = useState<{msg: string; warning?: boolean}>();
    const handleCopy = useCallback(async () => {
        try {
            await copyText(value);
            setSnack({msg: locale.requisites.copy});
        } catch (e) {
            setSnack({msg: e.message, warning: true});
        }
    }, [value]);

    return (
        <>
            <SnackBar
                open={!!snack}
                warning={snack?.warning}
                onRemove={() => setTimeout(() => setSnack(null), 0)}
            >
                {snack?.msg}
            </SnackBar>
            <Box df fd="column">
                <Text color="secondary" size="xs">
                    {name}
                </Text>
                <div>
                    <Text size="m" cursor="pointer" onClick={handleCopy}>
                        <Box df ai="center">
                            <Box mr={1}>{value}</Box>
                            <Copy
                                width={c.gutter * 2 + 'px'}
                                height={c.gutter * 2 + 'px'}
                                color={c.palette.grey['600']}
                            />
                        </Box>
                    </Text>
                </div>
            </Box>
        </>
    );
};

export default Item;
