import styled from 'styled-components';

interface LabelWrapperProps {
    $theme;
    $padLeft?;
    $padRight?;
}

const LabelWrapper = styled.div<LabelWrapperProps>`
    position: relative;
    ${({$theme, $padLeft, $padRight}) => ({
        paddingTop: $theme.theme.gutter + 'px',
        paddingLeft: $padLeft ? $theme.theme.gutter * 6.5 + 'px' : $theme.theme.gutter * 2 + 'px',
        paddingRight: $padRight ? $theme.theme.gutter * 4.5 + 'px' : $theme.theme.gutter * 2 + 'px',
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
    })}
`;

export default LabelWrapper;
