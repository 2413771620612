'use client';
'use strict';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import defaultTheme from './theme';
import Root from './style/primary/root';
import theme from '../theme';
import makeEvent, {SyntheticEvent} from 'utils/syntheticEvent';
import LabelWrapper from './style/primary/labelWrapper';
import LabelHolder from '../Input/style/labelHolder';
import LabelStyle from '../Input/style/label';
import mergeTheme from 'themes/utils/merge';
import SelectStyle from './style/primary/select';
import SelectWrapper from './style/primary/selectWrapper';
import ArrowDown from '../Icon/ArrowDown';
import IconFx from '../IconFx';
import Box from '../Box';
import Overlay from '../Overlay';
import Paper from '../Paper';
import Option from './Option';
import ClickAwayListener from '../ClickAwayListener';
import List from './style/primary/list';
import primaryTheme from './theme/primary';
import {DeepPartial} from 'themes/index';

export interface IOption {
    value: string | number;
    title?: string;
    subTitle?: string;
    content?: React.ReactNode;
}

export interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style' | 'onChange'> {
    style?: DeepPartial<ReturnType<typeof primaryTheme>>;
    theme?: DeepPartial<ReturnType<typeof defaultTheme>>;
    value?: string | number | null;
    name?: string;
    onChange?: (e: SyntheticEvent) => void;
    disabled?: boolean;
    label?: React.ReactNode;
    options?: IOption | IOption[];
    error?: boolean;
    onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const DropDown = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            style,
            theme,
            options,
            label,
            error,
            value,
            disabled,
            onMouseEnter,
            onMouseLeave,
            onChange,
            name,
            ...rest
        }: IProps,
        ref,
    ) => {
        const root = useRef<null | HTMLDivElement>(null);
        const [hover, setHover] = useState(false);
        const [open, setOpen] = useState(false);
        options = options ? (Array.isArray(options) ? options : [options]) : [];
        const selected = value ? options.find(item => item.value === value) : null;
        const compiledTheme = useMemo(() => {
            const compiled = mergeTheme(theme?.primary, style);
            return {
                input: {
                    ...compiled,
                    label: undefined,
                },
                label: {
                    theme: compiled.theme,
                    ...compiled.label,
                },
                list: {
                    theme: compiled.theme,
                    ...compiled.list,
                },
                paper: {
                    background: compiled.list.background,
                },
            };
        }, [theme, style]);
        const handleEnter = useCallback(
            e => {
                setHover(true);
                if (typeof onMouseEnter === 'function') {
                    onMouseEnter(e);
                }
            },
            [onMouseEnter],
        );
        const handleLeave = useCallback(
            e => {
                setHover(false);
                if (typeof onMouseLeave === 'function') {
                    onMouseLeave(e);
                }
            },
            [onMouseLeave],
        );
        const handleSelect = useCallback(
            value => {
                if (typeof onChange === 'function') {
                    onChange(
                        makeEvent({
                            name,
                            value,
                        }),
                    );
                    setOpen(false);
                }
            },
            [onChange, name],
        );

        return (
            <Root
                {...rest}
                ref={ref || root}
                $theme={compiledTheme.input}
                $disabled={disabled}
                $error={error}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                $hover={hover}
                $focus={open}
            >
                <SelectWrapper
                    onClick={disabled || !options.length ? undefined : () => setOpen(!open)}
                    $theme={compiledTheme.label}
                >
                    <Box flex="2" minW="0">
                        {label ? (
                            <LabelWrapper $theme={compiledTheme.label}>
                                <Box pos="relative">
                                    <LabelHolder $theme={compiledTheme.label}>&nbsp;</LabelHolder>
                                    <LabelStyle
                                        $theme={compiledTheme.label}
                                        $error={error}
                                        $top={!!selected}
                                        $toFontSize={compiledTheme.input.fontSize}
                                        $toLineHeight={compiledTheme.input.lineHeight}
                                        $gap={2}
                                        $disabled={disabled}
                                        $defaultCursor="pointer"
                                    >
                                        {label}
                                    </LabelStyle>
                                </Box>
                            </LabelWrapper>
                        ) : null}
                        <SelectStyle $theme={compiledTheme.input} $withLabel={!!label}>
                            {selected?.title || selected?.value}
                        </SelectStyle>
                    </Box>
                    <Box pl={1.5} df ai="center" fs={0}>
                        <IconFx rotate={open ? '180deg' : '0'}>
                            <ArrowDown
                                width="16px"
                                height="16px"
                                color={compiledTheme.input.theme.palette.grey['500']}
                            />
                        </IconFx>
                    </Box>
                </SelectWrapper>
                {options.length ? (
                    <Overlay active={open} offsetTop={compiledTheme.input.theme.gutter}>
                        <Paper py={1} overlay style={compiledTheme.paper}>
                            <List $theme={compiledTheme.list}>
                                {options.map(
                                    ({value: itemValue, title, content, subTitle}: IOption) => (
                                        <Option
                                            value={itemValue}
                                            title={title}
                                            subTitle={subTitle}
                                            onSelect={handleSelect}
                                            key={itemValue}
                                            checked={selected?.value === itemValue}
                                        >
                                            {content}
                                        </Option>
                                    ),
                                )}
                            </List>
                        </Paper>
                    </Overlay>
                ) : null}
                <ClickAwayListener onClickAway={() => setOpen(false)} node={ref || root} />
            </Root>
        );
    },
);

DropDown.displayName = 'DropDown';

export default theme<IProps, HTMLDivElement>(DropDown, 'dropDown', 'DropDown');
