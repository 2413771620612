import styled, {keyframes} from 'styled-components';

const move = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

interface RootProps {
    $theme;
}

const Root = styled.div<RootProps>`
    position: relative;
    height: 100%;
    width: 100%;
    ${({$theme}) => ({
        background: $theme.background,
    })}
    &:after {
        animation: 1.6s linear 0.5s infinite normal none running ${move};
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: block;
        will-change: transform;
        transform: translateX(-100%);
        ${({$theme}) => ({
            background: $theme.mask.background,
        })}
    }
`;

export default Root;
