'use client';
'use strict';
import React, {useCallback, useRef, useState} from 'react';
import moment from 'moment';
import theme from '../theme';
import defaultTheme from './theme';
import DateSelector from './dateSelector';
import Paper from './style/paper';
import shortcutComponents from './shortcut';
import Box from '../Box';
import Divider from '../Divider';
import Months from './shortcut/months';
import Years from './shortcut/years';
import Quarter from './shortcut/quarter';
import QuarterWrapper from './style/quarterWrapper';

export interface DatePickerPeriodValue {
    start: string | moment.Moment | null;
    end: string | moment.Moment | null;
}

export type DatePickerDayValue = string | moment.Moment | null;

export type DatePickerValue = DatePickerPeriodValue | DatePickerDayValue;

export type Shortcuts = (typeof shortcutsList)[number];

export interface IProps {
    value?: DatePickerValue;
    onSubmit?: (value: DatePickerSelected) => void;
    onCancel?: () => void;
    minYear?: number;
    maxYear?: number;
    minMonth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    maxMonth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    allowedWeekDays?: Array<0 | 1 | 2 | 3 | 4 | 5 | 6>;
    deniedDates?: string | string[] | moment.Moment | moment.Moment[];
    allowedDates?: string | string[] | moment.Moment | moment.Moment[];
    period?: boolean;
    extended?: boolean;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    exclude?: Shortcuts[];
}

export interface DatePickerSelectedPeriod {
    start: moment.Moment | null;
    end: moment.Moment | null;
}

export type DatePickerSelectedDay = moment.Moment | null;

export type DatePickerSelected = DatePickerSelectedPeriod | DatePickerSelectedDay;

const shortcutsList = [
    'today',
    'yesterday',
    'week',
    'prevWeek',
    'month',
    'quarter',
    'year',
    'manual',
] as const;

const shortcutGroups = [
    ['today', 'yesterday'],
    ['week', 'prevWeek'],
    ['month'],
    ['quarter'],
    ['year'],
    ['manual'],
];

const DatePicker = ({
    extended,
    onCancel,
    onSubmit,
    value,
    theme,
    period,
    minYear = moment().add(-3, 'years').get('year'),
    maxYear = moment().get('year'),
    minMonth = 1,
    maxMonth = 12,
    allowedWeekDays,
    deniedDates,
    allowedDates,
    exclude,
}: IProps) => {
    const [shortcut, setShortcut] = useState<null | Shortcuts>(value ? 'manual' : null);
    const availableShortcuts = Array.isArray(exclude)
        ? shortcutsList.filter(item => !exclude.includes(item))
        : shortcutsList;
    const handleShortcutSelect = useCallback(
        (dateOrGroup: Shortcuts | moment.Moment, date?) => {
            if (typeof dateOrGroup === 'string') {
                setShortcut(dateOrGroup);
            } else if (typeof onSubmit === 'function') {
                onSubmit(period ? {start: dateOrGroup, end: date} : dateOrGroup);
            }
        },
        [period, onSubmit],
    );
    const shortcutRoot = useRef<null | HTMLDivElement>(null);

    const groupsContent = shortcutGroups
        .map(group => {
            return group
                .filter(name => availableShortcuts.includes(name as Shortcuts))
                .map(name => {
                    const Component = shortcutComponents[name];

                    return (
                        <Component
                            theme={theme}
                            key={name}
                            shortcut={name}
                            onSelect={handleShortcutSelect}
                            selected={shortcut === name}
                        />
                    );
                });
        })
        .filter(group => !!group.length);

    const shortcutsContent = (
        <Box w={40} py={1} ref={shortcutRoot} pos="relative">
            {groupsContent.map((group, i) => (
                <div key={i}>
                    {group}
                    {group !== groupsContent[groupsContent.length - 1] ? (
                        <Box px={2.5}>
                            <Divider />
                        </Box>
                    ) : null}
                </div>
            ))}
        </Box>
    );
    const quarterShortcut = shortcutRoot.current?.querySelector(
        '[data-shortcut-group-name="quarter"]',
    ) as HTMLDivElement;

    return shortcut === 'quarter' ? (
        <Box dif>
            <Paper $theme={theme}>{shortcutsContent}</Paper>
            <Box w={44} pos="relative" of="hidden">
                <QuarterWrapper $top={quarterShortcut?.offsetTop + quarterShortcut?.offsetHeight}>
                    <Quarter theme={theme} onSelect={handleShortcutSelect} />
                </QuarterWrapper>
            </Box>
        </Box>
    ) : shortcut === 'year' ? (
        <Paper $theme={theme}>
            <Box df ai="stretch">
                {shortcutsContent}
                <Box df flex="1">
                    <Divider vertical />
                </Box>
                <Box py={1} w={44} h={shortcutRoot.current?.offsetHeight + 'px'} bs="border-box">
                    <Years
                        min={minYear}
                        max={maxYear}
                        theme={theme}
                        onSelect={handleShortcutSelect}
                    />
                </Box>
            </Box>
        </Paper>
    ) : shortcut === 'month' ? (
        <Paper $theme={theme}>
            <Box df ai="stretch">
                {shortcutsContent}
                <Box df flex="1">
                    <Divider vertical />
                </Box>
                <Box py={1} w={44} h={shortcutRoot.current?.offsetHeight + 'px'} bs="border-box">
                    <Months
                        min={minMonth}
                        max={maxMonth}
                        theme={theme}
                        onSelect={handleShortcutSelect}
                    />
                </Box>
            </Box>
        </Paper>
    ) : shortcut === 'manual' ? (
        <Paper $theme={theme}>
            <Box df ai="stretch">
                {shortcutsContent}
                <Box df flex="1">
                    <Divider vertical />
                </Box>
                <Box p={2.5}>
                    <DateSelector
                        allowedDates={allowedDates}
                        deniedDates={deniedDates}
                        allowedWeekDays={allowedWeekDays}
                        maxMonth={maxMonth}
                        minMonth={minMonth}
                        maxYear={maxYear}
                        minYear={minYear}
                        period={period}
                        extended={extended}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        value={value}
                        theme={theme}
                    />
                </Box>
            </Box>
        </Paper>
    ) : (
        <Paper $theme={theme}>{shortcutsContent}</Paper>
    );
};

export default theme<IProps, HTMLDivElement>(DatePicker, 'datePicker', 'DatePicker');
