import minmax, {Options} from './minmax';
import {Rule} from '@/hooks/useValidation';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';

export default function sumMinmax<T extends object>(
    keys: Array<Partial<keyof T>>,
    min: number | null,
    max?: number | null,
    options?: Options,
): Rule<T> {
    const locale: Dictionary['app'] = getLocale().app;
    options = options || {};
    options.message = {
        min: locale.validation.minSum,
        max: locale.validation.maxSum,
        ...(options.message || {}),
    };
    return minmax<T>(keys, min, max, options);
}
