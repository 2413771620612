'use client';
'use strict';
import React, {useMemo} from 'react';
import defaultTheme from './theme';
import Root from './style/root';
import theme from '../theme';
import merge from 'themes/utils/merge';
import wideMixin from './mixins/wide';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    children: React.ReactNode;
    active?: boolean;
    wide?: boolean;
}

const Chips = React.forwardRef<HTMLDivElement, IProps>(
    ({style, theme, children, active, wide, ...rest}: IProps, ref) => {
        const computedTheme = useMemo(() => {
            let result = merge(theme, style);

            if (wide) {
                result = wideMixin(result);
            }

            return result;
        }, [theme, style, wide]);

        return (
            <Root $theme={computedTheme} $active={active} $wide={wide} ref={ref} {...rest}>
                {children}
            </Root>
        );
    },
);

Chips.displayName = 'Chips';

export default theme<IProps, HTMLDivElement>(Chips, 'chips', 'Chips');
