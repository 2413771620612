import React from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToAccount';
import {useNavigate} from 'react-router-dom';
import {getLocale} from '@ff/web-components/utils/l10n';
import Paper from '@ff/web-components/components/Paper';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Success from '@/components/Success';
import Button from '@ff/web-components/components/Button';
import dashboardLink from '@/features/Dashboard/makeLink';
import {Dictionary} from '@/locale';
import Lottie from '@/components/Lottie';
import useTheme from '@/hooks/useTheme';

interface IProps extends StepProps {
    data: State;
}

const Result = ({data}: IProps) => {
    const navigate = useNavigate();
    const locale: Dictionary = getLocale();
    const stepLocale = locale.account.result;
    const {p} = useTheme();

    return (
        <Paper p={5} mt={8}>
            <Box mb={3}>
                {data.payment.transaction.imageUrl ? (
                    <Box w="300px" h="200px">
                        <Lottie
                            src={data.payment.transaction.imageUrl}
                            style={{width: '300px', height: '200px'}}
                        />
                    </Box>
                ) : (
                    <Success />
                )}
            </Box>
            {data.payment.paymentInfo.title ? (
                <Box mb={data.payment.paymentInfo.subTitle ? 1 : 0}>
                    <Text size="xl3" bold align="center">
                        {data.payment.paymentInfo.title}
                    </Text>
                </Box>
            ) : null}
            {data.payment.paymentInfo.subTitle ? (
                <Text color={p.palette.hex.black['50']} align="center" size="xs">
                    {data.payment.paymentInfo.subTitle}
                </Text>
            ) : null}
            <Box mt={4}>
                <Button primary wide onClick={() => navigate(dashboardLink())}>
                    {stepLocale.submit}
                </Button>
            </Box>
        </Paper>
    );
};

export default Result;
