import React from 'react';
import Shortcut from '../style/shortcut';
import {getLocale} from 'utils/l10n';
import moment from 'moment';
import Text from '../../Text';
import defaultTheme from '../theme';

export interface IProps {
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
    theme: Partial<ReturnType<typeof defaultTheme>>;
}

const Today = ({onSelect, theme}: IProps) => {
    return (
        <Shortcut
            onClick={() => onSelect(moment().startOf('date'), moment().endOf('date'))}
            $theme={theme}
        >
            <Text size="m" color="primary">
                {getLocale().datePicker?.today}
            </Text>
        </Shortcut>
    );
};

export default Today;
