import axios from 'axios';
import url from '@/api/url';

export type Response = {
    response: {
        maskedPan: string;
    };
};

export default async function getCard(): Promise<Response> {
    return axios.get(url('cards/main/topup/preview'));
}
