import React, {useCallback, useEffect} from 'react';
import {STEP_FORM, STEP_RESULT} from './constants';
import useForm from '@/hooks/useForm';
import Form from './form';
import StepController from '@/components/StepController';
import Result from './result';
import Box from '@ff/web-components/components/Box';
import {Response as ConfirmResponse} from '@/features/TransferToMyCard/api/saveForm';
import {pageView} from '@/utils/gtag';

export interface State {
    step: typeof STEP_FORM | typeof STEP_RESULT;
    sum: string | null;
    currency: string;
    commission: string;
    payment?: ConfirmResponse['response'];
}

const TransferToMyCard = () => {
    const [state, onChange, setState] = useForm<State>({
        step: STEP_FORM,
        sum: null,
        currency: 'AZN',
        commission: '0',
    });
    const handleFormSubmit = useCallback(
        (data: ConfirmResponse['response']) =>
            setState({
                payment: data,
                step: STEP_RESULT,
            }),
        [],
    );

    useEffect(() => {
        pageView({path: '/card', title: 'Transfer to card'});
    }, []);

    return (
        <Box w={47} center>
            <StepController value={state.step} onChange={onChange} name="step">
                <Form id={STEP_FORM} data={state} onSubmit={handleFormSubmit} />
                <Result id={STEP_RESULT} data={state} />
            </StepController>
        </Box>
    );
};

export default TransferToMyCard;
