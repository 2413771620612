import axios from 'axios';
import {Template} from '@/features/Templates/api/getList';
import url from '@/api/url';

export type Response = {
    [index: string]: string;
};

export default async function deleteTemplate(templateId: Template['id']): Promise<Response> {
    return axios.delete(url(`payments/templates/${templateId}`));
}
