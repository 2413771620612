import React, {SyntheticEvent, useCallback, useEffect, useMemo} from 'react';
import Paper from '@ff/web-components/components/Paper';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import {getLocale} from '@ff/web-components/utils/l10n';
import Divider from '@ff/web-components/components/Divider';
import Hint from '@ff/web-components/components/Hint';
import FieldError from '@/components/FieldError';
import Question from '@ff/web-components/components/Icon/Question';
import useTheme from '@/hooks/useTheme';
import useForm from '@/hooks/useForm';
import QRCode from 'react-qr-code';
import Root from './style';
import {onSubmit} from '@/features/Auth';
import useValidation from '@/hooks/useValidation';
import useToggle from '@/hooks/useToggle';
import {useMutation} from '@tanstack/react-query';
import sendLogin, {RequestData, Response} from '@/features/Auth/api/sendLogin';
import {findError, ResponseError} from '@/api/protocol';
import LoadingButton from '@/components/LoadingButton';
import phone from '@/hooks/useValidation/phone';
import {Dictionary} from '@/locale';
import {QROptions} from '@/features/Auth/api/init';
import Phone from '@ff/web-components/components/Input/Phone';

interface IProps {
    onSubmit: onSubmit;
    options?: {
        qr?: QROptions;
    };
}

interface State {
    login: string;
}

const Login = ({onSubmit, options}: IProps) => {
    const locale: Dictionary['auth']['login'] = getLocale().auth?.login;
    const theme = useTheme();
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange] = useForm<State>({login: '+994'});
    const [valid, errors] = useValidation<State>(state, state =>
        phone<State>(['login'])({login: state.login?.replace(/\s/g, '')}),
    );
    const sendReq = useMutation<Response, ResponseError, RequestData>({
        mutationFn: sendLogin,
    });
    const handleSubmit = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            if (valid) {
                sendReq.mutate({login: state.login.replace(/[\s+]/g, '')});
            } else {
                toggleValidation(true);
            }
        },
        [valid, state, sendReq],
    );
    const fieldErrors = useMemo(() => {
        return sendReq.error
            ? {
                  global: findError(sendReq.error),
                  login: findError(sendReq.error, 'login'),
              }
            : {};
    }, [sendReq.isError]);

    useEffect(() => {
        if (sendReq.isSuccess) {
            onSubmit({
                ...sendReq.data,
                options: {...(sendReq.data.options ?? {}), login: state.login},
            });
        }
    }, [sendReq.isSuccess]);

    useEffect(() => {
        if (sendReq.isError) {
            sendReq.reset();
        }
    }, [state]);

    return (
        <Box pt={14}>
            <Root>
                <Paper py={5} px={2.5}>
                    <Box mb={5}>
                        <Text size="xl" align="center" bold ws="pre-wrap">
                            {locale.title}
                        </Text>
                    </Box>
                    {fieldErrors.global ? (
                        <Box mb={2}>
                            <Text align="center" color="error">
                                {fieldErrors.global.message}
                            </Text>
                        </Box>
                    ) : null}
                    <form method="POST" action="/" onSubmit={handleSubmit}>
                        <Box mb={2.5}>
                            <Phone
                                onChange={onChange}
                                value={state.login}
                                name="login"
                                label={locale?.labelPhone}
                                endIcon={
                                    <Hint
                                        x="left"
                                        y="top"
                                        label={<Box w={41}>{locale?.hintPhone}</Box>}
                                    >
                                        <Question color={theme.c.palette.grey['600']} />
                                    </Hint>
                                }
                                error={
                                    !!fieldErrors.login?.message || (validation && !!errors.login)
                                }
                            />
                            <FieldError>
                                {(validation && errors.login?.message) ||
                                    fieldErrors.login?.message}
                            </FieldError>
                        </Box>
                        <Box mb={5}>
                            <LoadingButton primary type="submit" loading={sendReq.isPending} wide>
                                {locale.submit}
                            </LoadingButton>
                        </Box>
                        <Box mb={5}>
                            <Divider />
                        </Box>
                        {options?.qr?.available ? (
                            <Box mb={3} df jc="center">
                                <QRCode value={options?.qr.content} size={128} />
                            </Box>
                        ) : null}
                        <Text size="xs" color="secondary" align="center">
                            {locale?.qrHint}
                        </Text>
                    </form>
                </Paper>
            </Root>
        </Box>
    );
};

export default Login;
