import React, {useCallback, useEffect} from 'react';
import {
    PAYMENT_TYPE,
    STEP_CONFIRM,
    STEP_FORM,
    STEP_RECEIVER,
    STEP_RESULT,
    STEP_TEMPLATES,
} from './constants';
import StepController from '@/components/StepController';
import useForm from '@/hooks/useForm';
import Form from './form';
import Box from '@ff/web-components/components/Box';
import Confirm from './confirm';
import Result from './result';
import Templates from '@/features/Templates/list';
import dashboardLink, {templates} from '@/features/Dashboard/makeLink';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Template} from '@/features/Templates/api/getList';
import Receiver from '@/features/TransferToTaxService/receiver';
import {ExtraField} from './api/saveForm';
import {
    Params as ConfirmParams,
    Response as ConfirmResponse,
} from '@/features/TransferToTaxService/api/confirm';
import {pageView} from '@/utils/gtag';

export interface State {
    step:
        | typeof STEP_FORM
        | typeof STEP_CONFIRM
        | typeof STEP_RESULT
        | typeof STEP_TEMPLATES
        | typeof STEP_RECEIVER;
    voen: string | null;
    type: string | null;
    dest: string | null;
    sum: string | number | null;
    commission: string;
    currency: string;
    template: Template | null;
    extraFields?: ExtraField[];
    dataForReq?: ConfirmParams;
    payment?: ConfirmResponse;
}

const defaultParams: State = {
    step: STEP_TEMPLATES,
    voen: null,
    type: null,
    dest: null,
    sum: null,
    commission: '0',
    currency: 'AZN',
    template: null,
};

const TransferToTaxService = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [state, onChange, setState] = useForm<State>(defaultParams);
    const handleFormSubmit = useCallback(
        (data: Partial<State>) =>
            setState({
                ...data,
                step: STEP_CONFIRM,
            }),
        [],
    );
    const handleReceiverSubmit = useCallback(
        (data: Partial<State>) =>
            setState({
                ...data,
                step: STEP_FORM,
            }),
        [],
    );
    const handleTemplateSelect = useCallback(
        (template: Template) =>
            setState({
                ...defaultParams,
                step: STEP_FORM,
                voen: template.templateDetails?.voen,
                sum: template.templateDetails?.amount,
                type: template.templateDetails?.paymentTypeId,
                dest: template?.templateDetails?.paymentDetail?.code,
                template,
            }),
        [],
    );
    const handleConfirmSubmit = useCallback(
        (data: State['payment']) =>
            setState({
                payment: data,
                step: STEP_RESULT,
            }),
        [],
    );

    useEffect(() => {
        pageView({path: '/tax', title: 'Transfer to tax'});
    }, []);

    return (
        <Box w={47} center>
            <StepController value={state.step} onChange={onChange} name="step">
                <Templates
                    id={STEP_TEMPLATES}
                    onCreateNew={() => setState({...defaultParams, step: STEP_RECEIVER})}
                    type={PAYMENT_TYPE}
                    onSelect={handleTemplateSelect}
                    onBack={() => navigate(dashboardLink())}
                />
                <Receiver data={state} onSubmit={handleReceiverSubmit} id={STEP_RECEIVER} />
                <Form
                    id={STEP_FORM}
                    data={state}
                    onSubmit={handleFormSubmit}
                    onBack={() => {
                        return searchParams.get('t')
                            ? navigate(templates())
                            : setState({...defaultParams, step: STEP_RECEIVER, voen: state.voen});
                    }}
                    type={PAYMENT_TYPE}
                />
                <Confirm
                    id={STEP_CONFIRM}
                    data={state}
                    onSubmit={handleConfirmSubmit}
                    type={PAYMENT_TYPE}
                />
                <Result id={STEP_RESULT} data={state} />
            </StepController>
        </Box>
    );
};

export default TransferToTaxService;
