import styled from 'styled-components';

const Header = styled.header`
    margin: 0 auto;
    ${({theme}) => theme.p.responsive.contentWidth};
    ${({theme}) => ({
        [theme.p.media.mobile]: {
            padding: `0 ${theme.c.gutter * 2.5}px`,
        },
    })}
`;

export default Header;
