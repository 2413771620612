import axios from 'axios';
import url from '@/api/url';

export type Branch = {
    imageUrl: string;
    initials: string;
    initialsBgColor: string;
    bankCode: string;
    bankName: string;
};

export type Budget = {
    code: string;
    description: string;
};

export type Response = {
    response: {
        data: {
            imageUrl?: string;
            title: string;
            subtitle: string;
            initials: string;
            initialsBgColor: string;
            limits?: {
                minAmount: number;
                maxAmount: number;
            };
            nativeClientData?: {
                voin: string;
                receiverBankName: string;
            };
            notNativeClientData?: {
                bankList: Branch[];
                budgetLevel?: Budget[];
            };
        };
    };
};

export interface Params {
    iban: string;
}

export default async function saveReceiver(params: Params): Promise<Response> {
    return axios.get(url(`business/payment`), {params});
}
