import React, {useEffect} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToAccount';
import Layout from '@/features/TransferToAccount/layout';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import {useMutation} from '@tanstack/react-query';
import confirm, {Response as ConfirmResponse} from '../api/confirm';
import {ResponseError} from '@/api/protocol';
import Divider from '@ff/web-components/components/Divider';
import useForm from '@/hooks/useForm';
import LoadingButton from '@/components/LoadingButton';
import FormError from '@/components/FormError';
import Checkbox from '@ff/web-components/components/Checkbox';
import format from '@ff/web-components/utils/formatter';
import {IBAN_FORMAT} from '@ff/web-components/components/Input/IBAN/AZ';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: ConfirmResponse['response']) => void;
    onError: (data: ResponseError) => void;
}

const IBANFormatter = (value: string) => {
    if (value) {
        return format(value, IBAN_FORMAT);
    } else {
        return value;
    }
};

const Confirm = ({data, toPrevStep, onSubmit, onError}: IProps) => {
    const leo = data.receiver.nativeClientData !== undefined;
    const [state, onChange] = useForm({addTemplate: false});
    const locale: Dictionary = getLocale();
    const formLocale: Dictionary['account']['form'] = locale.account.form;
    const confirmLocale: Dictionary['account']['confirm'] = locale.account.confirm;
    const saveReq = useMutation<ConfirmResponse, ResponseError>({
        mutationFn: () =>
            confirm({
                iban: data.iban,
                paymentDescription: data.dest,
                amount: data.sum as number,
                senderCardId: data.senderCardId,
                receiverBankCode: data.receiverBankCode || undefined,
                receiverVoin: data.receiverVoin || undefined,
                receiverName: data.receiverName || undefined,
                budgetLevelCode: data.budgetLevelCode || undefined,
                budgetClassificatorCode: data.budgetClassificatorCode || undefined,
                addTemplate: state.addTemplate,
            }),
    });
    const currency =
        locale.app.currency[data.currency.toUpperCase() as keyof typeof locale.app.currency];
    const bankBranch =
        !leo && data.receiverBankCode
            ? data.receiver.notNativeClientData.bankList.find(
                  ({bankCode}) => bankCode === data.receiverBankCode,
              )
            : null;
    const templateSelected =
        data.template &&
        data.template.templateDetails &&
        data.template.templateDetails.paymentDescription === data.dest &&
        Number(data.template.templateDetails.amount) === data.sum &&
        data.template.templateDetails.receiverBankCode === data.receiverBankCode &&
        data.template.templateDetails.receiverVoin === data.receiverVoin &&
        data.template.templateDetails.receiverIban === data.iban &&
        data.template.templateDetails.receiverName === data.receiverName;

    useEffect(() => {
        if (saveReq.isSuccess) {
            onSubmit(saveReq.data.response);
        }
    }, [saveReq.isSuccess]);

    useEffect(() => {
        if (saveReq.isError) {
            onError(saveReq.error);
        }
    }, [saveReq.isError]);

    return (
        <Layout onBack={toPrevStep} title={confirmLocale.title} img={null}>
            {saveReq.isError ? <FormError error={saveReq.error} /> : null}
            <form
                action="/"
                method="post"
                onSubmit={e => {
                    e.preventDefault();
                    saveReq.mutate();
                }}
            >
                <Box df fd="column" gap={2} mb={2} of="hidden">
                    <div>
                        <Text color="secondary" size="xs">
                            {formLocale.name}
                        </Text>
                        <div>
                            <Text ws="pre-wrap">
                                {leo ? data.receiver.title : data.receiverName}
                            </Text>
                        </div>
                    </div>
                    <div>
                        <Text color="secondary" size="xs">
                            {formLocale.iban}
                        </Text>
                        <div>{IBANFormatter(data.iban)}</div>
                    </div>
                    {data.receiverVoin ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.voen}
                            </Text>
                            <div>{data.receiverVoin}</div>
                        </div>
                    ) : null}
                    {data.receiver.nativeClientData ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.bank}
                            </Text>
                            <div>{data.receiver.nativeClientData.receiverBankName}</div>
                        </div>
                    ) : null}
                    {bankBranch ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.code}
                            </Text>
                            <div>
                                {bankBranch.bankCode}
                                {' — '}
                                {bankBranch.bankName}
                            </div>
                        </div>
                    ) : null}
                    {data.confirmData?.budgetLevel ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.budgetLevelCode}
                            </Text>
                            <div>
                                {data.confirmData.budgetLevel.code}
                                {' — '}
                                {data.confirmData.budgetLevel.description}
                            </div>
                        </div>
                    ) : null}
                    {data.confirmData?.budgetClassificator ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.budgetClassificatorCode}
                            </Text>
                            <div>
                                {data.confirmData.budgetClassificator.code}
                                {' — '}
                                {data.confirmData.budgetClassificator.description}
                            </div>
                        </div>
                    ) : null}
                    <div>
                        <Text color="secondary" size="xs">
                            {formLocale.dest}
                        </Text>
                        <div>
                            <Text ws="pre-wrap">{data.dest}</Text>
                        </div>
                    </div>
                </Box>
                <Box mb={2}>
                    <Divider />
                </Box>
                <Box df fd="column" gap={2} mb={2}>
                    <div>
                        <Text color="secondary" size="xs">
                            {formLocale.sum}
                        </Text>
                        <div>
                            {data.sum} {currency}
                        </div>
                    </div>
                    {typeof data.commission !== 'undefined' ? (
                        <div>
                            <Text color="secondary" size="xs">
                                {formLocale.commission}
                            </Text>
                            <div>
                                {data.commission
                                    ? `${data.commission} ${currency}`
                                    : confirmLocale.noCommission}
                            </div>
                        </div>
                    ) : null}
                </Box>
                {!templateSelected ? (
                    <>
                        <Box>
                            <Divider />
                        </Box>
                        <Box py={2.5}>
                            <Checkbox
                                checked={state.addTemplate}
                                name="addTemplate"
                                onChange={onChange}
                            >
                                {confirmLocale.saveTemplate}
                            </Checkbox>
                        </Box>
                    </>
                ) : null}
                <Box mb={3}>
                    <Divider />
                </Box>
                <LoadingButton primary wide type="submit" loading={saveReq.isPending}>
                    <Box df fd="column">
                        {confirmLocale.submit}
                    </Box>
                </LoadingButton>
            </form>
        </Layout>
    );
};

export default Confirm;
