export type ErrorItem = {
    message: string;
    field?: string;
};

export type ResponseError = ErrorItem[];

export const findError = (list: ResponseError, name?: string): ErrorItem | undefined => {
    return name
        ? list?.find(({field}) => field === name)
        : list?.find(({field}) => field === undefined);
};
