import lang from './lang';
import viewport from './viewport';
import fp from './fp';
import csrfToken from './csrfToken';

export default {
    lang,
    viewport,
    fp,
    csrfToken,
};
