export interface IData {
    [index: string]: string | number | boolean;
}

export function toSearchString(data: IData) {
    let result = '';

    if (data && typeof data === 'object') {
        result = Object.keys(data)
            .map(name =>
                typeof data[name] === 'boolean' && data[name]
                    ? name
                    : `${name}=${String(data[name])}`,
            )
            .join('&');
    }

    return result;
}
