import React from 'react';
import img from '../img/warning.svg';
import Root from './style';
import Paper from '@ff/web-components/components/Paper';
import Button from '@ff/web-components/components/Button';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import {IState, onBack} from '@/features/Auth';

interface IProps {
    onBack?: onBack;
    content?: IState['content'];
}

const Reject = ({onBack, content}: IProps) => {
    return (
        <Box mt={14}>
            <Root>
                <Paper pb={5} pt={2.5} px={2.5}>
                    <Box mb={1}>
                        <Button round small secondary onClick={onBack}>
                            <PointerLeft />
                        </Button>
                    </Box>
                    <Box df jc="center" mb={2}>
                        <img src={img} alt="reject" width="64px" height="64px" />
                    </Box>
                    <Box px={2.5}>
                        {content?.title ? (
                            <Box mb={1}>
                                <Text size="xl" align="center" bold>
                                    {content.title}
                                </Text>
                            </Box>
                        ) : null}
                        <Text size="xs" color="secondary" align="center">
                            {content?.subtitle}
                        </Text>
                    </Box>
                </Paper>
            </Root>
        </Box>
    );
};

export default Reject;
