import styled from 'styled-components';

interface PaperProps {
    $theme;
}

const Paper = styled.div<PaperProps>`
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    ${({$theme}) => ({
        background: $theme.theme.palette.background.b2,
        borderRadius: $theme.theme.gutter * 2.5 + 'px',
        boxShadow: $theme.theme.palette.shadow.default,
    })}
`;

export default Paper;
