import styled from 'styled-components';

interface SelectProps {
    $theme;
    $withLabel?;
}

const Select = styled.div<SelectProps>`
    ${({$theme, $withLabel}) => ({
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        height: $theme.lineHeight,
        color: $theme.color,
        paddingTop: $withLabel ? $theme.theme.gutter / 4 + 'px' : 0,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })};
`;

export default Select;
