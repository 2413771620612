import React from 'react';
import Box from '@ff/web-components/components/Box';
import Divider from '@ff/web-components/components/Divider';
import Skeleton from '@ff/web-components/components/Skeleton';
import TextSkeleton from '@/components/TextSkeleton';

const Param = () => (
    <div>
        <TextSkeleton w={15} size="xs" />
        <TextSkeleton w={20} />
    </div>
);

const DetailsSkeleton = () => {
    return (
        <>
            <Box df fd="column">
                <Box df fd="column" gap={2} mb={3}>
                    <Box jc="center" df>
                        <Skeleton w={8} h={8} rad="50%" />
                    </Box>
                    <Box df fd="column" ai="center">
                        <TextSkeleton w={20} size="xl" />
                        <Box mt={0.25}>
                            <TextSkeleton w={25} size="xs2" />
                        </Box>
                    </Box>
                    <Box jc="center" df fd="column" center>
                        <TextSkeleton w={10} size="xl7" jc="center" />
                        <Box mt={0.25} jc="center" df>
                            <TextSkeleton w={20} size="xs2" />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box py={2.5} gap={2} df fd="column">
                <Param />
                <Param />
                <Param />
                <Param />
                <Param />
            </Box>
        </>
    );
};

export default DetailsSkeleton;
