import React from 'react';
import TemplateSkeleton from '@/features/Templates/template/skeleton';
import Text from '@ff/web-components/components/Text';
import Box from '@ff/web-components/components/Box';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';

const ListSkeleton = () => {
    const locale: Dictionary = getLocale();

    return (
        <>
            <Box py={1.25}>
                <Text tt="uppercase" size="xs3" color="secondary" semi>
                    {locale.templates.list.caption}
                </Text>
            </Box>
            <TemplateSkeleton />
            <TemplateSkeleton />
            <TemplateSkeleton />
        </>
    );
};

export default ListSkeleton;
