import React from 'react';
import Box from '@ff/web-components/components/Box';
import Button from '@ff/web-components/components/Button';
import Text from '@ff/web-components/components/Text';
import {ResponseError} from '@/api/protocol';
import {getLocale} from '@ff/web-components/utils/l10n';

interface IProps {
    error: ResponseError;
    onRetry?: () => void;
}

const ServiceError = ({error, onRetry}: IProps) => {
    const locale = getLocale();
    const list = error && Array.isArray(error) ? error.filter(({message}) => !!message) : [];

    return list.length ? (
        <Box py={3} df fd="column" ai="center" gap={2}>
            <div>
                {list.map(({message}, i) => (
                    <Text align="center" color="error" key={i}>
                        {message}
                    </Text>
                ))}
            </div>
            {onRetry ? (
                <Button warning onClick={() => onRetry()}>
                    {locale.app?.btnRetry}
                </Button>
            ) : null}
        </Box>
    ) : null;
};

export default ServiceError;
