import React from 'react';
import {IProps as TextProps} from '@ff/web-components/components/Text';
import useTheme from '@/hooks/useTheme';
import Box, {IProps as BoxProps} from '@ff/web-components/components/Box';
import Skeleton from '@ff/web-components/components/Skeleton';

interface IProps extends BoxProps {
    size?: TextProps['size'];
}

const TextSkeleton = ({size = 'm', h, w, ...rest}: IProps) => {
    const {c} = useTheme();
    return (
        <Box {...rest} h={h || (size ? c.text.lineHeight[size] : undefined)} df ai="center">
            <Box w={w}>
                <Skeleton h={h || (size ? c.text.fontSize[size] : undefined)} />
            </Box>
        </Box>
    );
};

export default TextSkeleton;
