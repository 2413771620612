'use client';
'use strict';
import React, {useMemo, useState} from 'react';
import Root from './style/root';
import Overlay from '../Overlay';
import UserAgent from 'utils/userAgent';
import Paper from '../Paper';
import Content from './style/content';
import defaultTheme from '../Divider/theme';
import theme from '../theme';
import Label from './style/label';
import useMergeTheme from 'hooks/useMergeTheme';
import {DeepPartial} from 'themes/index';

export interface IProps {
    x?: 'left' | 'center' | 'right';
    y?: 'top' | 'center' | 'bottom';
    children?: React.ReactNode;
    label?: React.ReactNode;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
}

const HintMain = ({children, label, theme, style, x = 'center', y = 'top'}: IProps) => {
    const [open, setOpen] = useState(false);
    const computedTheme = useMergeTheme(theme, style);
    const overlayStyle = useMemo(() => ({background: computedTheme?.background}), [computedTheme]);

    return (
        <Root
            onMouseLeave={!UserAgent.os.isTouch ? () => setOpen(false) : undefined}
            $theme={theme?.theme}
        >
            {label ? (
                <Overlay
                    active={open}
                    position={{x, y}}
                    offsetRight={x === 'left' && y === 'center' ? theme?.theme?.gutter : 0}
                    offsetLeft={x === 'right' && y === 'center' ? theme?.theme?.gutter : 0}
                    offsetBottom={y === 'top' ? theme?.theme?.gutter : 0}
                    offsetTop={y === 'bottom' ? theme?.theme?.gutter : 0}
                >
                    <Paper p={2.5} overlay style={overlayStyle}>
                        <Label $theme={theme}>{label}</Label>
                    </Paper>
                </Overlay>
            ) : null}
            <Content
                onMouseEnter={!UserAgent.os.isTouch ? () => setOpen(true) : undefined}
                onClick={UserAgent.os.isTouch ? () => setOpen(!open) : undefined}
            >
                {children}
            </Content>
        </Root>
    );
};

export default theme<IProps, HTMLDivElement>(HintMain, 'hint', 'Hint');
