import React, {useState} from 'react';
import useEventListener from 'react-use-event-listener';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Paper from '@ff/web-components/components/Paper';
import Root from './style/pinnedHeadingRoot';

interface IProps {
    root: React.MutableRefObject<HTMLDivElement>;
    group: React.MutableRefObject<HTMLDivElement>;
    children: React.ReactNode;
    enabled: boolean;
}

const paperStyle = {borderRadius: '0 0 20px 20px'};
const PINNED_HEIGHT = 62;

const PinnedHeading = ({root, children, group, enabled}: IProps) => {
    const breakpoint = root.current?.offsetTop;
    const startArea = group.current?.offsetTop;
    const endArea = startArea + group.current?.offsetHeight - PINNED_HEIGHT;
    const [scrollTop, setScrollTop] = useState(window.scrollY);
    const visible =
        enabled &&
        root.current &&
        group.current &&
        scrollTop >= breakpoint &&
        scrollTop >= startArea &&
        scrollTop <= endArea;

    useEventListener('scroll', () => setScrollTop(window.scrollY), window);

    return (
        <Root
            $visible={visible}
            $width={root.current?.offsetWidth}
            $left={root.current?.offsetLeft}
        >
            <Paper py={2.5} style={paperStyle}>
                <Box pl={2.5}>
                    <Text size="xs3" color="secondary" tt="uppercase">
                        {children}
                    </Text>
                </Box>
            </Paper>
        </Root>
    );
};

export default PinnedHeading;
