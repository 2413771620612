import React from 'react';
import Box from '@ff/web-components/components/Box';
import Skeleton from '@ff/web-components/components/Skeleton';
import useTheme from '@/hooks/useTheme';

const ItemSkeleton = () => {
    const {c} = useTheme();
    return (
        <Box py={2} px={2.5} df ai="center">
            <Box mr={2.5} fs={0} w={5} df>
                <Skeleton w={5} h={5} rad="50%" />
            </Box>
            <Box fg={3} fd="column" df>
                <Box h={c.text.lineHeight.m} df ai="center">
                    <Skeleton w={30} h={c.text.fontSize.m} />
                </Box>
                <Box h={c.text.lineHeight.xs} df ai="center">
                    <Skeleton w={20} h={c.text.fontSize.xs} />
                </Box>
            </Box>
        </Box>
    );
};

export default ItemSkeleton;
