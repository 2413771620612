import styled from 'styled-components';

interface StartIconContainerProps {
    $theme;
}

const StartIconContainer = styled.div<StartIconContainerProps>`
    position: absolute;
    ${({$theme}) => ({
        left: $theme.gutter * 2.5 + 'px',
        top: '50%',
        transform: 'translateY(-50%)',
    })}
`;

export default StartIconContainer;
