import styled from 'styled-components';
import STATE from '../../../../globals';

interface RootProps {
    $hover;
    $error;
    $disabled;
    $focus;
    $theme;
}

const Root = styled.div<RootProps>`
    position: relative;
    width: 100%;
    ${({$hover, $error, $disabled, $focus, $theme}) => ({
        fontFamily: $theme.fontFamily,
        borderRadius: $theme.borderRadius,
        transitionProperty: 'background, opacity',
        transitionDuration: $theme.theme.transition?.duration,
        transitionTimingFunction: $theme.theme.transition?.timingFunction,

        background: $disabled
            ? $theme[STATE.DISABLED]?.background ?? $theme.background
            : $error
            ? $theme[STATE.ERROR]?.background ?? $theme.background
            : $focus
            ? $theme[STATE.FOCUS]?.background ?? $theme.background
            : $hover
            ? $theme[STATE.HOVER]?.background ?? $theme.background
            : $theme.background,
        opacity: $disabled
            ? $theme[STATE.DISABLED]?.opacity ?? 1
            : $error
            ? $theme[STATE.ERROR]?.opacity ?? 1
            : $focus
            ? $theme[STATE.FOCUS]?.opacity ?? 1
            : $hover
            ? $theme[STATE.HOVER]?.opacity ?? 1
            : 1,
        cursor: $disabled ? 'not-allowed' : 'pointer',
    })}
`;

export default Root;
