import styled from 'styled-components';
import STATE from '../../../globals';

interface RootProps {
    $theme;
    $active;
    $wide;
}

const Root = styled.div<RootProps>`
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition-property: background;
    ${({$theme, $active, $wide}) => ({
        ...$theme,
        width: $wide ? '100%' : 'auto',
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
        '&:hover': $theme[STATE.HOVER],
        '&:active': $theme[STATE.ACTIVE],
        ...($active ? $theme.selected : {}),
    })}
`;

export default Root;
