import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Wallet: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 2a5 5 0 0 0-5 5v9.5a5 5 0 0 0 5 5h15V6.114h-2.636V2H7Zm10.364 2v2.114H4.133A3.001 3.001 0 0 1 7 4h10.364ZM4 8.114h16V19.5H7a3 3 0 0 1-3-3V8.114ZM16.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Wallet, 'icon', 'Wallet');
