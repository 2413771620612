import React, {ReactNode} from 'react';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import IconButton from '@ff/web-components/components/IconButton';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import Text from '@ff/web-components/components/Text';
import User from '@/features/TransferToAccount/user';

export interface IProps {
    onBack?: () => void;
    title: ReactNode;
    subTitle?: ReactNode;
    children?: ReactNode;
    img?: null | {
        imageUrl?: string;
        initialsBgColor?: string;
        initials?: string;
    };
}

const Layout: React.FC<IProps> = ({onBack, title, children, subTitle, img}) => {
    return (
        <Box mt={8}>
            <Paper px={2.5} pt={2.5} pb={5}>
                {onBack ? (
                    <Box mb={1}>
                        <IconButton onClick={onBack} secondary>
                            <PointerLeft />
                        </IconButton>
                    </Box>
                ) : null}
                {img !== null ? (
                    <Box df jc="center" mb={2} ai="center">
                        <User {...img} />
                    </Box>
                ) : null}
                <Box mb={3} mt={img === null ? 2 : 0}>
                    <Text align="center" size="xl" bold>
                        {title}
                    </Text>
                    {subTitle ? (
                        <Box mt={1}>
                            <Text align="center" color="secondary" size="xs">
                                {subTitle}
                            </Text>
                        </Box>
                    ) : null}
                </Box>
                {children}
            </Paper>
        </Box>
    );
};

export default Layout;
