import styled from 'styled-components';

const Receiver = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export default Receiver;
