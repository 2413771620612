import {Rule, ValidationError} from './index';
import {getLocale, placeholder} from '@ff/web-components/utils/l10n';
import parse from '@ff/web-components/utils/number/parse';
import {Dictionary} from '@/locale';

export interface Options {
    message?: {
        format?: string;
        min?: string;
        max?: string;
    };
}

export default function minmax<T extends object>(
    keys: Array<Partial<keyof T>>,
    min: number | null,
    max?: number | null,
    options?: Options,
): Rule<T> {
    return (state: T): ReturnType<Rule<T>> => {
        let result: ReturnType<Rule<T>>;
        const locale: Dictionary['app'] = getLocale().app;

        keys.forEach(name => {
            if (state && name in state) {
                const value =
                    typeof state[name] === 'string'
                        ? parse(state[name] as string)
                        : typeof state[name] === 'number'
                        ? state[name]
                        : NaN;

                if (isNaN(value as unknown as number)) {
                    result = (result || {}) as ValidationError<T>;
                    result[name] = {
                        message: options?.message?.format || locale.validation.format,
                        name: name as string,
                    };
                } else if (min && !isNaN(min) && value < min) {
                    result = (result || {}) as ValidationError<T>;
                    result[name] = {
                        message: placeholder(options?.message?.min || locale.validation.min, {
                            n: min,
                        }),
                        name: name as string,
                    };
                } else if (max && !isNaN(max) && value > max) {
                    result = (result || {}) as ValidationError<T>;
                    result[name] = {
                        message: placeholder(options?.message?.max || locale.validation.max, {
                            n: max,
                        }),
                        name: name as string,
                    };
                }
            }
        });

        return result;
    };
}
