import React, {useRef} from 'react';
import Box from '@ff/web-components/components/Box';
import Button from '@ff/web-components/components/Button';
import {getLocale} from '@ff/web-components/utils/l10n';
import Overlay from '@ff/web-components/components/Overlay';
import useToggle from '@/hooks/useToggle';
import Paper from '@ff/web-components/components/Paper';
import ClickAwayListener from '@ff/web-components/components/ClickAwayListener';
import Text from '@ff/web-components/components/Text';
import whatsapp from '@/img/whatsapp.svg';
import telegram from '@/img/telegram.svg';
import Divider from '@ff/web-components/components/Divider';
import {Dictionary} from '@/locale';
import Link from '@ff/web-components/components/Link';

const Support = () => {
    const locale: Dictionary = getLocale();
    const [active, toggleActive] = useToggle(false);
    const root = useRef(null);

    return (
        <Box ref={root} pos="relative">
            <Button secondary small onClick={() => toggleActive()} pressed={active}>
                💬 {locale.header.help}
            </Button>
            <Overlay active={active} offsetTop={8} position={{x: 'right', y: 'bottom'}}>
                <Box w={48}>
                    <Paper overlay py={1}>
                        <div>
                            <Link
                                size="m"
                                align="left"
                                href="https://wa.me/994555002673"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Box px={2.5} py={1.25} df ai="center">
                                    <Box mr={2.5} df ai="center">
                                        <img src={whatsapp} alt="WhatsApp" width="40" height="40" />
                                    </Box>
                                    WhatsApp
                                </Box>
                            </Link>
                        </div>
                        <div>
                            <Link
                                size="m"
                                href="https://t.me/leobank_business_bot"
                                target="_blank"
                                rel="noreferrer"
                                align="left"
                            >
                                <Box px={2.5} py={1.25} df ai="center">
                                    <Box mr={2.5} df ai="center">
                                        <img src={telegram} alt="Telegram" width="40" height="40" />
                                    </Box>
                                    Telegram
                                </Box>
                            </Link>
                        </div>
                        <Box py={1} px={2.5}>
                            <Divider />
                        </Box>
                        <Box px={2.5} py={1.25}>
                            <Text size="xs" color="secondary">
                                {locale.header.telAZLabel}
                            </Text>
                            <div>
                                <Link
                                    href={`tel:${locale.header.telAZ.replace(/[\s*]/g, '')}`}
                                    size="m"
                                >
                                    {locale.header.telAZ}
                                </Link>
                            </div>
                        </Box>
                        <Box px={2.5} py={1.25}>
                            <Text size="xs" color="secondary">
                                {locale.header.telForeignLabel}
                            </Text>
                            <div>
                                <Link
                                    size="m"
                                    href={`tel:${locale.header.telForeign.replace(/\s/g, '')}`}
                                >
                                    {locale.header.telForeign}
                                </Link>
                            </div>
                        </Box>
                    </Paper>
                </Box>
            </Overlay>
            <ClickAwayListener
                node={root}
                onClickAway={active ? () => toggleActive(false) : undefined}
            />
        </Box>
    );
};

export default Support;
