import styled from 'styled-components';

interface DayProps {
    $theme;
    $disabled;
    $active;
    $period;
    $first;
    $firstMon;
    $lastMon;
    $firstSun;
    $lastSun;
    $last;
    $firstSingle;
    $lastSingle;
    $rangeStartPoint;
    $rangeEndPoint;
}

const Day = styled.div<DayProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    z-index: 2;
    ${({
        $theme,
        $disabled,
        $active,
        $period,
        $first,
        $firstMon,
        $lastMon,
        $firstSun,
        $lastSun,
        $last,
        $firstSingle,
        $lastSingle,
        $rangeStartPoint,
        $rangeEndPoint,
    }) => ({
        cursor: $disabled ? 'not-allowed' : 'pointer',
        background: $active
            ? $theme.theme.palette.grey['1000']
            : $period
            ? $theme.theme.palette.grey['300']
            : 'transparent',
        borderTopLeftRadius: $first || $firstMon ? $theme.theme.gutter + 'px' : undefined,
        borderBottomLeftRadius: $firstSingle || $lastMon ? $theme.theme.gutter + 'px' : undefined,
        borderTopRightRadius: $lastSingle || $firstSun ? $theme.theme.gutter + 'px' : undefined,
        borderBottomRightRadius: $lastSun || $last ? $theme.theme.gutter + 'px' : undefined,
        borderRadius: $active ? '50%' : 'none',
        '&:before': {
            content: '""',
            display: $rangeStartPoint || $rangeEndPoint ? 'block' : 'none',
            position: 'absolute',
            top: 0,
            left: $rangeStartPoint ? '50%' : 0,
            background: $theme.theme.palette.grey['300'],
            width: '22px',
            height: '44px',
            zIndex: -1,
        },
    })}
`;

export default Day;
