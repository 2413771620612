import React, {useRef} from 'react';
import UserImage from './style/userImage';
import {Account} from '@/features/Accounts/api/getList';
import UserPreview from '@/features/Accounts/style/userPreview';
import url from '@/api/url';
import Text from '@ff/web-components/components/Text';

interface IProps {
    account: Account;
}

const colors = [
    'salmon',
    'juicyPeach',
    'lightOrange',
    'tuscanSun',
    'oliveGreen',
    'yellowGreen',
    'turquoise',
    'turkish',
    'lightSkyBlue',
    'blueOcean',
    'slateblue',
    'mediumPurple',
    'sweetTomato',
    'hotPink',
    'indianRed',
    'sienna',
    'burlyWood',
    'persian',
    'independence',
    'slategrey',
];

const UserPic = ({account}: IProps) => {
    const [fn, ln] = account.name ? account.name.split(' ') : ['', ''];
    const background = useRef(colors[Math.floor(Math.random() * colors.length)]);

    return account.img && account.img !== 'null' ? (
        <UserImage src={url(account.img) + `?enterpriseId=${account.id}`} />
    ) : (
        <UserPreview $bg={background.current}>
            <Text align="center" color="white">
                {fn ? fn[0] : ''}
                {ln ? ln[0] : ''}
            </Text>
        </UserPreview>
    );
};

export default UserPic;
