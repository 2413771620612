import {SyntheticEvent, useCallback, useState} from 'react';
import {SyntheticEvent as ComponentsSyntheticEvent} from '@ff/web-components/utils/syntheticEvent';

export type OnChange = (event: SyntheticEvent | ComponentsSyntheticEvent) => void;

export default function useForm<T>(
    initialState: T,
): [T, (e: SyntheticEvent | ComponentsSyntheticEvent) => void, (data: Partial<T>) => void] {
    const [state, setState] = useState<T>(initialState),
        handleChange = useCallback((event: SyntheticEvent | ComponentsSyntheticEvent) => {
            const {name, type, value, checked} = event.currentTarget;

            setState(
                prevState =>
                    ({
                        ...prevState,
                        [name]: type === 'checkbox' ? checked : value,
                    } as T),
            );
        }, []),
        outerSetState = useCallback(
            (data: Partial<T>) => {
                if (typeof data === 'function') {
                    setState(data);
                } else {
                    setState(
                        prevState =>
                            ({
                                ...prevState,
                                ...data,
                            } as T),
                    );
                }
            },
            [setState],
        );

    return [state as T, handleChange as OnChange, outerSetState];
}
