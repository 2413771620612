import React from 'react';
import Text, {IProps as TextProps} from '../Text';
import Anchor from './styles/anchor';
import theme from '../theme';

export interface IProps
    extends Pick<
            TextProps,
            | 'color'
            | 'align'
            | 'size'
            | 'lh'
            | 'fs'
            | 'fw'
            | 'semi'
            | 'bold'
            | 'noWrap'
            | 'ws'
            | 'ellipsis'
            | 'to'
            | 'overflow'
            | 'td'
            | 'cursor'
            | 'tt'
            | 'ff'
        >,
        React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const LinkMain = React.forwardRef<HTMLAnchorElement, IProps>(
    (
        {
            children,
            color = 'primary',
            align,
            size,
            cursor = 'pointer',
            bold,
            fs,
            fw,
            lh,
            semi,
            noWrap,
            ws,
            ellipsis,
            to,
            overflow,
            td,
            tt,
            ff,
            ...rest
        }: IProps,
        ref,
    ) => {
        return (
            <Text
                color={color}
                align={align}
                size={size}
                cursor={cursor}
                bold={bold}
                fs={fs}
                fw={fw}
                lh={lh}
                semi={semi}
                noWrap={noWrap}
                ws={ws}
                ellipsis={ellipsis}
                to={to}
                overflow={overflow}
                td={td}
                tt={tt}
                ff={ff}
            >
                <Anchor ref={ref} {...rest}>
                    {children}
                </Anchor>
            </Text>
        );
    },
);

LinkMain.displayName = 'Link';

export default theme<IProps, HTMLAnchorElement>(LinkMain, 'link', 'Link');
