import axios from 'axios';
import url from '@/api/url';
import {PAYMENT_TYPE as TAX} from '@/features/TransferToTaxService/constants';
import {PAYMENT_TYPE as IBAN} from '@/features/TransferToAccount/constants';
import {Branch, Budget} from '@/features/TransferToAccount/api/saveReceiver';
import {Type} from '@/features/TransferToTaxService/api/getTypes';
import {Destination} from '@/features/TransferToTaxService/api/getDestinations';
import {BudgetClassificator} from '@/features/TransferToAccount/api/getBudgetClassificators';
import {Response as BalanceResponse} from '@/features/Accounts/api/getBalance';

type TemplateBase = {
    id: string;
    type: typeof TAX | typeof IBAN;
    title: string;
    subTitle?: string;
    img?: string;
};

export interface Template extends TemplateBase {
    templateDetails: {
        [index: string]: any;
    };
}

export interface PaymentTemplate extends TemplateBase {
    templateDetails: {
        paymentDescription: string;
        amount: number;
        receiverVoin: string;
        receiverName: string;
        receiverBankCode: Branch['bankCode'];
        receiverIban: string;
        cardId: BalanceResponse['data']['id'];
        budgetLevel?: Budget;
        budgetClassificator?: BudgetClassificator;
    };
}

export interface TaxTemplate extends TemplateBase {
    templateDetails: {
        voen: string;
        amount: number;
        paymentTypeId: Type['id'];
        paymentDetail: Destination;
        cardId: BalanceResponse['data']['id'];
    };
}

export type Response = {
    templates: Array<PaymentTemplate | TaxTemplate>;
};

export type Params = {
    type?: Template['type'];
};

export const QUERY_KEY = 'payments/templates';

export default async function getTypes(params?: Params): Promise<Response> {
    return axios.get(url('payments/templates'), {params});
}
