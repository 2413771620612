import axios from 'axios';
import url from '@/api/url';

export interface Item {
    title: string;
    subtitle: string;
}

type NativeResponse = {
    data: {
        title: string;
        subtitle: string;
        icon: string;
        details: {
            blocks: {
                items: Item[];
            }[];
        };
    }[];
};
export type Response = {
    title: string;
    subtitle: string;
    icon: string;
    items: Item[];
};

export type Params = {
    iban: string;
};

export default async function getDestinations(params: Params): Promise<Response> {
    return axios.get(url('account-details'), {
        params: {
            ...params,
            isBusiness: true,
        },
        transformResponse: (resp: string, headers, code) => {
            let transformed: Response;

            if (code === 200) {
                try {
                    const parsed = JSON.parse(resp);
                    if (parsed.data?.length) {
                        transformed = {...parsed.data[0], items: []};
                        transformed.items = parsed.data[0].details?.blocks
                            ? parsed.data[0].details?.blocks[0].items
                            : [];
                    }
                    return transformed;
                } catch (e) {
                    throw new Error('empty data');
                }
            } else {
                return resp;
            }
        },
    });
}
