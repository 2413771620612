import formatter from 'utils/formatter';
import parse from './parse';

export default function numberFormatter(
    value: string | number,
    separators: string[],
    fractionLength: number,
    currency?: string,
) {
    const parsedValue = parse(value, separators);

    if (parsedValue) {
        // format only valid number
        // input can be on start point and contains only dot or sign
        if (parsedValue !== '-' && parsedValue !== '-0') {
            const int = Math.trunc(Number(parsedValue)),
                sign = parsedValue.startsWith('-') ? '-' : '';
            let decimal = parsedValue.match(/\.[0-9]{0,10}/) || '';

            if (decimal) {
                decimal = decimal[0].substr(0, fractionLength + 1);
            }

            const abs = Math.abs(int);
            const absLength = abs.toString().length;

            let formatPart = '';
            for (let i = 0; i < absLength; i++) {
                if ((i + 1) % 3 === 0 && i + 1 < absLength) {
                    formatPart = ` d${formatPart}`;
                } else {
                    formatPart = `d${formatPart}`;
                }
            }
            const format = formatPart
                .split(' ')
                .map(part => `{{${part}}}`)
                .join(' ');

            return `${sign}${formatter(String(Math.abs(int)), format)}${decimal}${
                currency ? ` ${currency}` : ''
            }`;
        } else {
            return String(value);
        }
    } else {
        return '';
    }
}
