import React, {useEffect} from 'react';
import {Statement} from '@/features/Accounts/api/getStatement';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import IconButton from '@ff/web-components/components/IconButton';
import CrossClose from '@ff/web-components/components/Icon/CrossClose';
import Head from './head';
import Body from './body';
import Divider from '@ff/web-components/components/Divider';
import Text from '@ff/web-components/components/Text';
import DeleteIcon from '@ff/web-components/components/Icon/Trash';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';
import {useMutation, useQuery} from '@tanstack/react-query';
import deletePayment, {Response} from '@/features/Accounts/api/deletePayment';
import GradientSpinner from '@/components/GradientSpinner';
import ServiceError from '@/components/ServiceError';
import {ResponseError} from '@/api/protocol';
import getPayments, {
    QUERY_KEY as PAYMENTS_KEY,
    Response as PaymentsResponse,
} from '@/features/Accounts/api/getPayments';
import useLang from '@/hooks/useLang';
import getPaymentDetails, {
    QUERY_KEY as DETAILS_KEY,
    Response as DetailsResponse,
} from '@/features/Accounts/api/getPaymentDetails';
import Skeleton from './skeleton';

interface IProps {
    id: Statement['id'];
    onClose?: () => void;
}

const StatementDetails = ({onClose, id}: IProps) => {
    const lang = useLang();
    const locale: Dictionary['statements'] = getLocale().statements;
    const deletePaymentReq = useMutation<Response, ResponseError>({
        mutationFn: () => deletePayment(id),
    });
    const paymentsReq = useQuery<PaymentsResponse, ResponseError>({
        queryKey: [PAYMENTS_KEY, lang],
        queryFn: getPayments,
        enabled: false,
    });
    const detailsReq = useQuery<DetailsResponse, ResponseError>({
        queryKey: [DETAILS_KEY, lang, id],
        queryFn: () => getPaymentDetails(id),
    });

    useEffect(() => {
        if (deletePaymentReq.isSuccess) {
            paymentsReq.refetch();
        }
    }, [deletePaymentReq.isSuccess]);

    return (
        <Box w={47}>
            <Paper px={2.5} pt={2.5}>
                <Box mb={1} df jc="flex-end">
                    {onClose ? (
                        <IconButton onClick={onClose} secondary>
                            <CrossClose />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                </Box>
                {deletePaymentReq.isError ? <ServiceError error={deletePaymentReq.error} /> : null}
                {detailsReq.isLoading ? (
                    <Skeleton />
                ) : detailsReq.isError ? (
                    <ServiceError error={detailsReq.error} />
                ) : detailsReq.data ? (
                    <>
                        <Head
                            icon={detailsReq.data.header.avatar?.iconUrl}
                            receiver={detailsReq.data.header.title}
                            subTitle={detailsReq.data.header.subtitles}
                            amount={detailsReq.data.input.sum}
                            amountHint={detailsReq.data.input.payload?.priceSubtitles}
                            currencySymbol={detailsReq.data.input.currency}
                        />
                        {detailsReq.data.extraFields?.length ? (
                            <>
                                <Divider />
                                <Body params={detailsReq.data.extraFields} />
                            </>
                        ) : null}
                        <Divider />
                        <Text
                            size="m"
                            cursor="pointer"
                            onClick={
                                deletePaymentReq.isPending
                                    ? undefined
                                    : () => deletePaymentReq.mutate()
                            }
                        >
                            <Box py={2.5} df ai="center">
                                <Box mr={2.5}>
                                    {deletePaymentReq.isPending ? (
                                        <GradientSpinner size={3} />
                                    ) : (
                                        <DeleteIcon />
                                    )}
                                </Box>
                                {locale.deletePayment}
                            </Box>
                        </Text>
                    </>
                ) : null}
            </Paper>
        </Box>
    );
};

export default StatementDetails;
