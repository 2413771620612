import React from 'react';
import theme from '../theme';
import rootTheme from './theme';
import SnackRoot from './style/snack';

export interface IProps {
    primary?: boolean;
    warning?: boolean;
    children?: React.ReactNode;
    duration?: number;
    theme?: Partial<ReturnType<typeof rootTheme>>;
    onRemove?: () => void;
    style?: object;
}

const Snack = ({children, theme, warning, style}: IProps) => {
    const computedTheme = warning ? theme?.snack?.warning : theme?.snack?.primary;

    return (
        <SnackRoot $theme={computedTheme} style={style}>
            {children}
        </SnackRoot>
    );
};

export default theme<IProps, HTMLDivElement>(Snack, 'snackBar', 'Snack');
