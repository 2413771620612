import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Root = styled.div<RootProps>`
    top: 0;
    left: 0;
    z-index: ${props => props.$theme.zIndex};
    position: fixed;
    zoom: 1;
    min-width: 100%;
    min-height: 100vh;
    &:after {
        clear: both;
        content: '';
        display: block;
        height: 0;
    }
`;

export default Root;
