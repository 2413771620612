import styled from 'styled-components';

interface ActionContainerProps {
    $theme;
}

const ActionContainer = styled.div<ActionContainerProps>`
    position: absolute;
    cursor: pointer;
    ${({$theme}) => ({
        right: $theme.gutter * 1.5 + 'px',
        top: '50%',
        transform: 'translateY(-50%)',
    })}
`;

export default ActionContainer;
