import React from 'react';
import IconButton from '@ff/web-components/components/IconButton';
import WalletIcon from '@ff/web-components/components/Icon/Wallet';
import Text from '@ff/web-components/components/Text';
import Box from '@ff/web-components/components/Box';
import {getLocale} from '@ff/web-components/utils/l10n';
import OptionsItem from '@ff/web-components/components/Options/Item';
import transferImg from '@/features/TransferToAccount/img/service.svg';
import Options, {IProps as OptionsProps} from '@ff/web-components/components/Options';
import transferTaxImg from '@/features/TransferToTaxService/img/service.svg';
import {useNavigate} from 'react-router-dom';
import navigateTax from '@/features/TransferToTaxService/makeLink';
import navigateAccount from '@/features/TransferToAccount/makeLink';
import {Dictionary} from '@/locale';

const optionsPosition = {x: 'right', y: 'bottom'};
const optionStyle = {padding: '10px 20px'};

const PaymentButton = () => {
    const navigate = useNavigate();
    const locale: Dictionary['dashboard'] = getLocale().dashboard;

    return (
        <Options
            position={optionsPosition as OptionsProps['position']}
            control={active => (
                <>
                    <IconButton primary>
                        <WalletIcon />
                    </IconButton>
                    <Text
                        size="xs2"
                        align="center"
                        ws="pre-wrap"
                        color={active ? 'secondary' : 'primary'}
                    >
                        <Box pt={1}>{locale.payment}</Box>
                    </Text>
                </>
            )}
        >
            <OptionsItem style={optionStyle} onClick={() => navigate(navigateTax())}>
                <Box df ai="center" minW={41}>
                    <Box mr={2.5} df ai="center">
                        <img src={transferTaxImg} width="40px" height="40px" />
                    </Box>
                    <Text>{locale.tax}</Text>
                </Box>
            </OptionsItem>
            <OptionsItem style={optionStyle} onClick={() => navigate(navigateAccount())}>
                <Box df ai="center" minW={41}>
                    <Box mr={2.5} df ai="center">
                        <img src={transferImg} width="40px" height="40px" />
                    </Box>
                    <Text>{locale.transfer}</Text>
                </Box>
            </OptionsItem>
        </Options>
    );
};

export default PaymentButton;
