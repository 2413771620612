export const config = (payload: object) => {
    if (window.gtag) {
        window.gtag('set', payload);
    }
};

export const pageView = ({title, path}: {title?: string; path: string}) => {
    if (window.gtag) {
        gtag('config', 'G-QKNN4P8X7N', {
            send_page_view: false,
            update: true,
            page_referrer: '',
            page_title: title,
            page_location: document.location.protocol + '//' + document.location.host + path,
        });
        gtag('event', 'page_view');
    }
};
