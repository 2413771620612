import React from 'react';
import {Response} from '@/features/Accounts/api/getPaymentDetails';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Money from '@/components/Money';
import useTheme from '@/hooks/useTheme';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import {get} from '@/store/runtime';

interface IProps {
    receiver: Response['header']['title'];
    amount: Response['input']['sum'];
    amountHint?: Response['input']['payload']['priceSubtitles'];
    currencySymbol: Response['input']['currency'];
    icon: Response['header']['avatar']['iconUrl'];
    subTitle?: Response['header']['subtitles'];
}

const DetailsHead = ({receiver, amount, amountHint, currencySymbol, subTitle, icon}: IProps) => {
    const {p} = useTheme();
    return (
        <Box df fd="column">
            <Box df fd="column" gap={2} mb={3}>
                {icon ? (
                    <Box jc="center" df>
                        <Box rad="50%" of="hidden" df>
                            <img
                                src={categoryIcon(icon, get('ENTERPRISE'))}
                                width="64"
                                height="64"
                            />
                        </Box>
                    </Box>
                ) : null}
                <Box of="hidden">
                    <Text size="xl" bold align="center">
                        {receiver}
                    </Text>
                    {subTitle?.length
                        ? subTitle.map((text, i) => (
                              <Box mt={0.25} key={i}>
                                  <Text size="xs2" color={p.palette.hex.black['50']} align="center">
                                      {text}
                                  </Text>
                              </Box>
                          ))
                        : null}
                </Box>
                <Box jc="center" df fd="column" center>
                    <Box jc="center" df>
                        <Money
                            size="xl7"
                            fractionSize="xl2"
                            bold
                            sum={amount}
                            currency={currencySymbol}
                        />
                    </Box>
                    {amountHint
                        ? amountHint.map((text, i) => (
                              <Box mt={0.25} jc="center" df key={i}>
                                  <Text size="xs2" color="secondary">
                                      {text}
                                  </Text>
                              </Box>
                          ))
                        : null}
                </Box>
            </Box>
        </Box>
    );
};

export default DetailsHead;
