import axios from 'axios';
import url from '@/api/url';
import {Destination} from '@/features/TransferToTaxService/api/getDestinations';

export type Response = {
    feeAmount: number;
};

export interface Params {
    paymentTypeId: string;
    voen: string;
    paymentDetail: Destination;
    amount: number;
}

export default async function getCommission(params: Params): Promise<Response> {
    return axios.post(url(`tax/calculate-fee`), params);
}
