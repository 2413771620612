import styled from 'styled-components';

interface IconProps {
    $theme;
    $disabled;
}

const Icon = styled.label<IconProps>`
    display: block;
    flex-shrink: 0;
    ${({$theme, $disabled}) => ({
        width: $theme.theme.gutter * 3 + 'px',
        height: $theme.theme.gutter * 3 + 'px',
        cursor: $disabled ? 'not-allowed' : 'pointer',
    })}
`;

export default Icon;
