import styled from 'styled-components';
import scrollbar from 'themes/utils/scrollbar';

interface RootProps {
    $theme;
    $withLabel;
    $padLeft;
}

const Input = styled.textarea<RootProps>`
    ${({$theme, $withLabel, $padLeft}) => ({
        fontFamily: $theme.fontFamily,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        color: $theme.color,
        paddingLeft: $padLeft
            ? $theme.theme.gutter * 6.5 + 'px'
            : $withLabel
            ? $theme.theme.gutter * 2 + 'px'
            : $theme.theme.gutter * 2.5 + 'px',
        paddingRight: $theme.theme.gutter * 4.5 + 'px',
        textAlign: $theme.textAlign,
        ...scrollbar($theme.theme),
    })};
    padding-top: 0;
    padding-bottom: 0;
    resize: none;
    width: 100%;
    min-width: 100%;
    margin: 0;
    display: block;
    box-sizing: border-box;
    background: transparent;
    border: none;
    ::placeholder,
    ::-webkit-input-placeholder {
        ${({$theme}) => ({
            color: $theme.placeholder,
            transitionProperty: 'color',
            transitionDuration: $theme.theme.transition?.duration,
            transitionTimingFunction: $theme.theme.transition?.timingFunction,
        })};
    }
    :-ms-input-placeholder {
        ${({$theme}) => ({
            color: $theme.placeholder,
            transitionProperty: 'color',
            transitionDuration: $theme.theme.transition?.duration,
            transitionTimingFunction: $theme.theme.transition?.timingFunction,
        })};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        ${({$theme}) => ({
            '-webkit-box-shadow': `0 0 0 50px ${$theme.theme.palette.background.default} inset`,
            '-webkit-text-fill-color': $theme.color,
        })};
    }
    &:focus {
        outline: 0 none;
    }
`;

export default Input;
