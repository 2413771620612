import React from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import {ResponseError} from '@/api/protocol';

interface IProps {
    error: ResponseError;
}

const ServiceError = ({error}: IProps) => {
    const list = error ? error.filter(({message}) => !!message) : [];

    return list.length ? (
        <Box pb={3} df fd="column" ai="center" gap={2}>
            <div>
                {list.map(({message}, i) => (
                    <Text align="center" color="error" key={i}>
                        {message}
                    </Text>
                ))}
            </div>
        </Box>
    ) : null;
};

export default ServiceError;
