import styled from 'styled-components';

interface RootProps {
    $theme;
    $vertical;
}

const Root = styled.div<RootProps>`
    ${({$theme, $vertical}) => ({
        width: $vertical ? $theme.height : $theme.width,
        height: $vertical ? $theme.width : $theme.height,
        background: $theme.color,
    })}
`;

export default Root;
