import styled from 'styled-components';

interface LabelProps {
    $theme;
}

const Label = styled.div<LabelProps>`
    ${({$theme}) => ({
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        color: $theme.color,
    })}
`;

export default Label;
