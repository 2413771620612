import React from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import {Response} from '@/features/Accounts/api/getPaymentDetails';

interface IProps {
    params: Response['extraFields'];
}

const DetailsBody = ({params}: IProps) => {
    return (
        <Box py={2.5} gap={2} df fd="column">
            {params?.map(({title, value}) => (
                <div key={title}>
                    <div>
                        <Text size="xs" color="secondary">
                            {title}
                        </Text>
                    </div>
                    <Box of="hidden">
                        <Text size="m">{value}</Text>
                    </Box>
                </div>
            ))}
        </Box>
    );
};

export default DetailsBody;
