import {Response} from '@/features/Accounts/api/getStatement';
import {ST_STATUS_SUCCESS} from '@/features/Accounts/constants';
import toFixed from '@ff/web-components/utils/number/toFixed';

export default function getTotalIncomeAndSpend(list: Response['data']) {
    const successList = list.filter(item => item.status === ST_STATUS_SUCCESS);

    return {
        income: toFixed(
            successList
                .filter(item => item.value.amount > 0)
                .reduce((memo, item) => memo + item.value.amount, 0),
        ),
        spend: toFixed(
            successList
                .filter(item => item.value.amount < 0)
                .reduce((memo, item) => memo + Math.abs(item.value.amount), 0),
        ),
    };
}
