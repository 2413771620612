import React from 'react';
import {Options} from '@/features/Accounts/api/getStatementDetails';
import Box from '@ff/web-components/components/Box';
import Divider from '@ff/web-components/components/Divider';
import Text from '@ff/web-components/components/Text';
import {DetailsButton, Statement} from '@/features/Accounts/api/getStatement';
import DownloadIcon from '@ff/web-components/components/Icon/Download';
import url from '@/api/url';
import runtime from '@/store/runtime';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';

interface IProps extends Options {
    buttons: Statement['detailContent']['buttons'];
    id: Statement['id'];
}

const allowedActions = ['share'];

const actions = {
    share: (id: Statement['id']) => {
        window.open(url(`statements/${id}/quittance?enterpriseId=${runtime.get('ENTERPRISE')}`));
    },
};

const icons = {
    share: DownloadIcon,
};

type AllowedActions = keyof Dictionary['statements']['buttons'];

const DetailsFooter = ({buttons, id}: IProps) => {
    const allowedList = buttons.filter(({action}) => allowedActions.includes(action));
    const locale: Dictionary = getLocale();

    return (
        <div>
            {allowedList.map(({action}: DetailsButton) => {
                const title = locale.statements.buttons[action as AllowedActions];
                return (
                    title && (
                        <React.Fragment key={action}>
                            <Divider />
                            <Text
                                size="m"
                                cursor="pointer"
                                onClick={
                                    actions[action as keyof typeof actions]
                                        ? () => actions[action as keyof typeof actions](id)
                                        : undefined
                                }
                            >
                                <Box py={2.5} df ai="center">
                                    {icons[action as keyof typeof icons] ? (
                                        <Box mr={2.5}>
                                            {React.createElement(
                                                icons[action as keyof typeof icons],
                                            )}
                                        </Box>
                                    ) : null}
                                    {title}
                                </Box>
                            </Text>
                        </React.Fragment>
                    )
                );
            })}
        </div>
    );
};

export default DetailsFooter;
