import React, {useCallback, useState} from 'react';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import IconButton from '@ff/web-components/components/IconButton';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import Text from '@ff/web-components/components/Text';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';
import {useQuery} from '@tanstack/react-query';
import getList, {Response} from '@/features/Requisites/api/getList';
import Item from '@/features/Requisites/item';
import Skeleton from '@/features/Requisites/skeleton';
import ServiceError from '@/components/ServiceError';
import {useNavigate} from 'react-router-dom';
import dashboard from '@/features/Dashboard/makeLink';
import {ResponseError} from '@/api/protocol';
import useAccount from '@/hooks/useAccount';
import getBalance, {
    QUERY_KEY,
    Response as BalanceResponse,
} from '@/features/Accounts/api/getBalance';
import url from '@/api/url';
import DownloadIcon from '@ff/web-components/components/Icon/Download';
import Divider from '@ff/web-components/components/Divider';
import CopyIcon from '@ff/web-components/components/Icon/Copy';
import copyText from '@/utils/copyText';
import SnackBar from '@ff/web-components/components/SnackBar';
import useLang from '@/hooks/useLang';
import {pageView} from '@/utils/gtag';

const generateParams = (items: Response['items']) =>
    items
        .map(
            item =>
                `${item.title}:
${item.subtitle}
`,
        )
        .join('\n');

const generateAllRequisites = (data: Response) => {
    return `${data.title}
${data.subtitle}

${generateParams(data.items)}`;
};

const Requisites = () => {
    const locale: Dictionary = getLocale();
    const account = useAccount();
    const lang = useLang();
    const [snack, setSnack] = useState<{msg: string; warning?: boolean}>();
    const getBalanceReq = useQuery<BalanceResponse, ResponseError>({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
    });
    const listReq = useQuery<Response, ResponseError>({
        queryKey: ['/requisites/list', lang],
        queryFn: () => getList({iban: getBalanceReq.data?.data.iban}),
        enabled: !!getBalanceReq.data?.data.iban,
    });
    const navigate = useNavigate();
    const handleDownload = useCallback(
        () =>
            window.open(
                url(
                    `account-details/file?cardId=${getBalanceReq.data.data.cardBankId}&enterpriseId=${account.id}`,
                ),
            ),
        [getBalanceReq.data],
    );
    const handleCopyAll = useCallback(async () => {
        try {
            await copyText(generateAllRequisites(listReq.data));
            setSnack({msg: locale.requisites.copy});
        } catch (e) {
            setSnack({msg: e.message, warning: true});
        }
    }, [listReq.data]);

    pageView({path: '/requisites', title: 'Requisites'});

    return (
        <Box mt={8} center w={47}>
            <Paper px={2.5} pt={2.5} pb={4}>
                <Box mb={1}>
                    <IconButton secondary onClick={() => navigate(dashboard())}>
                        <PointerLeft />
                    </IconButton>
                </Box>
                {getBalanceReq.isLoading ||
                getBalanceReq.isPending ||
                listReq.isLoading ||
                listReq.isPending ? (
                    <Skeleton />
                ) : getBalanceReq.isError ? (
                    <ServiceError error={getBalanceReq.error} onRetry={getBalanceReq.refetch} />
                ) : listReq.isError ? (
                    <ServiceError error={listReq.error} onRetry={listReq.refetch} />
                ) : listReq.data ? (
                    <>
                        <Box df jc="center" ai="center" mb={2}>
                            <img src={listReq.data.icon} width="64" height="64" alt="Manat" />
                        </Box>
                        <Box mb={1}>
                            <Text align="center" size="xl" bold>
                                {listReq.data.title}
                            </Text>
                        </Box>
                        <Box mb={3}>
                            <Text align="center" size="xs" color="secondary">
                                {listReq.data.subtitle}
                            </Text>
                        </Box>
                        {listReq.data.items?.length ? (
                            <Box mb={3} gap={2} df fd="column">
                                {listReq.data.items.map(({title, subtitle}, i) => (
                                    <Item key={i} name={title} value={subtitle} />
                                ))}
                            </Box>
                        ) : null}
                        <Divider />
                        <Text cursor="pointer" align="left">
                            <Box df ai="center" py={2.5} onClick={handleDownload}>
                                <Box mr={2.5}>
                                    <DownloadIcon />
                                </Box>
                                {locale.requisites.download}
                            </Box>
                        </Text>
                        <Divider />
                        <Text cursor="pointer" align="left">
                            <Box pt={2.5} df ai="center" onClick={handleCopyAll}>
                                <Box mr={2.5}>
                                    <CopyIcon />
                                </Box>
                                {locale.requisites.copyAll}
                            </Box>
                        </Text>
                        <SnackBar
                            open={!!snack}
                            warning={snack?.warning}
                            onRemove={() => setTimeout(() => setSnack(null), 0)}
                        >
                            {snack?.msg}
                        </SnackBar>
                    </>
                ) : null}
            </Paper>
        </Box>
    );
};

export default Requisites;
