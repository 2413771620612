import React, {useEffect, useMemo, useRef} from 'react';
import Filter from './filter';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import useForm from '@/hooks/useForm';
import getStatement, {
    Response,
    Statement as IStatement,
} from '@/features/Accounts/api/getStatement';
import {DatePickerSelectedPeriod} from '@ff/web-components/components/DatePicker';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import Paper from '@ff/web-components/components/Paper';
import ServiceError from '@/components/ServiceError';
import {ResponseError} from '@/api/protocol';
import PositiveMascot from '@/components/mascot/Positive';
import {getLocale} from '@ff/web-components/utils/l10n';
import List from './list';
import SadMascot from '@/components/mascot/Sad';
import Skeleton from './list/skeleton';
import getList, {
    QUERY_KEY as TEMPLATES_QUERY_KEY,
    Response as TemplatesResponse,
} from '@/features/Templates/api/getList';
import TemplatesList from '@/features/Dashboard/templates/list';
import {Dictionary} from '@/locale';
import {
    FILTER_ALL,
    FILTER_INCOME,
    FILTER_SPEND,
    FILTER_TEMPLATES,
} from '@/features/Dashboard/constants';
import concat from 'lodash/concat';
import LoadingButton from '@/components/LoadingButton';
import queryClient from '@/api/queryClient';
import getBalance, {
    QUERY_KEY,
    Response as BalanceResponse,
} from '@/features/Accounts/api/getBalance';
import useLang from '@/hooks/useLang';
import getPayments, {
    QUERY_KEY as PAYMENTS_KEY,
    Response as PaymentsResponse,
} from '@/features/Accounts/api/getPayments';
import Payments from '@/features/Dashboard/statement/payments';
import {useSearchParams} from 'react-router-dom';
import search from '@/features/Templates/utils/search';

type StatusState =
    | typeof FILTER_INCOME
    | typeof FILTER_SPEND
    | typeof FILTER_ALL
    | typeof FILTER_TEMPLATES;

export interface State {
    filter: StatusState;
    date: DatePickerSelectedPeriod;
    search: string;
}

const PAGE_SIZE = 20;

const Statement = () => {
    const [searchParams] = useSearchParams();
    const locale: Dictionary['statements'] = getLocale().statements;
    const searchTimeout = useRef<ReturnType<typeof setTimeout>>();
    const lang = useLang();
    const [state, onChange] = useForm<State>({
        filter: (searchParams.get('tab') as StatusState) || FILTER_ALL,
        date: null,
        search: '',
    });
    const getBalanceReq = useQuery<BalanceResponse, ResponseError>({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
        staleTime: 1000,
    });
    const templatesReq = useQuery<TemplatesResponse, ResponseError>({
        queryKey: [TEMPLATES_QUERY_KEY, lang],
        queryFn: () => getList(),
        enabled: false,
    });

    const queryKey = [
        '/statements',
        getBalanceReq.data?.data.id,
        `${state.date?.start.format('DDMMYY')}-${state.date?.end.format('DDMMYY')}`,
        lang,
    ];
    const statementsReq = useInfiniteQuery<Response, ResponseError>({
        queryKey,
        initialPageParam: 0,
        queryFn: ({pageParam}) =>
            getStatement({
                tsFrom: state.date?.start.valueOf(),
                tsTo: state.date?.end.valueOf(),
                searchString: state.search || undefined,
                size: PAGE_SIZE,
                page: pageParam as number,
                cardId: String(getBalanceReq.data?.data.id),
            }),
        getNextPageParam: (lastPage, allPages) => (lastPage?.lastPage ? null : allPages.length),
        enabled: !!getBalanceReq.data?.data.id,
    });
    const paymentsReq = useQuery<PaymentsResponse, ResponseError>({
        queryKey: [PAYMENTS_KEY, lang],
        queryFn: getPayments,
    });
    const allStatements = useMemo(() => {
        return statementsReq.data?.pages?.length
            ? (concat(
                  [],
                  ...statementsReq.data.pages.map(page => page.data),
              ) as unknown as IStatement[])
            : [];
    }, [statementsReq.data]);
    const filteredList = useMemo(
        () =>
            allStatements?.length
                ? state.filter === FILTER_ALL || state.filter === FILTER_TEMPLATES
                    ? allStatements
                    : allStatements.filter(item =>
                          state.filter === FILTER_INCOME
                              ? item.value.amount > 0
                              : item.value.amount < 0,
                      )
                : [],
        [state.filter, allStatements],
    );
    const filteredTemplates = useMemo(() => {
        if (templatesReq.data?.templates?.length) {
            return search(templatesReq.data.templates, state.search);
        } else {
            return templatesReq.data?.templates ?? [];
        }
    }, [state.search, templatesReq.data?.templates]);
    const empty = (
        <Paper py={5} ai="center" df fd="column" mt={2.5}>
            <Box mb={3}>
                <PositiveMascot style={{width: '200px', height: '133px'}} />
            </Box>
            <Text size="l" bold>
                {state.filter === FILTER_TEMPLATES ? locale.emptyTemplates : locale.empty}
            </Text>
        </Paper>
    );
    const noResult = (
        <Paper py={5} ai="center" df fd="column" mt={2.5}>
            <Box mb={3}>
                <SadMascot style={{width: '200px', height: '133px'}} />
            </Box>
            <Text size="l" bold>
                {locale.noResult}
            </Text>
        </Paper>
    );

    useEffect(() => {
        if (!statementsReq.isLoading && !getBalanceReq.isLoading) {
            clearTimeout(searchTimeout.current);
            searchTimeout.current = setTimeout(() => {
                queryClient.removeQueries({queryKey});
                statementsReq.refetch();
            }, 500);
        }
    }, [state.search]);

    useEffect(() => {
        return () => {
            clearTimeout(searchTimeout.current);
            // for fix double request in Strict mode.
            const key = [
                '/statements',
                getBalanceReq.data?.data.id,
                `${state.date?.start.format('DDMMYY')}-${state.date?.end.format('DDMMYY')}`,
            ];
            if (queryClient.getQueryState(key)?.status === 'success') {
                queryClient.removeQueries({queryKey: key});
            }
        };
    }, []);

    useEffect(() => {
        if (state.filter === FILTER_TEMPLATES) {
            templatesReq.refetch();
        }
    }, [state.filter]);

    return (
        <Box pb={8}>
            <Filter
                state={state}
                onChange={onChange}
                collection={allStatements}
                templates={filteredTemplates}
                cardId={getBalanceReq.data?.data.id}
            />
            {state.filter === FILTER_TEMPLATES ? (
                templatesReq.isLoading ? (
                    <Skeleton />
                ) : templatesReq.isError ? (
                    <Paper mt={2.5}>
                        <ServiceError error={templatesReq.error} onRetry={templatesReq.refetch} />
                    </Paper>
                ) : templatesReq.isSuccess ? (
                    templatesReq.data.templates?.length ? (
                        filteredTemplates.length ? (
                            <TemplatesList collection={filteredTemplates} />
                        ) : (
                            noResult
                        )
                    ) : (
                        empty
                    )
                ) : null
            ) : statementsReq.isError ? (
                <Paper mt={2.5}>
                    <ServiceError error={statementsReq.error} onRetry={statementsReq.refetch} />
                </Paper>
            ) : getBalanceReq.isError ? (
                <Paper mt={2.5}>
                    <ServiceError error={getBalanceReq.error} onRetry={getBalanceReq.refetch} />
                </Paper>
            ) : paymentsReq.isError ? (
                <Paper mt={2.5}>
                    <ServiceError error={paymentsReq.error} onRetry={paymentsReq.refetch} />
                </Paper>
            ) : statementsReq.isLoading ||
              statementsReq.isPending ||
              paymentsReq.isPending ||
              paymentsReq.isLoading ? (
                <Skeleton />
            ) : statementsReq.isSuccess ? (
                allStatements.length ? (
                    filteredList.length ? (
                        <>
                            <List collection={filteredList} payments={paymentsReq.data?.data} />
                            {statementsReq.hasNextPage ? (
                                <Box mt={2.5} df jc="center">
                                    <LoadingButton
                                        secondary
                                        small
                                        onClick={() => statementsReq.fetchNextPage()}
                                        loading={statementsReq.isFetchingNextPage}
                                    >
                                        {locale.more}
                                    </LoadingButton>
                                </Box>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {paymentsReq.data?.data.length ? (
                                <Payments collection={paymentsReq.data?.data} />
                            ) : null}
                            {noResult}
                        </>
                    )
                ) : (
                    <>
                        {paymentsReq.data?.data.length ? (
                            <Payments collection={paymentsReq.data?.data} />
                        ) : null}
                        {state.search ? noResult : empty}
                    </>
                )
            ) : null}
        </Box>
    );
};

export default Statement;
