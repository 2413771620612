import React from 'react';
import Box from '@ff/web-components/components/Box';
import Search from '@/components/Search';
import Text from '@ff/web-components/components/Text';
import {getLocale, t} from '@ff/web-components/utils/l10n';
import {State} from '@/features/Dashboard/statement';
import {OnChange} from '@/hooks/useForm';
import {Response as TemplatesResponse} from '@/features/Templates/api/getList';
import {Dictionary} from '@/locale';

interface IProps {
    collection?: TemplatesResponse['list'];
    state: State;
    onChange: OnChange;
}

const TemplatesFilter = ({collection, state, onChange}: IProps) => {
    const locale: Dictionary['statements'] = getLocale().statements;

    return (
        <>
            <Box df>
                <Search
                    wide
                    name="search"
                    value={state.search}
                    onChange={onChange}
                    placeholder={locale?.searchPlaceholder}
                />
            </Box>
            {collection?.length ? (
                <Box mt={2.5} df ai="center">
                    <Text size="xs" color="secondary">
                        {t(locale?.templatesTotal, {
                            n: String(collection.length),
                        })}
                    </Text>
                </Box>
            ) : null}
        </>
    );
};

export default TemplatesFilter;
