import axios from 'axios';
import url from '@/api/url';
import {Statement} from '@/features/Accounts/api/getPayments';

export type Params = {
    notificationId: string;
};

export type Response = {
    header: {
        avatar: {
            iconUrl: string;
        };
        title: string;
        subtitles?: string[];
    };
    input: {
        sum: number;
        currency: string;
        payload?: {
            priceSubtitles: string[];
        };
    };
    extraFields?: {
        title: string;
        value: string;
    }[];
};

export const QUERY_KEY = 'paymentConfirmation/info';

export default async function getPayments(statementId: Statement['id']): Promise<Response> {
    return axios.get(url('paymentConfirmation/info'), {params: {statementId}});
}
