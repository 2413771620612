import React from 'react';
import defaultTheme from '../theme';
import moment from 'moment/moment';
import Shortcut from '../style/shortcut';
import Text from '../../Text';
import Root from '../style/side';

interface IProps {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    min: number;
    max: number;
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
}

const Months = ({min, max, onSelect, theme}: IProps) => {
    const content: any = [];
    for (let i = min; i <= max; i++) {
        const month = moment()
            .month(i - 1)
            .startOf('month');

        content.push(
            <Shortcut
                key={i}
                onClick={() => onSelect(month, moment(month).endOf('month'))}
                $theme={theme}
            >
                <Text size="m" tt="capitalize" color="primary">
                    {month.format('MMMM')}
                </Text>
            </Shortcut>,
        );
    }
    return <Root $theme={theme}>{content}</Root>;
};

export default Months;
