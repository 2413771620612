import React from 'react';
import Shortcut from '../style/shortcut';
import {Shortcuts} from '../index';
import Box from '../../Box';
import ArrowRight from '../../Icon/ArrowRight';
import Divider from '../../Divider';
import Text from '../../Text';
import defaultTheme from '../theme';

export interface IProps {
    onSelect: (shortcut: Shortcuts) => void;
    theme: Partial<ReturnType<typeof defaultTheme>>;
    divider?: boolean;
    selected?: boolean;
    shortcut: Shortcuts;
    name: string;
}

const Group = ({onSelect, theme, selected, name, shortcut, divider}: IProps) => {
    return (
        <>
            <Shortcut
                onClick={() => onSelect(shortcut)}
                $theme={theme}
                $selected={selected}
                data-shortcut-group-name={shortcut}
            >
                <Box df jc="space-between" ai="center">
                    <Text size="m" color="primary">
                        {name}
                    </Text>
                    <ArrowRight
                        color={
                            selected
                                ? theme.theme?.palette?.grey['1000']
                                : theme.theme?.palette?.grey['600']
                        }
                    />
                </Box>
            </Shortcut>
            {divider ? (
                <Box px={2.5}>
                    <Divider />
                </Box>
            ) : null}
        </>
    );
};

export default Group;
