import React from 'react';
import {Statement} from '@/features/Accounts/api/getPayments';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import CategoryIcon from '@/features/Dashboard/statement/list/style/categoryIcon';
import stringify from '@ff/web-components/utils/number/stringify';
import useToggle from '@/hooks/useToggle';
import Modal from '@ff/web-components/components/Modal';
import Description from '@/features/Dashboard/statement/list/style/description';
import ItemWrapper from '@/features/Dashboard/statement/payments/style/itemWrapper';
import Details from './details';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import useAccount from '@/hooks/useAccount';

const itemStyle = {cursor: 'pointer'};

const Item = ({content, value, id}: Statement) => {
    const [details, toggleDetails] = useToggle(false);
    const account = useAccount();

    return (
        <>
            <Box py={1.25} px={1} style={itemStyle} onClick={() => toggleDetails()}>
                <ItemWrapper>
                    <Box mr={2.5} fs={0} w={5} df>
                        {content.icon ? (
                            <CategoryIcon src={categoryIcon(content.icon, account.id)} />
                        ) : null}
                    </Box>
                    <Box mr={5} fg={3} fd="column" df>
                        {content.title ? <Text>{content.title}</Text> : null}
                        <Text size="xs" color="secondary">
                            <Description>{content.subTitle.text}</Description>
                        </Text>
                    </Box>
                    <Box w={12} fs={0} df jc="flex-end">
                        <Text size="m">{stringify(value.amount, {toFixed: 2})}</Text>
                    </Box>
                </ItemWrapper>
            </Box>
            <Modal open={details} onClose={() => toggleDetails()}>
                <Details onClose={() => toggleDetails()} id={id} />
            </Modal>
        </>
    );
};

export default Item;
