import styled from 'styled-components';

const UserImage = styled.img`
    display: block;
    border-radius: 50%;
    ${({theme}) => ({
        width: theme.c.gutter * 5 + 'px',
        height: theme.c.gutter * 5 + 'px',
    })}
`;

export default UserImage;
