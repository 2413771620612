import React, {useCallback} from 'react';
import Input, {IProps as InputProps} from '../index';
import {SyntheticEvent} from 'utils/syntheticEvent';

export type IProps = InputProps;

const NumberInput = React.forwardRef<HTMLInputElement, IProps>(
    ({onChange, value, inputMode = 'decimal', ...rest}: IProps, ref) => {
        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent) => {
                if (typeof onChange === 'function') {
                    if (e.target.value) {
                        e.currentTarget.value = e.target.value = e.target.value.replace(/\D/g, '');
                    }
                    onChange(e);
                }
            },
            [onChange],
        );

        return (
            <Input
                {...rest}
                onChange={handleChange}
                value={value}
                inputMode={inputMode}
                ref={ref}
            />
        );
    },
);

NumberInput.displayName = 'NumberInput';

export default NumberInput;
