import styled from 'styled-components';

const LoanInfo = styled.div`
    display: flex;
    flex-direction: column;
    ${({theme}) => ({
        fontFamily: theme.c.text.fontFamily,
        fontSize: theme.c.text.fontSize.m,
        lineHeight: theme.c.text.lineHeight.m,
        color: theme.c.palette.text.primary,
        gap: theme.c.gutter * 2 + 'px',
    })};

    h2 {
        ${({theme}) => ({
            fontSize: theme.c.text.fontSize.xl,
            lineHeight: theme.c.text.lineHeight.xl,
            color: theme.c.palette.text.primary,
            fontWeight: theme.c.text.fontWeight.bold,
            textAlign: 'center',
        })};
    }

    b {
        ${({theme}) => ({
            fontWeight: theme.c.text.fontWeight.bold,
        })};
    }
`;

export default LoanInfo;
