'use client';
'use strict';
import React from 'react';
import Root from '../style/item';
import defaultTheme from '../theme';
import theme from '../../theme';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const OptionsItem = ({children, theme, ...rest}: IProps) => {
    return (
        <Root $theme={theme} {...rest}>
            {children}
        </Root>
    );
};

export default theme<IProps, HTMLDivElement>(OptionsItem, 'options', 'OptionsItem');
