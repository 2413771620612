import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Shadow = styled.textarea<RootProps>`
    ${({$theme}) => ({
        visibility: 'hidden',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        padding: 0,
        margin: 0,
        border: 'none',
        resize: 'none',
        outline: 'none',
        overflow: 'hidden',
        width: '100%',
        verticalAlign: 'top',
    })}
`;

export default Shadow;
