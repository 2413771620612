import offset from 'utils/offset';

export interface IConstraintsArgs {
    root: HTMLDivElement;
    list: HTMLDivElement;
    doc: {
        w: number;
        h: number;
    };
    gapTop: number;
    gapLeft: number;
    gapBottom: number;
    gapRight: number;
    offsetTop: number;
    offsetBottom: number;
    offsetLeft: number;
    offsetRight: number;
    listShiftY: number;
    position?: {
        x: 'left' | 'center' | 'right';
        y: 'top' | 'center' | 'bottom';
    };
}

export interface IConstraints {
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
    maxWidth?: string;
    maxHeight?: string;
}

const computeConstraints = ({
    root,
    list,
    doc,
    gapTop,
    gapLeft,
    gapBottom,
    gapRight,
    offsetTop,
    offsetBottom,
    offsetLeft,
    offsetRight,
    listShiftY,
    position,
}: IConstraintsArgs) => {
    if (!root?.offsetParent || !list) {
        return {};
    }

    const {offsetWidth, offsetHeight} = root.offsetParent as HTMLDivElement,
        rootOffset = offset(list),
        width = list.offsetWidth,
        height = list.offsetHeight;
    let maxWidth = 'none',
        maxHeight = 'none',
        top: string | number = 'auto',
        right: string | number = 'auto',
        bottom: string | number = 'auto',
        left: string | number = 'auto';

    if (listShiftY) {
        rootOffset.top += height * listShiftY;
    }

    if (rootOffset) {
        const availTop = rootOffset.top - offsetHeight - gapTop,
            availBottom = doc.h - rootOffset.top - gapBottom,
            availLeft = rootOffset.left + offsetWidth - gapLeft,
            // minimal padding + scroll
            availRight = doc.w - rootOffset.left - gapRight,
            dirTop = `calc(100% + ${offsetTop}px)`,
            dirBottom = `calc(100% + ${offsetBottom}px)`,
            dirLeft = offsetLeft,
            dirRight = offsetRight;

        // direct position was settled
        if (position) {
            if (position.y === 'top') {
                bottom = dirBottom;
                maxHeight = availTop + 'px';
            } else if (position.y === 'bottom') {
                top = dirTop;
                maxHeight = availBottom + 'px';
            } else if (position.y === 'center') {
                top = (height / 2 - offsetHeight / 2) * -1 + 'px';
                maxHeight = availBottom + 'px';
            }

            if (position.x === 'left') {
                right = position.y === 'center' ? `calc(100% + ${dirRight}px)` : dirLeft;
                maxWidth = availLeft + 'px';
            } else if (position.x === 'right') {
                left = position.y === 'center' ? `calc(100% + ${offsetLeft}px)` : dirLeft;
                maxWidth = availRight + 'px';
            } else if (position.x === 'center') {
                left = (width / 2 - offsetWidth / 2) * -1 + 'px';
                maxWidth = availRight + 'px';
            }
            // default position detection
        } else {
            // нет места сверху и снизу
            if (availBottom < height && availTop < height) {
                if (availTop > availBottom) {
                    bottom = dirBottom;
                    maxHeight = availTop + 'px';
                } else {
                    top = dirTop;
                    maxHeight = availBottom + 'px';
                }
                // нет места снизу, полностью влазит сверху
            } else if (availBottom < height && availTop >= height) {
                bottom = dirBottom;
                maxHeight = availTop + 'px';
            } else {
                top = dirTop;
                maxHeight = availBottom + 'px';
            }

            // нет места справа/слева
            if (availRight < width && availLeft < width) {
                if (availLeft > availRight) {
                    right = dirRight;
                    maxWidth = availLeft + 'px';
                } else {
                    left = dirLeft;
                    maxWidth = availRight + 'px';
                }
                // нет места справа, полностью влазит слева
            } else if (availRight < width && availLeft >= width) {
                right = dirRight;
                maxWidth = availLeft + 'px';
            } else {
                left = dirLeft;
                maxWidth = availRight + 'px';
            }
        }
    }

    return {
        top,
        right,
        bottom,
        left,
        maxWidth,
        maxHeight,
    };
};

export default computeConstraints;
