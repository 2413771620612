import React from 'react';
import {Statement} from '@/features/Accounts/api/getStatement';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import IconButton from '@ff/web-components/components/IconButton';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import CrossClose from '@ff/web-components/components/Icon/CrossClose';
import moment from 'moment';
import Head from '@/features/Dashboard/statement/details/head';
import Body from '@/features/Dashboard/statement/details/body';
import Divider from '@ff/web-components/components/Divider';
import Footer from './footer';

interface IProps {
    content: Statement['content'];
    status: Statement['status'];
    value: Statement['value'];
    detailContent: Statement['detailContent'];
    id: Statement['id'];
    date: moment.Moment;
    onBack?: () => void;
    onClose?: () => void;
}

const StatementDetails = ({content, onBack, onClose, date, value, detailContent, id}: IProps) => {
    return (
        <Box w={47}>
            <Paper px={2.5} pt={2.5}>
                <Box mb={1} df jc="space-between">
                    {onBack ? (
                        <IconButton onClick={onBack} secondary>
                            <PointerLeft />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                    {onClose ? (
                        <IconButton onClick={onClose} secondary>
                            <CrossClose />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                </Box>
                <Head
                    icon={content.icon}
                    receiver={content.title}
                    subTitle={content.subTitle}
                    badgeColor={detailContent.badgeColor}
                    amount={value.amount}
                    commission={value.commission}
                    currencySymbol={value.currencySymbol}
                    date={date}
                    balance={value.balance}
                    declineReason={detailContent.declineReason}
                />
                {detailContent.params ? (
                    <>
                        <Divider />
                        <Body params={detailContent.params} />
                    </>
                ) : null}
                {detailContent.buttons ? <Footer buttons={detailContent.buttons} id={id} /> : null}
            </Paper>
        </Box>
    );
};

export default StatementDetails;
