import {Theme} from '../index';

export default function (theme: Theme) {
    return {
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar:horizontal': {
            height: '6px',
        },
        '&::-webkit-scrollbar-button': {
            display: 'none',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette?.grey['400'],
            borderRadius: '4px',
            transition: `background ${theme.transition?.duration} ${theme.transition?.timingFunction}`,
        },
        '&::-webkit-scrollbar-corner': {
            display: 'none',
        },
        '&::-webkit-resizer': {
            display: 'none',
        },
    };
}
