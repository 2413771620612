import {Step} from '@/features/Auth';
import axios from 'axios';
import url from '@/api/url';

export interface Response {
    result: Step;
}

export default async function status(): Promise<Response> {
    return axios.get(url('auth/status'));
}
