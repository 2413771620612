import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import syntheticEvent, {SyntheticEvent} from 'utils/syntheticEvent';
import defaultTheme from './theme';
import theme from '../theme';
import mergeTheme from 'themes/utils/merge';
import Root from '../Input/style/root';
import StartIconContainer from '../Input/style/startIconContainer';
import LabelWrapper from '../Input/style/labelWrapper';
import LabelFloatBase from '../Input/style/labelFloatBase';
import LabelHolder from '../Input/style/labelHolder';
import LabelStyle from '../Input/style/label';
import InputStyle from './style/input';
import ActionContainer from '../Input/style/actionContainer';
import CloseAction from '../Input/closeAction';
import InputWrapper from './style/inputWrapper';
import ptn from '../../themes/utils/pixelToNumber';
import Shadow from './style/shadow';
import {DeepPartial} from 'themes/index';

type ITextAreaNative = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'style' | 'value'>;

export interface IProps extends ITextAreaNative {
    label?: React.ReactNode;
    value?: string | number | null;
    error?: boolean;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement> | SyntheticEvent) => void;
    placeholder?: string;
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    disabled?: boolean;
    className?: string;
    wide?: boolean;
    defaultValue?: string;
    endIcon?: false | React.ReactNode;
    startIcon?: React.ReactNode;
    rowsMax?: number;
}

const TextAreaMain = React.forwardRef<HTMLTextAreaElement, IProps>(
    (
        {
            value,
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onChange,
            onBlur,
            style,
            disabled,
            error,
            className,
            placeholder,
            startIcon,
            endIcon,
            wide,
            label,
            theme,
            rows = 1,
            rowsMax,
            ...rest
        }: IProps,
        ref,
    ) => {
        const [height, setHeight] = useState<string | number>();
        const [hover, setHover] = useState(false);
        const [focus, setFocus] = useState(false);
        const shadow = useRef<HTMLTextAreaElement | null>(null);
        const inputInner = useRef<HTMLTextAreaElement | null>(null);
        const inputReference = ref || inputInner;
        value = value ?? '';
        const handleEnter = useCallback(
            e => {
                setHover(true);
                if (typeof onMouseEnter === 'function') {
                    onMouseEnter(e);
                }
            },
            [onMouseEnter],
        );
        const handleLeave = useCallback(
            e => {
                setHover(false);
                if (typeof onMouseLeave === 'function') {
                    onMouseLeave(e);
                }
            },
            [onMouseLeave],
        );
        const handleFocus = useCallback(
            e => {
                setFocus(true);
                if (typeof onFocus === 'function') {
                    onFocus(e);
                }
            },
            [onFocus, onChange],
        );
        const handleBlur = useCallback(
            e => {
                setFocus(false);
                if (typeof onBlur === 'function') {
                    onBlur(e);
                }
            },
            [onBlur],
        );
        const handleLabelClick = useCallback(() => {
            if (typeof inputReference === 'object' && inputReference.current) {
                inputReference.current?.focus();
            }
        }, [inputReference]);
        const handleClear = useCallback(() => {
            if (
                typeof onChange === 'function' &&
                typeof inputReference === 'object' &&
                inputReference.current
            ) {
                inputReference.current.value = '';
                onChange(syntheticEvent(inputReference.current));
            }
        }, [onChange]);
        const labelTop = focus || !!String(value);

        const compiledTheme = useMemo(() => {
            const compiled = mergeTheme(theme, style);
            return {
                input: {
                    ...compiled,
                    label: undefined,
                },
                label: {
                    theme: compiled.theme,
                    ...compiled.label,
                },
            };
        }, [theme, style]);

        useLayoutEffect(() => {
            let result: string | number = 'auto';

            if (shadow.current) {
                let height = shadow.current.scrollHeight;
                const lineHeight =
                    typeof compiledTheme.input.lineHeight === 'string'
                        ? ptn(compiledTheme.input.lineHeight)
                        : typeof compiledTheme.input.lineHeight === 'number'
                        ? ptn(compiledTheme.input.fontSize) * compiledTheme.input.lineHeight
                        : height;

                if (shadow.current && lineHeight) {
                    let maxHeight;

                    if (rows > 1) {
                        height = rows * lineHeight;
                    }

                    if (rowsMax) {
                        maxHeight = Number(rowsMax) * lineHeight;
                    }

                    result = maxHeight ? Math.min(height, maxHeight) : height;
                }
            }

            setHeight(result);
        }, [shadow.current, rows, value, rowsMax]);

        return (
            <Root
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                $theme={compiledTheme.input}
                className={className}
                $wide={wide}
                $hover={hover}
                $focus={focus}
                $error={error}
                $disabled={disabled}
            >
                {startIcon ? (
                    <StartIconContainer $theme={compiledTheme.input.theme}>
                        {startIcon}
                    </StartIconContainer>
                ) : null}
                {label ? (
                    <LabelWrapper
                        $theme={compiledTheme.label}
                        onClick={handleLabelClick}
                        $padRight={labelTop}
                        $padLeft={!!startIcon}
                    >
                        <LabelFloatBase>
                            <LabelHolder $theme={compiledTheme.label}>&nbsp;</LabelHolder>
                            <LabelStyle
                                $theme={compiledTheme.label}
                                $error={error}
                                $top={labelTop}
                                $toFontSize={compiledTheme.input.fontSize}
                                $toLineHeight={compiledTheme.input.lineHeight}
                                $gap={2}
                                $disabled={disabled}
                            >
                                {label}
                            </LabelStyle>
                        </LabelFloatBase>
                    </LabelWrapper>
                ) : null}
                <InputWrapper $withLabel={!!label} $theme={compiledTheme.input}>
                    <InputStyle
                        {...rest}
                        $withLabel={!!label}
                        $theme={compiledTheme.input}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={inputReference}
                        placeholder={!label ? placeholder : ''}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        $padLeft={!!startIcon}
                        rows={rows}
                        style={{height}}
                    />
                    <Shadow
                        ref={shadow}
                        value={value}
                        rows={1}
                        $theme={compiledTheme.input}
                        tabIndex={-1}
                        aria-hidden="true"
                        readOnly
                    />
                </InputWrapper>
                {endIcon !== false ? (
                    <ActionContainer $theme={compiledTheme.input.theme}>
                        {endIcon ||
                            (!!String(value) && (focus || hover) ? (
                                <CloseAction
                                    theme={compiledTheme.input.theme}
                                    onClick={handleClear}
                                />
                            ) : null)}
                    </ActionContainer>
                ) : null}
            </Root>
        );
    },
);

TextAreaMain.displayName = 'TextArea';

export default theme<IProps, HTMLTextAreaElement>(TextAreaMain, 'textArea', 'TextArea');
