import styled from 'styled-components';

const Content = styled.div`
    ${({theme}) => ({
        margin: `0 auto`,
        width: theme.c.gutter * 46 + 'px',
        [theme.p.media.mobile]: {
            width: '100%',
        },
    })}
`;

export default Content;
