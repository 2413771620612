import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import store from '../../store';
import Application from './index';
import {BrowserRouter} from 'react-router-dom';
import ThemeProvider from '@ff/web-components/components/themeProvider';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import useTheme from '@/hooks/useTheme';
import {QueryClientProvider} from '@tanstack/react-query';
import queryClient from '../../api/queryClient';
import Responsive from './responsive';
import SnackBarProvider from '@ff/web-components/components/SnackBar/Provider';

const AppProvider = () => {
    const theme = useTheme();

    return (
        <React.StrictMode>
            <ReduxProvider store={store}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <ThemeProvider>
                            <StyledThemeProvider theme={theme}>
                                <Responsive>
                                    <SnackBarProvider>
                                        <Application />
                                    </SnackBarProvider>
                                </Responsive>
                            </StyledThemeProvider>
                        </ThemeProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </ReduxProvider>
        </React.StrictMode>
    );
};

export default AppProvider;
