'use client';
'use strict';
import React, {useLayoutEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {animated, useTransition} from '@react-spring/web';
import ownerDocument from 'utils/ownerDocument';
import type {IConstraints} from './computeConstraints';
import computeConstraints from './computeConstraints';

const Content = styled.div`
    position: absolute;
    min-width: 100%;
`;

const Animated = animated(Content);

const DEFAULT_CONSTRAINTS = {
    maxHeight: 'none',
    maxWidth: 'none',
    top: '100%',
    left: 0,
    bottom: 'auto',
    right: 'auto',
};

const transitionConfig = {
    from: {
        opacity: 0,
        transform: 'translateY(-20%)',
    },
    enter: {
        opacity: 1,
        transform: 'translateY(0%)',
    },
    leave: {
        opacity: 0,
        transform: 'translateY(-20%)',
    },
    config: {duration: 150},
};

export interface IProps {
    active: boolean;
    children: React.ReactNode;
    offsetTop?: number;
    offsetRight?: number;
    offsetBottom?: number;
    offsetLeft?: number;
    gapTop?: number;
    gapRight?: number;
    gapBottom?: number;
    gapLeft?: number;
    zIndex?: number;
    minWidth?: number | string;
    position?: {
        x: 'left' | 'center' | 'right';
        y: 'top' | 'center' | 'bottom';
    };
}

const Overlay = ({
    active,
    children,
    zIndex = 999,
    minWidth,
    gapTop = 8,
    gapLeft = 24,
    gapBottom = 8,
    gapRight = 24,
    offsetTop = 0,
    offsetBottom = 0,
    offsetLeft = 0,
    offsetRight = 0,
    position,
}: IProps) => {
    const [constraints, setConstraints] = useState<IConstraints>(DEFAULT_CONSTRAINTS);
    const root = useRef(null);
    const list = useRef(null);
    const size = useRef({
        h: 0,
        w: 0,
    });
    const config = useMemo(
        () => ({
            ...transitionConfig,
            onDestroyed: !active ? () => setConstraints(DEFAULT_CONSTRAINTS) : undefined,
        }),
        [active],
    );
    const transitions = useTransition(active ? [1] : [], config);
    const doc = typeof window !== 'undefined' ? ownerDocument(root.current) : undefined;
    // save it here, before dropdown is closed and options content are invisible
    size.current = {
        h: doc?.body.offsetHeight ?? 0,
        w: doc?.body.offsetWidth ?? 0,
    };

    useLayoutEffect(() => {
        if (active && root.current && list.current) {
            setConstraints(
                computeConstraints({
                    root: root.current,
                    list: list.current,
                    // transition start position
                    listShiftY: 0.2,
                    doc: size.current,
                    gapTop,
                    gapLeft,
                    gapBottom,
                    gapRight,
                    offsetTop,
                    offsetBottom,
                    offsetLeft,
                    offsetRight,
                    position,
                }),
            );
        }
    }, [active]);

    return (
        <div ref={root}>
            {transitions(style => (
                <Animated
                    style={{
                        ...constraints,
                        zIndex,
                        minWidth,
                        ...style,
                    }}
                    ref={list}
                >
                    {children}
                </Animated>
            ))}
        </div>
    );
};

export default Overlay;
