import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Search: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 10.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0ZM10.5 3a7.5 7.5 0 1 0 4.78 13.28L20 21l1.414-1.414-4.775-4.776A7.5 7.5 0 0 0 10.5 3Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Search, 'icon', 'Search');
