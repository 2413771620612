import React from 'react';
import Paper from '@ff/web-components/components/Paper';
import Radio from '@ff/web-components/components/Radio';
import Box from '@ff/web-components/components/Box';
import {getLocale} from '@ff/web-components/utils/l10n';
import {State} from '@/features/Dashboard/statement';
import {OnChange} from '@/hooks/useForm';
import {Response} from '@/features/Accounts/api/getStatement';
import {Response as TemplatesResponse} from '@/features/Templates/api/getList';
import StatementsFilter from '@/features/Dashboard/statement/filter/statements';
import TemplatesFilter from '@/features/Dashboard/statement/filter/templates';
import {
    FILTER_ALL,
    FILTER_INCOME,
    FILTER_SPEND,
    FILTER_TEMPLATES,
} from '@/features/Dashboard/constants';
import {Dictionary} from '@/locale';
import {Response as BalanceResponse} from '@/features/Accounts/api/getBalance';

interface IProps {
    state: State;
    onChange: OnChange;
    collection?: Response['data'];
    templates?: TemplatesResponse['templates'];
    cardId?: BalanceResponse['data']['id'];
}

const Filter = ({state, onChange, collection, templates, cardId}: IProps) => {
    const locale: Dictionary['statements'] = getLocale().statements;

    return (
        <Paper p={2.5}>
            <Box df mb={2.5}>
                <Radio
                    checked={state.filter === FILTER_ALL}
                    onChange={onChange}
                    value={FILTER_ALL}
                    name="filter"
                >
                    {locale.filterAll}
                </Radio>
                <Radio
                    checked={state.filter === FILTER_INCOME}
                    onChange={onChange}
                    value={FILTER_INCOME}
                    name="filter"
                >
                    {locale.filterIncome}
                </Radio>
                <Radio
                    checked={state.filter === FILTER_SPEND}
                    onChange={onChange}
                    value={FILTER_SPEND}
                    name="filter"
                >
                    {locale.filterSpend}
                </Radio>
                <Radio
                    checked={state.filter === FILTER_TEMPLATES}
                    onChange={onChange}
                    value={FILTER_TEMPLATES}
                    name="filter"
                >
                    <Box df ai="center">
                        {locale.filterTemplates}
                    </Box>
                </Radio>
            </Box>
            {state.filter === FILTER_TEMPLATES ? (
                <TemplatesFilter state={state} onChange={onChange} collection={templates} />
            ) : (
                <StatementsFilter
                    state={state}
                    onChange={onChange}
                    collection={collection}
                    cardId={cardId}
                />
            )}
        </Paper>
    );
};

export default Filter;
