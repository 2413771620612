import React from 'react';
import Root from './style';
import Paper from '@ff/web-components/components/Paper';
import Button from '@ff/web-components/components/Button';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import logo from '../../../img/logo_round.svg';
import {getLocale} from '@ff/web-components/utils/l10n';
import {onBack, onSubmit} from '@/features/Auth';
import {Dictionary} from '@/locale';

interface IProps {
    onSubmit: onSubmit;
    onBack?: onBack;
}

const AppConfirm = ({onBack}: IProps) => {
    const locale: Dictionary['auth']['app'] = getLocale().auth.app;

    return (
        <Box mt={14}>
            <Root>
                <Paper pb={5} pt={2.5} px={2.5}>
                    <Box mb={1}>
                        <Button round small secondary onClick={onBack}>
                            <PointerLeft />
                        </Button>
                    </Box>
                    <Box df jc="center" mb={2}>
                        <img src={logo} alt="Leobank" width="64px" height="64px" />
                    </Box>
                    <Box mb={1}>
                        <Text size="xl" align="center" bold>
                            {locale.title}
                        </Text>
                    </Box>
                    <Text size="xs" color="secondary" align="center">
                        {locale?.hint}
                    </Text>
                </Paper>
            </Root>
        </Box>
    );
};

export default AppConfirm;
