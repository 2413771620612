import React, {useCallback, useEffect, useMemo, useState} from 'react';
import defaultTheme from './theme';
import Box from '../Box';
import Input from '../Input';
import ArrowLeft from '../Icon/ArrowLeft';
import ArrowRight from '../Icon/ArrowRight';
import Text from '../Text';
import CalendarRoot from './style/calendar';
import moment from 'moment';
import Day from './style/day';
import {DatePickerSelected, DatePickerSelectedDay, DatePickerSelectedPeriod} from './index';
import formatter from 'utils/formatter';
import IconButton from '../IconButton';
import {parseDate} from './dateSelector';

interface IProps {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    startMonth: number;
    startYear: number;
    selected: DatePickerSelected;
    onSelect: (value: moment.Moment, unset?: boolean, name?: 'start' | 'end') => void;
    period?: boolean;
    minYear?: number;
    maxYear?: number;
    minMonth?: number;
    maxMonth?: number;
    allowedWeekDays?: number[];
    deniedDates?: moment.Moment[];
    allowedDates?: moment.Moment[];
    inputValue?: string;
    name: 'start' | 'end';
}

const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];
const now = moment();
const dateInputFormat = '{{dd}}.{{dd}}.{{dd}}';

const getCalendar = (from, to) => {
    from = moment(from);
    to = moment(to);
    // if greater then monday - pad start of week
    if (from.get('day') > 1) {
        from.day(1);
        // if sunday - pad start of week
    } else if (from.get('day') === 0) {
        from.day(-6);
    }

    // if not sunday - add rest of week
    if (to.get('day') > 0) {
        to.day(7);
    }

    // fill up calendar to 6 rows total
    const daysInCalendar = to.diff(from, 'days') + 1;
    if (daysInCalendar <= 28) {
        to.day(14);
    } else if (daysInCalendar <= 35) {
        to.day(7);
    }

    return [from, to];
};

const isInSelectedRange = ({start, end}, date) => {
    if (start.isAfter(end)) {
        const s = start;
        start = end;
        end = s;
    }

    return date.isAfter(start) && date.isBefore(end);
};

// period highlight settings
const getBorderDays = ({start, end}, {from, to}) => {
    let first,
        firstMon,
        firstSun,
        last,
        lastMon,
        lastSun,
        firstSingle,
        lastSingle,
        rangeStartPoint,
        rangeEndPoint;

    if (start.isAfter(end)) {
        const s = start;
        start = end;
        end = s;
    }
    start = moment(start).startOf('date');
    end = moment(end).startOf('date');

    if (
        (start.isSameOrBefore(from) || start.isSameOrBefore(to)) &&
        (end.isSameOrAfter(to) || end.isSameOrAfter(from))
    ) {
        const rangeStart = moment(start.isBefore(from) ? from : start);
        const rangeEnd = moment(end.isAfter(to) ? to : end);

        // selected day is a start point
        rangeStartPoint =
            rangeEnd.isAfter(rangeStart) && start.get('day') !== 0 ? moment(start) : undefined;
        // selected day is an end point
        rangeEndPoint =
            rangeStart.isBefore(rangeEnd) && end.get('day') !== 1 ? moment(end) : undefined;
        // first day in range
        first = start.isBefore(from) ? from : start.isBefore(to) ? start : undefined;
        // last day of range
        last = end.isAfter(to) ? to : end.isAfter(from) ? end : undefined;

        let i = 0;
        const length = rangeStart.diff(rangeEnd, 'days') * -1;
        for (let d = moment(rangeStart); d.diff(rangeEnd, 'days') <= 0; d.add(1, 'days')) {
            const daysLeft = d.diff(rangeEnd, 'days') * -1;
            // first day in range which end less than week (do not cross 2d line)
            firstSingle = length < 7 ? first : undefined;
            // first day in range which end less than week (do not cross 2d line)
            lastSingle = length < 7 ? last : undefined;

            if (d.get('day') === 1 && !d.isSame(start) && !d.isSame(end)) {
                // first monday in range
                firstMon = !firstMon ? moment(d) : firstMon;
                // last monday in range
                lastMon = daysLeft <= 7 ? moment(d) : undefined;
            }
            if (d.get('day') === 0 && !d.isSame(start) && !d.isSame(end)) {
                // first sunday in range
                if (!firstSun) {
                    firstSun = moment(d);
                }

                // last sunday in range
                if (daysLeft <= 7) {
                    lastSun = moment(d);
                }
            }
            i++;
        }
    }
    return {
        first,
        firstMon,
        firstSun,
        last,
        lastMon,
        lastSun,
        firstSingle,
        lastSingle,
        rangeStartPoint,
        rangeEndPoint,
    };
};

const Calendar = ({
    theme,
    startMonth,
    startYear,
    onSelect,
    selected,
    period,
    minYear,
    maxYear,
    minMonth,
    maxMonth,
    allowedWeekDays,
    deniedDates,
    allowedDates,
    inputValue,
    name,
}: IProps) => {
    const inputStyle = useMemo(
        () => ({
            fontSize: theme?.theme?.text?.fontSize?.xs,
            lineHeight: theme?.theme?.text?.lineHeight?.xs,
        }),
        [theme],
    );
    const [date, setDate] = useState(inputValue);
    const [month, setMonth] = useState(startMonth);
    const [year, setYear] = useState(startYear);
    const [monthStart, monthEnd, from, to] = useMemo(() => {
        const monthStart = moment({month, year, d: 1});
        const monthEnd = moment(monthStart).date(monthStart.daysInMonth());
        const [from, to] = getCalendar(monthStart, monthEnd);

        return [monthStart, monthEnd, from, to];
    }, [month, year]);
    const content: any = [];
    const sp = selected as DatePickerSelectedPeriod;
    const s = selected as DatePickerSelectedDay;
    const handleSelect = useCallback(
        e => {
            const value = moment(e.currentTarget.getAttribute('data-value'));
            if (period) {
                onSelect(value, false, name);
            } else {
                onSelect(value, value.isSame(s), name);
            }
        },
        [onSelect, selected, name],
    );
    const handleMonthChange = useCallback(
        shift => {
            const shifted = moment({month, year, date: 1}).add(shift, 'month');
            setMonth(shifted.get('month'));
            setYear(shifted.get('year'));
        },
        [month, year],
    );
    const handleDateInputChange = useCallback(
        e => {
            const value = e.target.value
                ? e.target.value.length === e.target.selectionStart
                    ? formatter(e.target.value, dateInputFormat)
                    : e.target.value
                : '';
            const currentValue = period ? sp[name] : (s as moment.Moment);
            setDate(value);

            if (value.length === 8) {
                const parsed = parseDate(value);
                if (parsed?.isValid()) {
                    if (period) {
                        if (
                            (name === 'start' && sp.end && parsed?.isAfter(sp.end)) ||
                            (name === 'end' && sp.start && parsed?.isBefore(sp.start))
                        ) {
                            return;
                        } else {
                            onSelect(parsed, false, name);
                        }
                    } else {
                        onSelect(parsed, false, name);
                    }
                }
            } else if (!value && currentValue) {
                onSelect(currentValue, true, name);
            }
        },
        [onSelect, selected, name, period],
    );
    const periodProps = useMemo(() => {
        if (period && sp.start && sp.end) {
            return getBorderDays(sp, {from: monthStart, to: monthEnd});
        } else {
            return {} as ReturnType<typeof getBorderDays>;
        }
    }, [period, sp, monthEnd, monthStart]);

    for (let d = moment(from); d.diff(to, 'days') <= 0; d.add(1, 'days')) {
        const disabled =
            d.get('month') !== month ||
            (allowedDates ? !allowedDates.some(date => d.isSame(date, 'date')) : false) ||
            (deniedDates ? deniedDates.some(date => d.isSame(date, 'date')) : false) ||
            (Array.isArray(allowedWeekDays) ? !allowedWeekDays.includes(d.get('day')) : false) ||
            (minMonth ? d.get('month') + 1 < minMonth : false) ||
            (maxMonth ? d.get('month') + 1 > maxMonth : false) ||
            (minYear ? d.get('year') < minYear : false) ||
            (maxYear ? d.get('year') > maxYear : false);
        const active =
            !disabled &&
            (period ? d.isSame(sp.start, 'date') || d.isSame(sp.end, 'date') : d.isSame(s, 'date'));
        const inPeriod =
            period && !disabled && sp.start && sp.end ? isInSelectedRange(sp, d) : false;

        content.push(
            <Day
                key={d.format('DDMM')}
                $disabled={disabled}
                onClick={!disabled ? handleSelect : undefined}
                $active={active}
                $theme={theme}
                data-value={
                    name === 'end'
                        ? moment(d).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                        : d.format('YYYY-MM-DD')
                }
                $period={inPeriod}
                $first={d.isSame(periodProps.first)}
                $firstSingle={d.isSame(periodProps.firstSingle)}
                $firstMon={d.isSame(periodProps.firstMon)}
                $lastMon={d.isSame(periodProps.lastMon)}
                $firstSun={d.isSame(periodProps.firstSun)}
                $lastSun={d.isSame(periodProps.lastSun)}
                $last={d.isSame(periodProps.last)}
                $lastSingle={d.isSame(periodProps.lastSingle)}
                $rangeStartPoint={!disabled && d.isSame(periodProps.rangeStartPoint)}
                $rangeEndPoint={!disabled && d.isSame(periodProps.rangeEndPoint)}
            >
                <Text size="m" color={active ? 'contrast' : !disabled ? 'primary' : 'disabled'}>
                    {d.format('DD')}
                </Text>
            </Day>,
        );
    }

    useEffect(() => {
        setMonth(startMonth);
    }, [startMonth, selected]);

    useEffect(() => {
        setYear(startYear);
    }, [startYear, selected]);

    useEffect(() => {
        setDate(inputValue);
    }, [inputValue]);

    return (
        <div>
            <Box mb={1.5} df>
                <Input
                    style={inputStyle}
                    placeholder={now.format('DD.MM.YY')}
                    wide
                    value={date}
                    onChange={handleDateInputChange}
                    maxLength={8}
                />
            </Box>
            <Box df jc="space-between" ai="center" mb={1.5}>
                <IconButton onClick={() => handleMonthChange(-1)} small>
                    <ArrowLeft />
                </IconButton>
                <Text size="m" bold tt="capitalize" color="primary">
                    {moment({month, year, date: 1}).format('MMMM YYYY')}
                </Text>
                <IconButton onClick={() => handleMonthChange(1)} small>
                    <ArrowRight />
                </IconButton>
            </Box>
            <CalendarRoot>
                {daysOfWeek.map(day => (
                    <Box key={day} df ai="center" jc="center">
                        <Text size="s" color="secondary">
                            {moment().day(day).format('dd')}
                        </Text>
                    </Box>
                ))}
                {content}
            </CalendarRoot>
        </div>
    );
};

export default Calendar;
