import axios from 'axios';
import url from '@/api/url';
import {Destination} from '@/features/TransferToTaxService/api/getDestinations';
import {Response as BalanceResponse} from '@/features/Accounts/api/getBalance';
import {Template} from '@/features/Templates/api/getList';

export type Response = {
    paymentInfo: {
        title: string;
        subTitle: string;
    };
    transaction: {
        [index: string]: any;
        imageUrl?: string;
    };
};

export interface Params {
    cardId: BalanceResponse['data']['id'];
    voen: string;
    paymentTypeId: string;
    paymentDetail: Destination;
    amount: number;
    addTemplate?: boolean;
    templateId?: Template['id'];
}

export default async function confirm(params: Params): Promise<Response> {
    return axios.post(url(`tax/payment`), params);
}
