import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Overlay = styled.div<RootProps>`
    position: fixed;
    top: 0;
    left: 0;
    background: ${props => props.$theme.background};
    min-width: 100%;
    min-height: 100vh;

    &:hover {
        cursor: pointer;
    }
`;

export default Overlay;
