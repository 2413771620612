import React, {useEffect} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToAccount';
import Layout from '../layout';
import {ResponseError} from '@/api/protocol';
import {useMutation} from '@tanstack/react-query';
import saveForm, {Params, Response as SaveResponse} from '../api/saveReceiver';
import TextSkeleton from '@/components/TextSkeleton';
import FormSkeleton from '@/features/TransferToAccount/form/skeleton';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: Partial<State>) => void;
    onBack?: () => void;
}

const TemplateLoader = ({data, onSubmit, onBack}: IProps) => {
    const saveReq = useMutation<SaveResponse, ResponseError, Params>({
        mutationFn: params => saveForm(params),
    });

    useEffect(() => {
        if (data.iban) {
            saveReq.mutate({
                iban: data.iban,
            });
        } else {
            onBack();
        }
    }, []);

    useEffect(() => {
        if (saveReq.isSuccess) {
            onSubmit({
                receiver: saveReq.data?.response?.data,
            });
        }
    }, [saveReq.isSuccess]);

    return (
        <Layout
            onBack={onBack}
            title={<TextSkeleton w={20} size="xl" jc="center" />}
            subTitle={<TextSkeleton w={30} size="xs" jc="center" />}
        >
            <FormSkeleton />
        </Layout>
    );
};

export default TemplateLoader;
