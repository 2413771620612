import styled from 'styled-components';
import {IDropDownSearchTheme} from '../theme';

interface LabelWrapperProps {
    $theme: IDropDownSearchTheme;
}

const LabelWrapper = styled.div<LabelWrapperProps>`
    position: relative;
    ${({$theme}) => ({
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
    })}
`;

export default LabelWrapper;
