import React from 'react';
import Button from '@ff/web-components/components/Button';
import Box from '@ff/web-components/components/Box';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';
import useToggle from '@/hooks/useToggle';
import Modal from '@ff/web-components/components/Modal';
import Paper from '@ff/web-components/components/Paper';
import trash from '@/img/trash.svg';
import Text from '@ff/web-components/components/Text';
import LoadingButton from '@/components/LoadingButton';
import SnackBar from '@ff/web-components/components/SnackBar';

export interface IProps {
    onDelete: () => void;
    onSave: () => void;
    saveLoading?: boolean;
    deleteLoading?: boolean;
    saveSuccess?: boolean;
    deleteSuccess?: boolean;
}

const TemplateControls = ({
    onDelete,
    onSave,
    deleteLoading,
    saveLoading,
    saveSuccess,
    deleteSuccess,
}: IProps) => {
    const locale: Dictionary = getLocale();
    const [modal, toggleModal] = useToggle(false);

    return (
        <>
            <SnackBar open={deleteSuccess || saveSuccess} primary>
                {locale.templates.controls.editSuccess}
            </SnackBar>
            <Box mb={2.5}>
                <LoadingButton
                    secondary
                    wide
                    type="button"
                    onClick={() => onSave()}
                    loading={saveLoading}
                >
                    {locale.templates.controls.save}
                </LoadingButton>
            </Box>
            <LoadingButton
                warning
                wide
                type="button"
                onClick={() => toggleModal()}
                loading={deleteLoading}
            >
                {locale.templates.controls.delete}
            </LoadingButton>
            <Modal open={modal} onClose={() => toggleModal(false)}>
                <Paper px={2.5} py={5} overlay w={47}>
                    <Box df jc="center" mb={2}>
                        <img src={trash} width="64" height="64" alt="Delete" />
                    </Box>
                    <Box mb={2.5}>
                        <Text bold size="xl" align="center">
                            {locale.templates.controls.deleteConfirm}
                        </Text>
                    </Box>
                    <Box mb={2.5}>
                        <LoadingButton
                            wide
                            warning
                            type="button"
                            onClick={() => {
                                toggleModal();
                                onDelete();
                            }}
                            loading={deleteLoading}
                        >
                            {locale.templates.controls.deleteOk}
                        </LoadingButton>
                    </Box>
                    <Button wide secondary type="button" onClick={() => toggleModal()}>
                        {locale.templates.controls.deleteCancel}
                    </Button>
                </Paper>
            </Modal>
        </>
    );
};

export default TemplateControls;
