import React from 'react';
import Button, {IProps} from '@ff/web-components/components/Button';
import GradientSpinner from '@/components/GradientSpinner';

interface LoadingButtonProps extends IProps {
    loading?: boolean;
}

const LoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
    ({children, loading, disabled, ...rest}: LoadingButtonProps, ref) => (
        <Button ref={ref} disabled={loading || disabled} {...rest}>
            {loading ? <GradientSpinner /> : children}
        </Button>
    ),
);

LoadingButton.displayName = 'LoadingButton';

export default LoadingButton;
