import React, {useCallback, useMemo, useRef} from 'react';
import Input, {IProps as InputProps} from '../index';
import formatter from 'utils/formatter';
import {SyntheticEvent} from 'utils/syntheticEvent';

export const DEFAULT_FORMAT = '+{{ddd}} {{dd}} {{ddd}} {{dd}} {{dd}}';

export interface IProps extends InputProps {
    countryCode?: string;
    format?: string;
}

const filterInput = value => value.replace(/[^+\d]/gi, '');

const Phone = React.forwardRef<HTMLInputElement, IProps>(
    ({onChange, onPaste, value, countryCode, format = DEFAULT_FORMAT, ...rest}: IProps, ref) => {
        const inputInner = useRef<HTMLInputElement | null>(null);
        const inputReference = ref || inputInner;
        const formattedValue = useMemo(
            () => (value ? formatter(value as string, format) : value),
            [value],
        );
        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent) => {
                let carriagePosition = 0;
                let formattedNextValue = e.currentTarget.value;

                if (e.currentTarget.value) {
                    if (countryCode && !e.currentTarget.value.startsWith(countryCode)) {
                        return;
                    }
                    formattedNextValue = formatter(filterInput(e.currentTarget.value), format);
                    // part of value from start to carriage position
                    const formattedSelection = formatter(
                        e.currentTarget.value
                            .substring(0, e.target.selectionStart)
                            .replace(/[^+\d\s]/gi, ''),
                        format,
                    );

                    carriagePosition =
                        e.target.selectionStart === e.currentTarget.value.length
                            ? // add, remove from end of value
                              formattedNextValue.length
                            : // add, remove from middle of value
                              formattedSelection.length;

                    e.currentTarget.value = formattedNextValue;
                } else if (countryCode) {
                    e.currentTarget.value = countryCode;
                    carriagePosition = countryCode.length;
                }

                if (typeof onChange === 'function') {
                    onChange(e);
                    setTimeout(() => {
                        if (typeof inputReference === 'object' && inputReference.current) {
                            inputReference.current.setSelectionRange(
                                carriagePosition,
                                carriagePosition,
                            );
                        }
                    }, 0);
                }
            },
            [onChange, value, format, countryCode],
        );
        const handlePaste = useCallback(
            (e: React.ClipboardEvent<HTMLInputElement>) => {
                if (countryCode) {
                    const buffer = e.clipboardData.getData('text/plain');
                    const clean = filterInput(buffer);
                    e.currentTarget.value = clean.startsWith(countryCode)
                        ? clean
                        : clean.startsWith(countryCode.substring(1))
                        ? `+${clean}`
                        : `${countryCode}${clean}`;
                }

                if (typeof onPaste === 'function') {
                    onPaste(e);
                }
            },
            [onPaste, countryCode],
        );

        return (
            <Input
                {...rest}
                onChange={handleChange}
                value={formattedValue || countryCode || ''}
                ref={inputReference}
                onPaste={handlePaste}
                inputMode="tel"
            />
        );
    },
);

Phone.displayName = 'Phone';

export default Phone;
