import React from 'react';
import FE from '@ff/web-components/components/Error/Field';

interface IProps {
    children: React.ReactNode;
}

const FieldError = ({children}: IProps) => {
    return children ? <FE>{children}</FE> : null;
};

export default FieldError;
