import React, {useCallback, useEffect, useRef} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToAccount';
import {getLocale} from '@ff/web-components/utils/l10n';
import useToggle from '@/hooks/useToggle';
import useForm from '@/hooks/useForm';
import useValidation from '@/hooks/useValidation';
import required from '@/hooks/useValidation/required';
import {useMutation, useQuery} from '@tanstack/react-query';
import parse from '@ff/web-components/utils/number/parse';
import getBalance, {QUERY_KEY} from '@/features/Accounts/api/getBalance';
import {Dictionary} from '@/locale';
import format from '@/hooks/useValidation/format';
import Layout from '@/features/TransferToAccount/layout';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import FieldError from '@/components/FieldError';
import SumInput from '@ff/web-components/components/Input/Sum';
import GradientSpinner from '@/components/GradientSpinner';
import stringify from '@ff/web-components/utils/number/stringify';
import LoadingButton from '@/components/LoadingButton';
import TextArea from '@ff/web-components/components/TextArea';
import getCommission from '../api/getCommission';
import Input from '@ff/web-components/components/Input';
import DropDown from '@ff/web-components/components/DropDown';
import DropDownSearch from '@ff/web-components/components/DropDownSearch';
import NumberInput from '@ff/web-components/components/Input/Number';
import sumMinmax from '@/hooks/useValidation/sumMinmax';
import FormError from '@/components/FormError';
import Controls from '@/features/Templates/controls';
import {useNavigate, useSearchParams} from 'react-router-dom';
import saveIBANTemplate, {
    Params as SaveTemplateParams,
    Response as SaveTemplateResponse,
} from '@/features/Templates/api/saveAccountTemplate';
import {ResponseError} from '@/api/protocol';
import deleteTemplate, {
    Response as DeleteTemplateResponse,
} from '@/features/Templates/api/deleteTemplate';
import dashboardLink from '@/features/Dashboard/makeLink';
import getBudgetClassificators, {
    QUERY_KEY as BUDGET_CLASS_QUERY_KEY,
    Response as ClassificatorsResponse,
} from '@/features/TransferToAccount/api/getBudgetClassificators';
import useLang from '@/hooks/useLang';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: Partial<State>) => void;
    onBack: () => void;
}

const dropDownStyle = {
    list: {
        maxHeight: '248px',
    },
};

const Form = ({data, onSubmit, onBack}: IProps) => {
    const lang = useLang();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const commissionInQueue = useRef(false);
    const leo = data.receiver.nativeClientData !== undefined;
    const budgetPayment = !leo && data.receiver.notNativeClientData.budgetLevel;
    const fetchCommissionTimeout = useRef<ReturnType<typeof setTimeout>>();
    const locale: Dictionary = getLocale();
    const formLocale: Dictionary['account']['form'] = locale.account.form;
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange] = useForm(data);
    const sumNumber = typeof state.sum === 'number' ? state.sum : parse(state.sum);
    const [valid, errors] = useValidation<State>(
        state,
        required(
            leo
                ? ['dest', 'sum']
                : [
                      'receiverVoin',
                      'receiverName',
                      'dest',
                      'receiverBankCode',
                      'sum',
                      ...((budgetPayment
                          ? ['budgetLevelCode', 'budgetClassificatorCode']
                          : []) as (keyof State)[]),
                  ],
        ),
        format(['dest'], /^.{1,250}$/i, {message: locale.account.errors.dest}),
        ({dest}) => {
            if (dest) {
                if (dest.replace(/\s/g, '').length < 5) {
                    return {dest: {message: locale.account.errors.dest}};
                } else if (!/[a-z]/i.test(dest)) {
                    return {dest: {message: locale.account.errors.dest}};
                }
            }
        },
        sumMinmax(['sum'], data.receiver.limits.minAmount, data.receiver.limits.maxAmount),
        format(['receiverVoin'], leo ? /.*/ : /^\d{9}[1-2]$/, {
            message: locale.account.errors.voen,
        }),
        format(['receiverName'], leo ? /.*/ : /^[a-z\s\d]{5,200}$/i, {
            message: locale.account.errors.receiverName,
        }),
        !leo
            ? ({receiverName}) => {
                  if (receiverName) {
                      if (receiverName.replace(/\s/g, '').length < 5) {
                          return {receiverName: {message: locale.account.errors.receiverName}};
                      } else if (!/[a-z]/i.test(receiverName)) {
                          return {receiverName: {message: locale.account.errors.receiverName}};
                      }
                  }
              }
            : undefined,
    );
    const saveTemplateReq = useMutation<SaveTemplateResponse, ResponseError, SaveTemplateParams>({
        mutationFn: params => saveIBANTemplate(params),
    });
    const deleteTemplateReq = useMutation<DeleteTemplateResponse, ResponseError, string>({
        mutationFn: id => deleteTemplate(id),
    });
    const getBalanceReq = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
    });
    const getCommissionReq = useQuery({
        queryKey: ['/business/payment/fee'],
        queryFn: () =>
            getCommission({
                senderCardId: String(getBalanceReq.data?.data.id),
                iban: data.iban,
                amount: sumNumber,
            }),

        enabled: false,
    });
    const getBudgetClassificatorsReq = useQuery<ClassificatorsResponse, ResponseError>({
        queryKey: [BUDGET_CLASS_QUERY_KEY, lang],
        queryFn: () => getBudgetClassificators(),
        staleTime: 300000,
    });
    const handleSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!valid) {
                toggleValidation(true);
            } else {
                if (!commissionInQueue.current) {
                    onSubmit({
                        dest: state.dest,
                        sum: typeof state.sum === 'number' ? state.sum : parse(state.sum),
                        senderCardId: String(getBalanceReq.data?.data.id),
                        commission: getCommissionReq.data?.response?.data?.fee,
                        receiverVoin: leo
                            ? data.receiver.nativeClientData.voin
                            : state.receiverVoin,
                        receiverName: state.receiverName,
                        receiverBankCode: state.receiverBankCode,
                        budgetLevelCode: budgetPayment ? state.budgetLevelCode : null,
                        budgetClassificatorCode: budgetPayment
                            ? state.budgetClassificatorCode
                            : null,
                        confirmData: budgetPayment
                            ? {
                                  budgetLevel: data.receiver.notNativeClientData.budgetLevel.find(
                                      ({code}) => code === state.budgetLevelCode,
                                  ),
                                  budgetClassificator:
                                      getBudgetClassificatorsReq.data?.response.data.budgetClassificator.find(
                                          ({code}) => code === state.budgetClassificatorCode,
                                      ),
                              }
                            : undefined,
                    });
                }
            }
        },
        [state, valid, getCommissionReq.data, leo, budgetPayment],
    );
    const currency =
        locale.app.currency[data.currency.toUpperCase() as keyof typeof locale.app.currency];
    const handleSaveRequest = useCallback(() => {
        if (!valid) {
            toggleValidation(true);
        } else {
            deleteTemplateReq.reset();
            saveTemplateReq.mutate({
                paymentDescription: state.dest,
                amount: typeof state.sum === 'number' ? state.sum : parse(state.sum),
                senderCardId: String(getBalanceReq.data?.data.id),
                receiverVoin: leo ? data.receiver.nativeClientData.voin : state.receiverVoin,
                receiverName: state.receiverName,
                receiverBankCode: state.receiverBankCode,
                iban: state.iban,
                id: state.template.id,
                cardId: state.template.templateDetails.cardId || getBalanceReq.data?.data.id,
                budgetLevelCode: budgetPayment ? state.budgetLevelCode : undefined,
                budgetClassificatorCode: budgetPayment ? state.budgetClassificatorCode : undefined,
            });
        }
    }, [state, valid, getBalanceReq.data, leo, budgetPayment]);
    const handleDeleteTemplate = useCallback(() => {
        saveTemplateReq.reset();
        deleteTemplateReq.mutate(state.template.id);
    }, [state.template?.id]);

    useEffect(() => {
        if (sumNumber > 0 && getBalanceReq.data?.data.id) {
            clearTimeout(fetchCommissionTimeout.current);
            commissionInQueue.current = true;
            fetchCommissionTimeout.current = setTimeout(() => {
                commissionInQueue.current = false;
                getCommissionReq.refetch();
            }, 300);
        }
    }, [state.sum, getBalanceReq.data]);

    useEffect(() => {
        if (saveTemplateReq.isSuccess || deleteTemplateReq.isSuccess) {
            navigate(dashboardLink({tab: 'templates'}));
        }
    }, [saveTemplateReq.isSuccess, deleteTemplateReq.isSuccess]);

    useEffect(() => {
        if (saveTemplateReq.isError) {
            saveTemplateReq.reset();
        }
        if (deleteTemplateReq.isError) {
            deleteTemplateReq.reset();
        }
    }, [state]);

    return (
        <Layout
            onBack={onBack}
            title={data.receiver.title}
            subTitle={data.receiver.subtitle}
            img={{
                imageUrl: data.receiver.imageUrl,
                initials: data.receiver.initials,
                initialsBgColor: data.receiver.initialsBgColor,
            }}
        >
            {data.confirmError ? <FormError error={data.confirmError} /> : null}
            {saveTemplateReq.isError ? <FormError error={saveTemplateReq.error} /> : null}
            {deleteTemplateReq.isError ? <FormError error={deleteTemplateReq.error} /> : null}
            <form action="/" method="post" onSubmit={handleSubmit}>
                <Box mb={2.5}>
                    <Box df fd="column" gap={2.5}>
                        {leo ? (
                            <>
                                {data.receiver.nativeClientData.voin ? (
                                    <div>
                                        <Text color="secondary" size="xs">
                                            {formLocale.voen}
                                        </Text>
                                        <div>{data.receiver.nativeClientData.voin}</div>
                                    </div>
                                ) : null}
                                {data.receiver.nativeClientData.receiverBankName ? (
                                    <div>
                                        <Text color="secondary" size="xs">
                                            {formLocale.bank}
                                        </Text>
                                        <div>{data.receiver.nativeClientData.receiverBankName}</div>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <div>
                                    <Input
                                        name="receiverName"
                                        value={state.receiverName}
                                        onChange={onChange}
                                        label={formLocale.name}
                                        error={!!(validation && errors.receiverName?.message)}
                                        maxLength={200}
                                    />
                                    <FieldError>
                                        {validation && errors.receiverName?.message}
                                    </FieldError>
                                </div>
                                <div>
                                    <NumberInput
                                        name="receiverVoin"
                                        value={state.receiverVoin}
                                        onChange={onChange}
                                        label={formLocale.voen}
                                        error={!!(validation && errors.receiverVoin?.message)}
                                        maxLength={10}
                                    />
                                    <FieldError>
                                        {validation && errors.receiverVoin?.message}
                                    </FieldError>
                                </div>
                                <div>
                                    <DropDown
                                        name="receiverBankCode"
                                        value={state.receiverBankCode}
                                        onChange={onChange}
                                        label={formLocale.code}
                                        error={!!(validation && errors.receiverBankCode?.message)}
                                        options={data.receiver.notNativeClientData.bankList?.map(
                                            item => ({
                                                value: item.bankCode,
                                                title: `${item.bankCode} — ${item.bankName}`,
                                                content: (
                                                    <>
                                                        <Text size="m">{item.bankCode}</Text>
                                                        <Text size="xs" color="secondary">
                                                            {item.bankName}
                                                        </Text>
                                                    </>
                                                ),
                                            }),
                                        )}
                                        style={dropDownStyle}
                                    />
                                    <FieldError>
                                        {validation && errors.receiverBankCode?.message}
                                    </FieldError>
                                </div>
                            </>
                        )}
                    </Box>
                </Box>
                <Box df fd="column" gap={2.5} mb={3}>
                    <div>
                        <TextArea
                            name="dest"
                            value={state.dest}
                            onChange={onChange}
                            label={formLocale.dest}
                            error={!!(validation && errors.dest?.message)}
                            rowsMax={3}
                            maxLength={250}
                        />
                        <FieldError>{validation && errors.dest?.message}</FieldError>
                    </div>
                    {!leo && budgetPayment ? (
                        <>
                            <div>
                                <DropDown
                                    name="budgetLevelCode"
                                    value={state.budgetLevelCode}
                                    onChange={onChange}
                                    label={formLocale.budgetLevelCode}
                                    error={!!(validation && errors.budgetLevelCode?.message)}
                                    options={data.receiver.notNativeClientData.budgetLevel.map(
                                        item => ({
                                            value: item.code,
                                            title: `${item.code} — ${item.description}`,
                                            content: (
                                                <>
                                                    <Text size="m">{item.code}</Text>
                                                    <Text size="xs" color="secondary">
                                                        {item.description}
                                                    </Text>
                                                </>
                                            ),
                                        }),
                                    )}
                                    style={dropDownStyle}
                                />
                                <FieldError>
                                    {validation && errors.budgetLevelCode?.message}
                                </FieldError>
                            </div>
                            <div>
                                <DropDownSearch
                                    name="budgetClassificatorCode"
                                    value={state.budgetClassificatorCode}
                                    onChange={onChange}
                                    label={formLocale.budgetClassificatorCode}
                                    error={
                                        !!(validation && errors.budgetClassificatorCode?.message)
                                    }
                                    options={getBudgetClassificatorsReq.data?.response.data.budgetClassificator.map(
                                        item => ({
                                            value: item.code,
                                            title: `${item.code} — ${item.description}`,
                                            content: (
                                                <>
                                                    <Text size="m">{item.code}</Text>
                                                    <Text size="xs" color="secondary">
                                                        {item.description}
                                                    </Text>
                                                </>
                                            ),
                                        }),
                                    )}
                                    disabled={
                                        getBudgetClassificatorsReq.isLoading ||
                                        getBudgetClassificatorsReq.isPending ||
                                        !getBudgetClassificatorsReq.data?.response.data
                                            .budgetClassificator?.length
                                    }
                                    style={dropDownStyle}
                                />
                                <FieldError>
                                    {validation && errors.budgetClassificatorCode?.message}
                                </FieldError>
                                <FieldError>
                                    {getBudgetClassificatorsReq.isError ? (
                                        <div>
                                            {formLocale.loadDictError}{' '}
                                            <Text
                                                td="underline"
                                                cursor="pointer"
                                                onClick={() => getBudgetClassificatorsReq.refetch()}
                                            >
                                                {formLocale.reload}
                                            </Text>
                                        </div>
                                    ) : null}
                                </FieldError>
                            </div>
                        </>
                    ) : null}
                    <div>
                        <Box mb={1}>
                            <SumInput
                                name="sum"
                                value={state.sum}
                                onChange={onChange}
                                label={formLocale.sum}
                                maxLength={9}
                                error={!!(validation && errors.sum?.message)}
                            />
                            <FieldError>{validation && errors.sum?.message}</FieldError>
                        </Box>
                        <div>
                            {getBalanceReq.isLoading ? (
                                <GradientSpinner size={2} />
                            ) : getBalanceReq.isSuccess ? (
                                <Text size="xs3" color="secondary" align="left">
                                    {formLocale.balance}{' '}
                                    {stringify(getBalanceReq.data?.data.balances.amount)}{' '}
                                    {getBalanceReq.data?.data.displayedInfo.currencySymbol}
                                </Text>
                            ) : null}
                            {sumNumber ? (
                                <Text size="xs3" color="secondary" align="left">
                                    <Box df ai="center">
                                        {!getCommissionReq.isFetched ||
                                        getCommissionReq.isLoading ? (
                                            <GradientSpinner size={2} />
                                        ) : getCommissionReq.isSuccess ? (
                                            getCommissionReq.data?.response?.data?.fee ? (
                                                <span>
                                                    {formLocale.commission}{' '}
                                                    {getCommissionReq.data.response.data.fee}{' '}
                                                    {currency}
                                                </span>
                                            ) : (
                                                formLocale.noCommission
                                            )
                                        ) : (
                                            '-'
                                        )}
                                    </Box>
                                </Text>
                            ) : null}
                        </div>
                    </div>
                </Box>
                <LoadingButton
                    primary
                    wide
                    type="submit"
                    disabled={
                        getBalanceReq.isFetching ||
                        getBalanceReq.isLoading ||
                        getCommissionReq.isFetching ||
                        getCommissionReq.isLoading
                    }
                >
                    {formLocale.submit}
                </LoadingButton>
                {searchParams.has('edit') && data.template ? (
                    <Box mt={2.5}>
                        <Controls
                            onSave={handleSaveRequest}
                            onDelete={handleDeleteTemplate}
                            saveLoading={saveTemplateReq.isPending}
                            deleteLoading={deleteTemplateReq.isPending}
                            saveSuccess={saveTemplateReq.isSuccess}
                            deleteSuccess={deleteTemplateReq.isSuccess}
                        />
                    </Box>
                ) : null}
            </form>
        </Layout>
    );
};

export default Form;
