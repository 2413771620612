import styled from 'styled-components';

interface IProps {
    $bg: string;
}

const UserPreview = styled.div<IProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    ${({theme, $bg}) => ({
        width: theme.c.gutter * 8 + 'px',
        height: theme.c.gutter * 8 + 'px',
        background: $bg,
        border: `0.5px solid ${theme.c.palette.grey['500']}`,
    })}
`;

export default UserPreview;
