'use client';
'use strict';
import React from 'react';
import Box from '../Box';
import Text, {IProps as TextProps} from '../Text';
import theme from '../theme';
import defaultTheme from './theme';

export interface IProps extends TextProps {
    children?: React.ReactNode;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const FieldError = React.forwardRef<HTMLDivElement, IProps>(
    ({children, theme, ...rest}: IProps, ref) => (
        <Text size="xs3" color="error" align="left" ref={ref} {...rest}>
            <Box pt={0.25} px={2}>
                <Box minH={theme?.theme?.text?.lineHeight.xs3}>{children}</Box>
            </Box>
        </Text>
    ),
);

FieldError.displayName = 'FieldError';

export default theme<IProps, HTMLDivElement>(FieldError, 'error', 'FieldError');
