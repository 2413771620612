import React from 'react';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import IconButton from '@ff/web-components/components/IconButton';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import icon from './img/service.svg';
import useTheme from '@/hooks/useTheme';
import Text from '@ff/web-components/components/Text';

export interface IProps {
    onBack: () => void;
    title: string;
    subTitle?: string;
    children?: React.ReactNode;
}

const Layout: React.FC<IProps> = ({onBack, title, children, subTitle}) => {
    const {c} = useTheme();
    return (
        <Box mt={8}>
            <Paper px={2.5} pt={2.5} pb={5}>
                <Box mb={1}>
                    <IconButton onClick={onBack} secondary>
                        <PointerLeft />
                    </IconButton>
                </Box>
                <Box df jc="center" mb={2} ai="center">
                    <img
                        src={icon}
                        width={c.gutter * 8 + 'px'}
                        height={c.gutter * 8 + 'px'}
                        alt="Tax"
                    />
                </Box>
                <Box mb={3}>
                    <Text align="center" size="xl" bold>
                        {title}
                    </Text>
                    {subTitle ? (
                        <Box mt={1}>
                            <Text color="secondary" size="xs" align="center">
                                {subTitle}
                            </Text>
                        </Box>
                    ) : null}
                </Box>
                {children}
            </Paper>
        </Box>
    );
};

export default Layout;
