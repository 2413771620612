'use client';
'use strict';
import React, {useMemo} from 'react';
import defaultTheme from './theme';
import Root from './style/root';
import theme from '../theme';
import merge from 'themes/utils/merge';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    children: React.ReactNode;
    warning?: boolean;
}

const BadgeButton = React.forwardRef<HTMLButtonElement, IProps>(
    ({theme, style, children, warning, disabled, ...rest}: IProps, ref) => {
        const computedTheme = useMemo(() => {
            return merge(warning ? theme?.warning : theme?.primary, style);
        }, [style, theme, warning]);

        return (
            <Root $theme={computedTheme} ref={ref} disabled={disabled} {...rest}>
                {children}
            </Root>
        );
    },
);

BadgeButton.displayName = 'BadgeButton';

export default theme<IProps, HTMLButtonElement>(BadgeButton, 'badgeButton', 'BadgeButton');
