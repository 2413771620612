'use client';
'use strict';
import React, {useMemo, useRef, useState} from 'react';
import Overlay, {IProps as OverlayProps} from '../Overlay';
import ClickAwayListener from '../ClickAwayListener';
import Root from './style/root';
import Paper from '../Paper';
import theme from '../theme';
import defaultTheme from './theme';

export interface IProps {
    control: React.ReactNode | ((open: boolean) => React.ReactNode);
    children: React.ReactNode;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    onSelect?: (el: React.ReactNode) => void;
    position?: OverlayProps['position'];
}

const Options = ({control, children, theme, onSelect, position}: IProps) => {
    const [open, setOpen] = useState(false);
    const root = useRef(null);
    const handleSelect = el => {
        if (typeof onSelect === 'function') {
            onSelect(el);
        }
        setOpen(false);
    };
    const paperStyle = useMemo(() => ({background: theme?.theme?.palette?.background.b2}), [theme]);

    return (
        <Root ref={root}>
            <div onClick={() => setOpen(!open)}>
                {typeof control === 'function' ? control(open) : control}
            </div>
            <Overlay active={open} offsetTop={theme?.theme?.gutter} position={position}>
                <Paper overlay py={1} style={paperStyle}>
                    {Array.isArray(children)
                        ? children.map((el, i) => (
                              <div key={i} onClick={() => handleSelect(el)}>
                                  {el}
                              </div>
                          ))
                        : children}
                </Paper>
            </Overlay>
            <ClickAwayListener node={root} onClickAway={open ? () => setOpen(false) : undefined} />
        </Root>
    );
};

export default theme<IProps, HTMLDivElement>(Options, 'options', 'Options');
