import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Option = styled.div<RootProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${({$theme}) => ({
        background: 'transparent',
        transitionProperty: 'background',
        transitionDuration: $theme.theme.transition?.duration,
        transitionTimingFunction: $theme.theme.transition?.timingFunction,
        padding: `10px ${($theme.theme.gutter ?? 0) * 2.5}px`,
        '&:hover': {
            background: $theme.theme.palette.grey['200'],
        },
    })}
`;

export default Option;
