import React, {useState} from 'react';
import GeneralError from '@/components/GeneralError';
import Box from '@ff/web-components/components/Box';

const TestPage = () => {
    const [value, setValue] = useState('AZ');

    return (
        <Box mt={5}>
            <GeneralError title="test1" subTitle="test2" onRetry={() => console.log('t')} />
        </Box>
    );
};

export default TestPage;
