import styled from 'styled-components';

const Option = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({theme}) => ({
        padding: `${theme.c.gutter * 1.5}px ${theme.c.gutter * 2.5}px`,
    })}
`;

export default Option;
