import styled from 'styled-components';
import {IDropDownSearchTheme} from '../theme';

interface SelectWrapperProps {
    $theme: IDropDownSearchTheme;
}

const SelectWrapper = styled.div<SelectWrapperProps>`
    display: flex;
    align-items: center;
    ${({$theme}) => ({
        padding: `${$theme.theme.gutter}px ${$theme.theme.gutter * 1.5}px ${
            $theme.theme.gutter
        }px ${$theme.theme.gutter * 2}px`,
    })};
`;

export default SelectWrapper;
