import React, {useCallback, useMemo, useRef} from 'react';
import Input, {IProps as InputProps} from '../index';
import formatter from 'utils/formatter';
import syntheticEvent, {SyntheticEvent} from 'utils/syntheticEvent';

export const IBAN_FORMAT =
    '{{ww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}}';

export interface IProps extends InputProps {
    format?: string;
    country?: string;
}

const filterInput = value => value.replace(/[^a-z0-9]/gi, '');

const IBANInput = React.forwardRef<HTMLInputElement, IProps>(
    (
        {
            onChange,
            value,
            name,
            format = IBAN_FORMAT,
            maxLength = 42,
            country,
            onPaste,
            ...rest
        }: IProps,
        ref,
    ) => {
        const inputInner = useRef<HTMLInputElement | null>(null);
        const inputReference = ref || inputInner;
        const formattedValue = useMemo(
            () => (value ? formatter(value as string, format).toUpperCase() : value),
            [value],
        );
        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent) => {
                let carriagePosition = 0;
                let formattedNextValue = e.currentTarget.value;

                if (e.currentTarget.value) {
                    formattedNextValue = formatter(filterInput(e.currentTarget.value), format);
                    // part of value from start to carriage position
                    const formattedSelection = formatter(
                        e.currentTarget.value
                            .substring(0, e.target.selectionStart)
                            .replace(/[^a-z0-9\s]/gi, ''),
                        format,
                    );

                    carriagePosition =
                        e.target.selectionStart === e.currentTarget.value.length
                            ? // add, remove from end of value
                              formattedNextValue.length
                            : // add, remove from middle of value
                              formattedSelection.length;

                    e.currentTarget.value = formattedNextValue;
                }

                if (typeof onChange === 'function') {
                    onChange(e);
                    setTimeout(() => {
                        if (typeof inputReference === 'object' && inputReference.current) {
                            inputReference.current.setSelectionRange(
                                carriagePosition,
                                carriagePosition,
                            );
                        }
                    }, 0);
                }
            },
            [onChange, value],
        );
        const handlePaste = useCallback(
            (e: React.ClipboardEvent<HTMLInputElement>) => {
                const buffer = e.clipboardData.getData('text/plain');
                const newValue = formatter(
                    country && value === country && buffer.startsWith(country)
                        ? buffer
                        : e.currentTarget.value,
                    IBAN_FORMAT,
                ).toUpperCase();

                if (typeof onChange === 'function') {
                    onChange(
                        syntheticEvent({
                            name,
                            value: newValue,
                        }),
                    );
                }
                if (typeof onPaste === 'function') {
                    onPaste(e);
                }
            },
            [onPaste, value, country, name],
        );

        return (
            <Input
                {...rest}
                onChange={handleChange}
                value={formattedValue || ''}
                ref={inputReference}
                maxLength={maxLength}
                name={name}
                onPaste={handlePaste}
            />
        );
    },
);

IBANInput.displayName = 'IBANInput';

export default IBANInput;
