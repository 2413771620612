import styled from 'styled-components';

interface RootProps {
    $theme;
    $rotate;
    $scale;
}

const Root = styled.div<RootProps>`
    display: inline-flex;
    transition-property: transform;
    transform-origin: center center;
    ${({$theme, $rotate, $scale}) => ({
        transform:
            $rotate !== undefined || $scale !== undefined
                ? ($rotate ? `rotate(${$rotate}) ` : '') + ($scale ? `scale(${$scale})` : '')
                : undefined,
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
    })}
`;

export default Root;
