import React, {useCallback, useEffect} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToTaxService';
import Box from '@ff/web-components/components/Box';
import FieldError from '@/components/FieldError';
import LoadingButton from '@/components/LoadingButton';
import Layout from '../layout';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';
import {ResponseError} from '@/api/protocol';
import useForm from '@/hooks/useForm';
import useValidation from '@/hooks/useValidation';
import required from '@/hooks/useValidation/required';
import useToggle from '@/hooks/useToggle';
import {useQuery} from '@tanstack/react-query';
import Input from '@ff/web-components/components/Input/Number';
import getInfo, {Response as InfoResponse} from '@/features/TransferToTaxService/api/getInfo';
import link from '@/features/TransferToTaxService/makeLink';
import {useNavigate, useSearchParams} from 'react-router-dom';
import format from '@/hooks/useValidation/format';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: Partial<State>) => void;
}

const Receiver = ({data, onSubmit, toPrevStep}: IProps) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const locale: Dictionary = getLocale();
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange, setState] = useForm(data);
    const [valid, errors] = useValidation<{voen: State['voen']}>(
        state,
        required(['voen']),
        format(['voen'], /^\d{10}$/),
    );
    const infoReq = useQuery<InfoResponse, ResponseError>({
        queryKey: ['/tax/payer-info'],
        queryFn: getInfo,
    });
    const handleSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!valid) {
                toggleValidation(true);
            } else {
                onSubmit({
                    voen: state.voen,
                });
            }
        },
        [state, valid],
    );
    const handleBack = useCallback(() => {
        if (searchParams.get('t')) {
            navigate(link());
        }
        toPrevStep();
    }, [searchParams]);

    useEffect(() => {
        if (infoReq.isSuccess && infoReq.data?.voen && !state.voen) {
            setState({voen: infoReq.data.voen});
        }
    }, [infoReq.isSuccess]);

    return (
        <Layout
            onBack={handleBack}
            title={locale.tax.receiver.title}
            subTitle={locale.tax.receiver.hint}
        >
            <form action="/" method="post" onSubmit={handleSubmit}>
                <Box mb={3}>
                    <Input
                        name="voen"
                        value={state.voen}
                        onChange={onChange}
                        label={locale.tax.receiver.voen}
                        error={!!(validation && errors.voen?.message)}
                        maxLength={10}
                    />
                    <FieldError>{validation && errors.voen?.message}</FieldError>
                </Box>
                <LoadingButton primary wide type="submit">
                    {locale.tax.receiver.submit}
                </LoadingButton>
            </form>
        </Layout>
    );
};

export default Receiver;
