export default async (text: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        try {
            await navigator.clipboard.writeText(text); // resolves promise with undefined if copied, rejects promise if not
            return true;
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
};
