'use client';
'use strict';
import React, {useId} from 'react';
import theme from '../theme';
import defaultTheme from './theme';
import useMergeTheme from '../../hooks/useMergeTheme';
import Root from './style/root';
import Input from './style/input';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'style'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    disabled?: boolean;
    checked?: boolean;
    children?: React.ReactNode;
    className?: string;
    id?: string;
}

const Radio = React.forwardRef<HTMLInputElement, IProps>(
    ({style, theme, disabled, checked, children, className, id, ...rest}: IProps, ref) => {
        const computedTheme = useMergeTheme(theme, style);
        const innerId = useId();

        return (
            <Root
                $theme={computedTheme}
                $checked={checked}
                $disabled={disabled}
                className={className}
                htmlFor={id || innerId}
            >
                {children}
                <Input {...rest} type="radio" id={id || innerId} ref={ref} disabled={disabled} />
            </Root>
        );
    },
);

Radio.displayName = 'Radio';

export default theme<IProps, HTMLInputElement>(Radio, 'radio', 'Radio');
