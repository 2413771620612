import projectTheme, {Theme as ProjectTheme} from '@/style/theme';
import componentsTheme from '@ff/web-components/themes/light';
import ComponentsTheme from '@ff/web-components/themes/light.d';

export type Theme = {
    p: ProjectTheme;
    c: Partial<typeof ComponentsTheme>;
    name: string;
};

const useTheme = () =>
    ({
        c: componentsTheme,
        p: projectTheme,
        name: 'light',
    } as Theme);

export default useTheme;
