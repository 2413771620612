import React from 'react';
import {Response} from '@/features/Templates/api/getList';
import Paper from '@ff/web-components/components/Paper';
import Item from './item';

interface IProps {
    collection: Response['templates'];
}

const List = ({collection}: IProps) => {
    return (
        <Paper py={1} mt={2.5}>
            {collection.map(item => (
                <Item {...item} key={item.id} />
            ))}
        </Paper>
    );
};

export default List;
