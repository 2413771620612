import React from 'react';
import Root from '@/features/Application/style/header';
import logo from '@/img/logo.svg';
import Box from '@ff/web-components/components/Box';
import Divider from '@ff/web-components/components/Divider';
import Account from './account';
import {Link} from 'react-router-dom';
import dashboard from '@/features/Dashboard/makeLink';
import Support from '@/features/Application/layout/private/header/support';

const PrivateHeader = () => {
    return (
        <Root>
            <Box py={3} df jc="space-between" ai="center">
                <div>
                    <Link to={dashboard()}>
                        <img src={logo} height="24px" alt="Leobank" />
                    </Link>
                </div>
                <Box df ai="center">
                    <Box mr={2.5}>
                        <Support />
                    </Box>
                    <Account />
                </Box>
            </Box>
            <Divider />
        </Root>
    );
};

export default PrivateHeader;
