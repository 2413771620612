import React from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Skeleton from '@ff/web-components/components/Skeleton';

const Item = () => (
    <Box df fd="column" gap={0.5}>
        <Text color="secondary" size="xs2">
            <Skeleton w={10}>&nbsp;</Skeleton>
        </Text>
        <Text size="xs">
            <Skeleton w={25}>&nbsp;</Skeleton>
        </Text>
    </Box>
);

const RequisitesSkeleton = () => {
    return (
        <>
            <Box df jc="center" ai="center" mb={2}>
                <Skeleton w={8} h={8} rad="50%" />
            </Box>
            <Box mb={1} df jc="center" ai="center">
                <Text size="xl" bold>
                    <Skeleton w={30}>&nbsp;</Skeleton>
                </Text>
            </Box>
            <Box mb={3} df jc="center" ai="center">
                <Text size="xs" color="secondary">
                    <Skeleton w={20}>&nbsp;</Skeleton>
                </Text>
            </Box>
            <Box mb={3} gap={2} df fd="column">
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
            </Box>
        </>
    );
};

export default RequisitesSkeleton;
