import axios from 'axios';
import url from '@/api/url';

export type Response = {
    response: {
        fee: {
            sender: string;
        };
    };
};

export type Params = {
    amount: number;
};

export default async function getCommission(params: Params): Promise<Response> {
    return axios.post(url('cards/main/topup/fee'), params);
}
