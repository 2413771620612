'use client';
'use strict';
import React, {useRef, useState} from 'react';
import theme from '../theme';
import rootTheme from './theme';
import Context from './context';
import Snack, {IProps as SnackProps} from './snack';
import ReactDOM from 'react-dom';
import Root from './style/root';
import {useTransition} from '@react-spring/web';

export interface IProps {
    maxLength?: number;
    offsetParent?: HTMLElement;
    theme?: Partial<ReturnType<typeof rootTheme>>;
    children?: React.ReactNode;
}

type State = ({id: string} & SnackProps)[];

const SnackBarProvider = ({
    offsetParent = document.body,
    maxLength = 2,
    children,
    theme,
}: IProps) => {
    const root = useRef(document.createElement('div'));
    const [items, setItems] = useState<State>([]);
    const [visible, setVisible] = useState(false);
    const remove = id => {
        setItems(prev => {
            if (prev.length && id) {
                const itemToRemove = prev.find(item => id === item.id);
                if (itemToRemove) {
                    if (itemToRemove && typeof itemToRemove.onRemove === 'function') {
                        itemToRemove.onRemove();
                    }

                    return prev.filter(item => id !== item.id);
                } else {
                    return prev;
                }
            } else {
                return prev;
            }
        });
    };
    const add = (id, props) => {
        setItems(prev => {
            const newItems = [...prev.filter(item => item.id !== id)];
            newItems.unshift({id, ...props});

            if (maxLength && newItems.length > maxLength) {
                newItems.slice(maxLength).forEach(({id}) => remove(id));
                newItems.length = maxLength;
            }

            if (!offsetParent?.contains(root.current)) {
                offsetParent?.appendChild(root.current);
            }

            return newItems;
        });

        if (!visible) {
            setVisible(true);
        }
    };
    const contextValue = {
        add,
        remove,
    };
    const transitions = useTransition(items, {
        keys: item => item.id,
        from: {opacity: 0.5, marginTop: '-40px'},
        enter: {opacity: 1, marginTop: '0px'},
        leave: {opacity: 0},
        onDestroyed: () => {
            if (!items.length) {
                setVisible(false);
            }
        },
    });

    return (
        <>
            {ReactDOM.createPortal(
                <Root $theme={theme} $visible={visible}>
                    {transitions((style, item) => (
                        <Snack style={style} {...item} key={item.id}>
                            {item.children}
                        </Snack>
                    ))}
                </Root>,
                root.current,
            )}
            <Context.Provider value={contextValue}>{children}</Context.Provider>
        </>
    );
};

export default theme<IProps, HTMLDivElement>(SnackBarProvider, 'snackBar', 'SnackBarProvider');
