import {createSlice} from '@reduxjs/toolkit';
import {Account} from '../api/getList';
import runtime from '@/store/runtime';

const initialState: Account | null = null;

const account = createSlice({
    name: 'account',
    initialState,
    reducers: {
        set(state, action) {
            runtime.set('ENTERPRISE', action.payload?.id);
            return action.payload;
        },
    },
});

export const {set} = account.actions;
export default account.reducer;
