import React, {useCallback, useLayoutEffect, useMemo} from 'react';
import getList, {
    QUERY_KEY,
    Response,
    Template as ITemplate,
} from '@/features/Templates/api/getList';
import {getLocale} from '@ff/web-components/utils/l10n';
import TaxLayout from '@/features/TransferToTaxService/layout';
import {Dictionary} from '@/locale';
import Button from '@ff/web-components/components/Button';
import {StepProps} from '@/components/StepController';
import {PAYMENT_TYPE as TAX} from '@/features/TransferToTaxService/constants';
import Box from '@ff/web-components/components/Box';
import {useQuery} from '@tanstack/react-query';
import Template from '../template';
import Positive from '@/components/mascot/Positive';
import Text from '@ff/web-components/components/Text';
import ServiceError from '@/components/ServiceError';
import ListSkeleton from '@/features/Templates/list/skeleton';
import {useSearchParams} from 'react-router-dom';
import {PAYMENT_TYPE as ACCOUNT} from '@/features/TransferToAccount/constants';
import AccountLayout from '@/features/TransferToAccount/layout';
import {ResponseError} from '@/api/protocol';
import useLang from '@/hooks/useLang';
import Input from '@ff/web-components/components/Input';
import SearchIcon from '@ff/web-components/components/Icon/Search';
import useTheme from '@/hooks/useTheme';
import useForm from '@/hooks/useForm';
import Sad from '@/components/mascot/Sad';
import search from '../utils/search';

interface IProps extends StepProps {
    onBack?: () => void;
    type: ITemplate['type'];
    onSelect: (template: ITemplate) => void;
    onCreateNew: (type?: ITemplate['type']) => void;
}

const layouts = {
    [TAX]: TaxLayout,
    [ACCOUNT]: AccountLayout,
};

const getServiceLocale = (type: ITemplate['type'], locale: Dictionary) => {
    if (type === TAX) {
        return locale.tax.templates;
    } else if (type === ACCOUNT) {
        return locale.account.templates;
    }
};

const TemplatesList = ({onBack, type, onSelect, onCreateNew}: IProps) => {
    const {c} = useTheme();
    const [searchParams] = useSearchParams();
    const locale: Dictionary = getLocale();
    const serviceLocale = getServiceLocale(type, locale);
    const Layout = layouts[type];
    const handleCreateNew = useCallback(() => {
        if (typeof onCreateNew === 'function') {
            onCreateNew(type);
        }
    }, [type, onCreateNew]);
    const lang = useLang();
    const getListReq = useQuery<Response, ResponseError>({
        queryKey: [QUERY_KEY, type, lang],
        queryFn: () => getList({type}),
    });
    const [state, onChange] = useForm({query: ''});
    const filteredList = useMemo(() => {
        if (state.query && getListReq.data?.templates) {
            return search(getListReq.data.templates, state.query);
        } else {
            return getListReq.data?.templates ?? [];
        }
    }, [state.query, getListReq.data?.templates]);

    useLayoutEffect(() => {
        if (
            searchParams.get('t') &&
            getListReq.isSuccess &&
            getListReq.isFetchedAfterMount &&
            getListReq.data?.templates?.length
        ) {
            const template = getListReq.data?.templates.find(
                ({id}) => String(id) === searchParams.get('t'),
            );
            if (template) {
                onSelect(template);
            }
        }
    }, [getListReq.isFetchedAfterMount]);

    return Layout ? (
        <Layout title={serviceLocale.title} onBack={onBack}>
            {onCreateNew ? (
                <Box mb={3}>
                    <Button primary wide onClick={handleCreateNew}>
                        {serviceLocale.create}
                    </Button>
                </Box>
            ) : null}
            <Box mb={3}>
                <Input
                    value={state.query}
                    onChange={onChange}
                    name="query"
                    startIcon={<SearchIcon color={c.palette.grey['600']} />}
                    disabled={getListReq.isLoading}
                    placeholder={locale.templates.list.search}
                />
            </Box>
            {getListReq.isLoading ? (
                <ListSkeleton />
            ) : getListReq.isError ? (
                <ServiceError error={getListReq.error} onRetry={getListReq.refetch} />
            ) : getListReq.data?.templates?.length ? (
                filteredList.length ? (
                    <>
                        <Box py={1.25}>
                            <Text tt="uppercase" size="xs3" color="secondary" semi>
                                {locale.templates.list.caption}
                            </Text>
                        </Box>
                        {filteredList.map(template => (
                            <Template key={template.id} template={template} onClick={onSelect} />
                        ))}
                    </>
                ) : (
                    <>
                        <Box mb={1.5} df jc="center">
                            <Sad style={{width: '120px'}} />
                        </Box>
                        <Text align="center" semi>
                            {locale.templates.list.notFound}
                        </Text>
                    </>
                )
            ) : (
                <>
                    <Box mb={1.5}>
                        <Positive style={{width: '120px'}} />
                    </Box>
                    <Text align="center" semi>
                        {locale.templates.list.empty}
                    </Text>
                </>
            )}
        </Layout>
    ) : null;
};

export default TemplatesList;
