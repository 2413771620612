const separatorPattern = new RegExp('[.,]', 'g');

export default function prepareSeparatorRegExp(separators: string[]) {
    let fractionPart = separatorPattern;

    fractionPart.lastIndex = 0;

    if (separators) {
        separators = Array.isArray(separators) ? separators : [separators];

        const fractionString = separators
            .map(separator => {
                return separator.length === 1 ? '\\' + separator : '';
            })
            .join('');

        fractionPart = new RegExp('[' + fractionString + ']', 'g');
    }

    return fractionPart;
}
