import 'core-js/stable';
import React from 'react';
import Application from './features/Application/provider';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: 'https://d68017049a89b30e064dead5450e5a04@o4507027378077696.ingest.us.sentry.io/4507027379519488',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        /^https:\/\/business-tst\.unibank\.lan/,
        /^https:\/\/business\.leobank\.az/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById('app')).render(<Application />);
