import styled from 'styled-components';
import STATE from '../../../globals';
import {IDropDownSearchTheme} from '../theme';

interface RootProps {
    $hover?: boolean;
    $error?: boolean;
    $disabled?: boolean;
    $focus?: boolean;
    $theme: IDropDownSearchTheme;
}

const MainWrapper = styled.div<RootProps>`
    position: relative;
    width: 100%;
    ${({$hover, $error, $disabled, $focus, $theme}) => ({
        fontFamily: $theme.fontFamily,
        borderRadius: $theme.borderRadius,
        transitionProperty: 'background, opacity',
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
        background: $disabled
            ? $theme[STATE.DISABLED].background ?? $theme.background
            : $error
            ? $theme[STATE.ERROR].background ?? $theme.background
            : $focus
            ? $theme[STATE.FOCUS].background ?? $theme.background
            : $hover
            ? $theme[STATE.HOVER].background ?? $theme.background
            : $theme.background,
        opacity: $disabled ? $theme[STATE.DISABLED].opacity : 1,
        cursor: $disabled ? 'not-allowed' : 'text',
    })}
`;

export default MainWrapper;
