import React from 'react';
import {Statement} from '@/features/Accounts/api/getStatement';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import CategoryIcon from '@/features/Dashboard/statement/list/style/categoryIcon';
import stringify from '@ff/web-components/utils/number/stringify';
import {ST_STATUS_DECLINE, ST_STATUS_SUCCESS} from '@/features/Accounts/constants';
import useTheme from '@/hooks/useTheme';
import useToggle from '@/hooks/useToggle';
import Modal from '@ff/web-components/components/Modal';
import Details from '@/features/Dashboard/statement/details';
import Description from '@/features/Dashboard/statement/list/style/description';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import useAccount from '@/hooks/useAccount';

const itemStyle = {cursor: 'pointer'};

const Item = ({content, status, value, id, detailContent, date}: Statement) => {
    const {c} = useTheme();
    const [details, toggleDetails] = useToggle(false);
    const account = useAccount();

    return (
        <>
            <Box py={2} px={2.5} df ai="center" style={itemStyle} onClick={() => toggleDetails()}>
                <Box mr={2.5} fs={0} w={5} df>
                    {content.icon ? (
                        <CategoryIcon src={categoryIcon(content.icon, account.id)} />
                    ) : null}
                </Box>
                <Box mr={5} fg={3} fd="column" df>
                    {content.title ? <Text>{content.title}</Text> : null}
                    {content.subTitle ? (
                        <Text
                            size="xs"
                            color={status === ST_STATUS_DECLINE ? 'error' : 'secondary'}
                        >
                            <Description>{content.subTitle.text}</Description>
                        </Text>
                    ) : null}
                </Box>
                <Box w={12} fs={0} df jc="flex-end">
                    <Text
                        size="m"
                        color={
                            status === ST_STATUS_SUCCESS && value.amount > 0
                                ? c.palette.accent.greenApple
                                : status === ST_STATUS_DECLINE
                                ? 'error'
                                : 'primary'
                        }
                    >
                        {stringify(value.amount, {toFixed: 2})}
                    </Text>
                </Box>
            </Box>
            <Modal open={details} onClose={() => toggleDetails()}>
                <Details
                    content={content}
                    status={status}
                    value={value}
                    detailContent={detailContent}
                    date={date}
                    onClose={() => toggleDetails()}
                    id={id}
                />
            </Modal>
        </>
    );
};

export default Item;
