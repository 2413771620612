import React, {lazy, Suspense} from 'react';
import fallback from './fallback.svg';
import {IPlayerProps} from '@lottiefiles/react-lottie-player';

const Animation = lazy(() => import('./animation'));

export type IProps = Omit<IPlayerProps, 'src'>;

const Facepalm: React.FC<IProps> = props => {
    return (
        <Suspense fallback={<img src={fallback} width="100%" />}>
            <Animation {...props} />
        </Suspense>
    );
};

export default Facepalm;
