import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import link from '@/features/Accounts/makeLink';

const Success = (): null => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(link());
    }, []);

    return null;
};

export default Success;
