import React, {useEffect} from 'react';
import Header from './header';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import Root from '@/features/Application/style/layout';
import auth from '@/features/Auth/makeLink';
import {useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import Box from '@ff/web-components/components/Box';
import GradientSpinner from '@/components/GradientSpinner';
import getToken from '@/features/Auth/api/getToken';
import useSession from '@/hooks/useSession';
import {set as setCsrfToken} from '@/features/Application/reducers/csrfToken';
import runtime from '@/store/runtime';
import useAccount from '@/hooks/useAccount';
import PublicHeader from '../header';

const PrivateLayout = () => {
    const {account} = useParams();
    const {authorized} = useSession();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedAccount = useAccount();

    if (!runtime.get('ALIAS')) {
        runtime.set('ALIAS', account);
    }

    const csrfTokenReq = useQuery({
        queryKey: ['/csrfToken'],
        queryFn: getToken,
        staleTime: 1000,
        enabled: !authorized,
    });

    useEffect(() => {
        if (!authorized && csrfTokenReq.isSuccess) {
            dispatch(setCsrfToken(csrfTokenReq.data.csrfToken));
        }
    }, [csrfTokenReq.isSuccess]);

    useEffect(() => {
        if (csrfTokenReq.isError) {
            navigate(auth());
        }
    }, [csrfTokenReq.isError]);

    return (
        <Root>
            {!authorized ? (
                <Box p={3} df jc="center" ai="center" h="100vh">
                    <GradientSpinner size="64px" />
                </Box>
            ) : (
                <>
                    {selectedAccount ? <Header /> : <PublicHeader />}
                    <section>
                        <Outlet />
                    </section>
                </>
            )}
        </Root>
    );
};

export default PrivateLayout;
