import axios from 'axios';
import url from '@/api/url';

export type BudgetClassificator = {
    code: string;
    description: string;
};

export type Response = {
    response: {
        data: {
            budgetClassificator: BudgetClassificator[];
        };
    };
};

export const QUERY_KEY = 'business/payment/budget/classificators';

export default async function getBudgetClassificators(): Promise<Response> {
    return axios.get(url('business/payment/budget/classificators'));
}
