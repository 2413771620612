import axios from 'axios';
import url from '@/api/url';

export interface Messenger {
    deepLink: string;
    iconUrl: string;
    id: string;
    isDefault: boolean;
    title: string;
}

export interface Phone {
    iconUrl: string;
    id: string;
    phone: string;
    title: string;
}

export interface Response {
    data: {
        messengers: Messenger[];
        phones: Phone[];
    };
}

const subString = () => Math.random().toString(36).slice(2, 7);

export const chatId = `${subString()}-${subString()}-${subString()}-${subString()}`;

export default async function getMessengers(): Promise<Response> {
    return axios.get(url('support/messengers'), {params: {chatId}});
}
