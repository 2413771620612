import styled from 'styled-components';
import mascot from '../../../../img/mascot/sad.svg';

const Root = styled.div`
    position: relative;
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -84px;
        left: 62%;
        transform: translateX(-50%);
        background: url(${mascot}) no-repeat;
        background-size: contain;
        width: 234px;
        height: 234px;
    }
`;

export default Root;
