import styled from 'styled-components';
import STATE from '../../../globals';
import transformLabel from '../utils/transformLabel';
import ptn from 'themes/utils/pixelToNumber';

interface LabelProps {
    $theme;
    $toFontSize: string;
    $toLineHeight: string;
    $gap: number;
    $top?: boolean;
    $error?: boolean;
    $disabled?: boolean;
    $defaultCursor?: string;
}

const Label = styled.label<LabelProps>`
    text-wrap: unset;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
    left: 0;
    width: 100%;
    ${({
        $theme,
        $toFontSize,
        $toLineHeight,
        $gap,
        $top,
        $error,
        $disabled,
        $defaultCursor = 'text',
    }) => ({
        ...$theme,
        userSelect: $disabled ? 'none' : 'auto',
        cursor: $disabled ? 'not-allowed' : $defaultCursor,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        transitionProperty: 'color, transform',
        transitionDuration: $theme.theme.transition?.duration,
        transitionTimingFunction: $theme.theme.transition?.timingFunction,
        transformOrigin: 'left center',
        ...($error && $top ? $theme[STATE.ERROR] : {}),
        ...(!$top
            ? transformLabel({
                  from: $theme.fontSize,
                  to: $toFontSize,
                  offset:
                      (ptn($theme.lineHeight) + ptn($toLineHeight) + $gap - ptn($toLineHeight)) /
                          2 +
                      'px',
              })
            : {}),
    })};
`;

export default Label;
