import React, {useCallback, useMemo, useRef} from 'react';
import Input, {IProps as InputProps} from '../index';
import formatter from './utils/formatter';
import {SyntheticEvent} from 'utils/syntheticEvent';
import {getPosition, setCarriage} from 'utils/carriagePositionHelper';

export interface IProps extends InputProps {
    separators?: string[];
    fractionLength?: number;
    currency?: string;
}

const filterInput = value => value.replace(/[^\-.,\s\d]/gi, '');

const SumInput = React.forwardRef<HTMLInputElement, IProps>(
    (
        {
            onChange,
            separators = [',', '.'],
            fractionLength = 2,
            value,
            inputMode = 'decimal',
            // limit for Math.trunc() BigInt
            maxLength = 19,
            currency,
            ...rest
        }: IProps,
        ref,
    ) => {
        const inputInner = useRef<HTMLInputElement | null>(null);
        const inputReference = ref || inputInner;
        const formattedValue = useMemo(
            () => formatter(value as string, separators, fractionLength, currency),
            [value],
        );
        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent) => {
                let carriagePosition = 0;
                let formattedNextValue = e.currentTarget.value;

                if (e.currentTarget.value) {
                    formattedNextValue = formatter(
                        filterInput(e.currentTarget.value),
                        separators,
                        fractionLength,
                        currency,
                    );
                    carriagePosition = getPosition(
                        e.currentTarget.value,
                        e.target.selectionStart,
                        /[-.,\d]/,
                    );

                    e.currentTarget.value = formattedNextValue;
                }

                if (typeof onChange === 'function') {
                    onChange(e);
                    setTimeout(() => {
                        if (typeof inputReference === 'object' && inputReference.current) {
                            setCarriage(
                                inputReference.current,
                                formattedNextValue,
                                carriagePosition,
                                /[-.,\d]/,
                            );
                        }
                    }, 0);
                }
            },
            [onChange, separators, fractionLength],
        );

        return (
            <Input
                {...rest}
                onChange={handleChange}
                value={formattedValue}
                inputMode={inputMode}
                ref={inputReference}
                maxLength={maxLength}
            />
        );
    },
);

SumInput.displayName = 'SumInput';

export default SumInput;
