import {combineReducers, configureStore} from '@reduxjs/toolkit';
import application from '@/features/Application/reducers';
import account from '@/features/Accounts/reducers/account';

const store = configureStore({
    reducer: {
        application: combineReducers(application),
        account,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
