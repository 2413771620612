'use client';
'use strict';
import React, {useEffect, useId, useRef} from 'react';
import Context, {SnackBarContextValue} from './context';
import {IProps as SnackProps} from './snack';

export interface IProps extends SnackProps {
    open: boolean;
}

type ISnackWrapper = SnackBarContextValue & {
    open: boolean;
    snackProps: SnackProps;
    id: string;
    children?: React.ReactNode;
};

const SnackWrapper = ({add, remove, open, snackProps, id, children}: ISnackWrapper) => {
    const removeTimeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (open) {
            add(id, {...snackProps, children});

            if (snackProps.duration !== 0) {
                removeTimeout.current = setTimeout(() => remove(id), snackProps.duration ?? 3000);
            }
        } else {
            remove(id);
        }
    }, [open]);

    return null;
};

const SnackBar = ({open, children, ...rest}: IProps) => {
    const id = useId();

    return (
        <Context.Consumer>
            {value => (
                <SnackWrapper {...value} open={open} snackProps={rest} id={id}>
                    {children}
                </SnackWrapper>
            )}
        </Context.Consumer>
    );
};

export default SnackBar;
