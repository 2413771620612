import styled from 'styled-components';
import {IDropDownSearchTheme} from '../theme';

interface SelectProps {
    $theme: IDropDownSearchTheme;
    $withLabel: boolean;
}

const Select = styled.input<SelectProps>`
    ${({$theme, $withLabel}) => ({
        fontFamily: $theme.fontFamily,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        height: $theme.lineHeight,
        color: $theme.color,
        paddingTop: $withLabel ? $theme.theme.gutter / 4 + 'px' : 0,
        textAlign: 'left',
        width: '100%',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        '&[disabled]': {
            cursor: 'not-allowed',
            userSelect: 'none',
        },
    })};
`;

export default Select;
