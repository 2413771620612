import styled from 'styled-components';

interface OptionsItemProps {
    $theme;
}

const OptionsItem = styled.div<OptionsItemProps>`
    cursor: pointer;
    transition-property: background;
    ${({$theme}) => ({
        padding: `${$theme.theme.gutter * 2.5}px`,
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
        '&:hover': {
            background: $theme.theme.palette.grey['200'],
        },
    })}
`;

export default OptionsItem;
