import styled from 'styled-components';

interface LabelProps {
    $theme;
}

const Label = styled.label<LabelProps>`
    user-select: none;
    cursor: pointer;
    ${({$theme}) => ({
        fontFamily: $theme.fontFamily,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        color: $theme.color,
    })}
`;

export default Label;
