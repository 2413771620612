import React from 'react';
import Skeleton from '@ff/web-components/components/Skeleton';
import Box from '@ff/web-components/components/Box';
import useTheme from '@/hooks/useTheme';

const BalanceSkeleton = () => {
    const {c} = useTheme();

    return (
        <div>
            <Box df ai="center" h={c.text.lineHeight.xl7} mb={0.5}>
                <Skeleton w={24} h={c.text.fontSize.xl7} />
            </Box>
            <Box df ai="center" h={c.text.lineHeight.xs2}>
                <Skeleton w={24} h={c.text.fontSize.xs2} />
            </Box>
            <Box df ai="center" h={c.text.lineHeight.xs2}>
                <Skeleton w={24} h={c.text.fontSize.xs2} />
            </Box>
        </div>
    );
};

export default BalanceSkeleton;
