import styled from 'styled-components';

const CategoryIcon = styled.div`
    display: flex;
    align-items: center;
    ${({theme}) => ({
        padding: `${theme.c.gutter * 1.5}px`,
        background: theme.p.palette.secondary.blueOcean20,
        borderRadius: '20px',
    })}
`;

export default CategoryIcon;
