import styled from 'styled-components';
import STATE from '../../../globals';

interface RootProps {
    $theme;
}

const Root = styled.button<RootProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0 none;
    outline: none;
    ${({$theme, disabled}) => ({
        ...$theme,
        cursor: disabled ? 'not-allowed' : 'pointer',
        '&:hover': disabled ? undefined : $theme[STATE.HOVER],
        '&:active': disabled ? undefined : $theme[STATE.ACTIVE],
        '&[disabled]': $theme[STATE.DISABLED],
    })};
`;

export default Root;
