import separatorsRegExp from './prepareSeparatorRegExp';

const notNumberPattern = new RegExp('[^0-9,.-]', 'g');

export default function parse(value: string | number, separators: string[]) {
    let dotCount = 0;
    notNumberPattern.lastIndex = 0;

    return (
        String(value)
            .replace(separatorsRegExp(separators), '.')
            .replace(/[.]/g, dot => {
                dotCount += 1;

                // leave only first dot
                return dotCount === 1 ? dot : '';
            })
            // remove all sign char after first
            .replace(/-/g, (sign, offset) => (offset === 0 ? sign : ''))
            .replace(notNumberPattern, '')
    );
}
