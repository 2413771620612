import React, {useEffect} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToTaxService';
import {getLocale} from '@ff/web-components/utils/l10n';
import Layout from '../layout';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Divider from '@ff/web-components/components/Divider';
import LoadingButton from '@/components/LoadingButton';
import {useMutation} from '@tanstack/react-query';
import {ResponseError} from '@/api/protocol';
import confirm, {Response as ConfirmResponse} from '@/features/TransferToTaxService/api/confirm';
import useForm from '@/hooks/useForm';
import {Dictionary} from '@/locale';
import Checkbox from '@ff/web-components/components/Checkbox';
import FormError from '@/components/FormError';
import {Template as ITemplate} from '@/features/Templates/api/getList';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: State['payment']) => void;
    type: ITemplate['type'];
}

const Confirm = ({data, toPrevStep, onSubmit}: IProps) => {
    const [state, onChange] = useForm({addTemplate: false});
    const locale: Dictionary = getLocale();
    const taxLocale: Dictionary['tax'] = locale.tax;
    const saveReq = useMutation<ConfirmResponse, ResponseError>({
        mutationFn: () => confirm({...data.dataForReq, addTemplate: state.addTemplate}),
    });
    const templateSelected =
        data.template &&
        data.template.templateDetails &&
        data.template.templateDetails.amount === data.dataForReq.amount &&
        data.template.templateDetails.voen === data.dataForReq.voen &&
        data.template.templateDetails.paymentTypeId === data.dataForReq.paymentTypeId &&
        data.template.templateDetails.paymentDetail?.code === data.dataForReq.paymentDetail.code;

    useEffect(() => {
        if (saveReq.isSuccess) {
            onSubmit(saveReq.data);
        }
    }, [saveReq.isSuccess]);

    return (
        <Layout onBack={toPrevStep} title={taxLocale.confirm.title}>
            {saveReq.isError ? <FormError error={saveReq.error} /> : null}
            <form
                action="/"
                method="post"
                onSubmit={e => {
                    e.preventDefault();
                    saveReq.mutate();
                }}
            >
                <Box df fd="column" gap={2} mb={2}>
                    {data.extraFields?.map(({name, value}) => (
                        <Box key={name}>
                            <Text size="xs" color="secondary" align="left">
                                {name}
                            </Text>
                            <Text align="left">{value}</Text>
                        </Box>
                    ))}
                </Box>
                {!templateSelected ? (
                    <>
                        <Box>
                            <Divider />
                        </Box>
                        <Box py={2.5}>
                            <Checkbox
                                checked={state.addTemplate}
                                name="addTemplate"
                                onChange={onChange}
                            >
                                {taxLocale.confirm.saveTemplate}
                            </Checkbox>
                        </Box>
                    </>
                ) : null}
                <Box mb={3}>
                    <Divider />
                </Box>
                <LoadingButton primary wide type="submit" loading={saveReq.isPending}>
                    {taxLocale.confirm.submit}
                </LoadingButton>
            </form>
        </Layout>
    );
};

export default Confirm;
