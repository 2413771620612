import styled from 'styled-components';
import {animated} from '@react-spring/web';

interface RootProps {
    $theme;
}

const Snack = styled(animated.div)<RootProps>`
    position: relative;
    ${({$theme}) => ({
        textAlign: $theme.textAlign,
        fontFamily: $theme.fontFamily,
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
        color: $theme.color,
        padding: $theme.padding,
        borderRadius: $theme.borderRadius,
        background: $theme.background,
    })};
`;

export default Snack;
