import styled from 'styled-components';
import scrollbar from 'themes/utils/scrollbar';

interface ListProps {
    $theme;
}

const List = styled.div<ListProps>`
    ${({$theme}) => ({
        maxHeight: $theme.maxHeight,
        overflowY: 'auto',
        ...scrollbar($theme.theme),
    })}
`;

export default List;
