import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const PointerRight: React.FC<IProps> = ({
    color,
    width = '24px',
    height = '24px',
    theme,
}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m13.207 3.793 7.5 7.5.707.707-.707.707-7.5 7.5-1.414-1.414L17.586 13H3v-2h14.586l-5.793-5.793 1.414-1.414Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(PointerRight, 'icon', 'PointerRight');
