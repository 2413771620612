import axios from 'axios';
import url from '@/api/url';
import {chatId, Messenger} from '@/features/Application/api/getMessengers';

interface Params {
    messenger: Messenger['id'];
}

export default async function startChat(data: Params): Promise<Response> {
    return axios.post(url('support/start-chat'), {data: {...data, chatId}});
}
