import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import getBalance, {QUERY_KEY, Response} from '@/features/Accounts/api/getBalance';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import IconFx from '@ff/web-components/components/IconFx';
import IconButton from '@ff/web-components/components/IconButton';
import RequisitesIcon from '@ff/web-components/components/Icon/Paper';
import WithdrawIcon from '@ff/web-components/components/Icon/PointerRight';
import {getLocale} from '@ff/web-components/utils/l10n';
import Money from '@/components/Money';
import BalanceSkeleton from './balance.skeleton';
import PaymentButton from './paymentButton';
import transferToCard from '@/features/TransferToMyCard/makeLink';
import requisites from '@/features/Requisites/makeLink';
import {Dictionary} from '@/locale';
import {ResponseError} from '@/api/protocol';
import ServiceError from '@/components/ServiceError';
import CreditState from '@/features/Dashboard/head/creditState';

const Head = () => {
    const navigate = useNavigate();
    const getBalanceReq = useQuery<Response, ResponseError>({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
        staleTime: 1000,
    });
    const locale: Dictionary['dashboard'] = getLocale().dashboard;

    return (
        <Box df ai="center" jc="space-between" py={4}>
            <div>
                {getBalanceReq.isLoading ? (
                    <BalanceSkeleton />
                ) : getBalanceReq.isError ? (
                    <ServiceError error={getBalanceReq.error} onRetry={getBalanceReq.refetch} />
                ) : getBalanceReq.data ? (
                    <div>
                        <Box mb={0.5}>
                            <Money
                                size="xl7"
                                fractionSize="xl2"
                                bold
                                sum={getBalanceReq.data.data.balances.amount}
                                currency={getBalanceReq.data.data.displayedInfo.currencySymbol}
                            />
                        </Box>
                        {getBalanceReq.data.data.balances.creditLimit > 0 ? (
                            <Box df fd="column" maxW="fit-content">
                                <Box df jc="space-between">
                                    <Box dif mr={1}>
                                        <Text size="xs2" color="secondary">
                                            {locale.credit}
                                        </Text>
                                    </Box>
                                    <Money
                                        color="secondary"
                                        size="xs2"
                                        sum={getBalanceReq.data.data.balances.creditLimit}
                                        currency={
                                            getBalanceReq.data.data.displayedInfo.currencySymbol
                                        }
                                    />
                                </Box>
                                {getBalanceReq.data.data.balances.selfBalance > 0 ||
                                getBalanceReq.data.data.balances.creditUsed > 0 ? (
                                    <Box df jc="space-between">
                                        <Box dif mr={1}>
                                            <Text size="xs2" color="secondary">
                                                {getBalanceReq.data.data.balances.selfBalance > 0
                                                    ? locale.ownFunds
                                                    : locale.debt}
                                            </Text>
                                        </Box>
                                        <Money
                                            color="secondary"
                                            size="xs2"
                                            sum={
                                                getBalanceReq.data.data.balances.selfBalance > 0
                                                    ? getBalanceReq.data.data.balances.selfBalance
                                                    : getBalanceReq.data.data.balances.creditUsed
                                            }
                                            currency={
                                                getBalanceReq.data.data.displayedInfo.currencySymbol
                                            }
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                        ) : null}
                        {getBalanceReq.data.data.balances.creditUsed > 0 ? (
                            <Box mt={1.5}>
                                <CreditState />
                            </Box>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <Box df>
                <Box mr={1} w={15} ai="center" df fd="column">
                    <PaymentButton />
                </Box>
                <Box mr={1} w={15} ai="center" df fd="column">
                    <IconButton primary onClick={() => navigate(requisites())}>
                        <RequisitesIcon />
                    </IconButton>
                    <Text size="xs2" align="center" ws="pre-wrap">
                        <Box pt={1}>{locale.requisites}</Box>
                    </Text>
                </Box>
                <Box w={15} ai="center" df fd="column" onClick={() => navigate(transferToCard())}>
                    <IconFx rotate="90deg">
                        <IconButton primary>
                            <WithdrawIcon />
                        </IconButton>
                    </IconFx>
                    <Text size="xs2" align="center" ws="pre-wrap" cursor="pointer">
                        <Box pt={1}>{locale.withdraw}</Box>
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

export default Head;
