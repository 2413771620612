import React, {useEffect} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import getList from '@/features/Accounts/api/getList';
import useAccount from '@/hooks/useAccount';
import {set} from '@/features/Accounts/reducers/account';
import accountsLink from '@/features/Accounts/makeLink';
import auth from '@/features/Auth/makeLink';
import GradientSpinner from '@/components/GradientSpinner';
import Box from '@ff/web-components/components/Box';
import {useDispatch} from 'react-redux';
import useLang from '@/hooks/useLang';

const EnterpriseLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enterprise} = useParams();
    const selectedEnterprise = useAccount();
    const lang = useLang();
    const listReq = useQuery({
        queryKey: ['/accounts', lang],
        queryFn: getList,
        staleTime: 1000,
    });

    useEffect(() => {
        if (listReq.isSuccess && !selectedEnterprise) {
            if (listReq.data?.length) {
                const acc = listReq.data.find(({id}) => String(id) === enterprise);
                if (acc) {
                    dispatch(set(acc));
                } else {
                    navigate(accountsLink());
                }
            } else {
                navigate(auth());
            }
        }
    }, [listReq.isSuccess]);

    useEffect(() => {
        if (listReq.isError) {
            navigate(accountsLink());
        }
    }, [listReq.isError]);

    return selectedEnterprise ? (
        <Outlet />
    ) : (
        <Box p={3} df jc="center" ai="center" h="100vh">
            <GradientSpinner size="64px" />
        </Box>
    );
};

export default EnterpriseLayout;
