import axios from 'axios';
import url from '@/api/url';
import {Budget} from '@/features/TransferToAccount/api/saveReceiver';
import {BudgetClassificator} from '@/features/TransferToAccount/api/getBudgetClassificators';

export type Response = {
    response: {
        paymentInfo: {
            title: string;
            subTitle: string;
        };
        transaction: {
            imageUrl?: string;
        };
    };
};

export interface Params {
    iban: string;
    paymentDescription: string;
    amount: number;
    senderCardId: string;
    vatAmount?: boolean;
    receiverBankCode?: string;
    receiverVoin?: string;
    receiverName?: string;
    addTemplate?: boolean;
    budgetLevelCode?: Budget['code'];
    budgetClassificatorCode?: BudgetClassificator['code'];
}

export default async function saveForm(params: Params): Promise<Response> {
    return axios.post(url('business/payment'), params);
}
