'use client';
'use strict';
import React from 'react';
import defaultTheme from './theme';
import useMergeTheme from '../../hooks/useMergeTheme';
import Root from './style/root';
import theme from '../theme';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    vertical?: boolean;
}

const Divider = ({style, theme, vertical, ...rest}: IProps) => {
    const computedTheme = useMergeTheme(theme, style);
    return <Root $theme={computedTheme} $vertical={vertical} {...rest} />;
};

export default theme<IProps, HTMLDivElement>(Divider, 'divider', 'Divider');
