import axios from 'axios';
import url from '@/api/url';

export type Response = {
    response: {
        paymentInfo: {
            title: string;
            subTitle: string;
        };
        transaction: {
            imageUrl?: string;
        };
    };
};

export interface Params {
    amount: number;
}

export default async function saveForm(params: Params): Promise<Response> {
    return axios.post(url('cards/main/topup'), params);
}
