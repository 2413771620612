import React, {useRef} from 'react';
import Box from '@ff/web-components/components/Box';
import {getLocale} from '@ff/web-components/utils/l10n';
import Overlay from '@ff/web-components/components/Overlay';
import useToggle from '@/hooks/useToggle';
import Paper from '@ff/web-components/components/Paper';
import ClickAwayListener from '@ff/web-components/components/ClickAwayListener';
import Text from '@ff/web-components/components/Text';
import Divider from '@ff/web-components/components/Divider';
import {Dictionary} from '@/locale';
import {useMutation, useQuery} from '@tanstack/react-query';
import getMessengers, {Messenger, Response} from '@/features/Application/api/getMessengers';
import LoadingButton from '@/components/LoadingButton';
import {ResponseError} from '@/api/protocol';
import useAccount from '@/hooks/useAccount';
import startChat from '@/features/Application/api/startChat';
import Link from '@ff/web-components/components/Link';
import useLang from '@/hooks/useLang';

const Support = () => {
    const locale: Dictionary = getLocale();
    const [active, toggleActive] = useToggle(false);
    const root = useRef(null);
    const account = useAccount();
    const lang = useLang();
    const messengersReq = useQuery<Response, ResponseError>({
        queryKey: ['support/messengers', lang],
        queryFn: getMessengers,
        enabled: !!account,
    });
    const startChatReq = useMutation({mutationFn: startChat});
    const handleChatOpen = (messenger: Messenger['id']) => {
        startChatReq.mutate({messenger});
    };

    return (
        <Box ref={root} pos="relative">
            <LoadingButton
                loading={messengersReq.isLoading || messengersReq.isFetching}
                secondary
                small
                onClick={
                    messengersReq.isError ? () => messengersReq.refetch() : () => toggleActive()
                }
                pressed={active}
            >
                💬 {locale.header.help}
            </LoadingButton>
            {messengersReq.data?.data ? (
                <Overlay active={active} offsetTop={8} position={{x: 'right', y: 'bottom'}}>
                    <Box w={48}>
                        <Paper overlay py={1}>
                            {messengersReq.data.data.messengers?.length
                                ? messengersReq.data.data.messengers.map(messenger => (
                                      <div key={messenger.id}>
                                          <Link
                                              href={messenger.deepLink}
                                              target="_blank"
                                              rel="noreferrer"
                                              onClick={() => handleChatOpen(messenger.id)}
                                              size="m"
                                              align="left"
                                          >
                                              <Box px={2.5} py={1.25} df ai="center">
                                                  <Box mr={2.5} df ai="center">
                                                      <img
                                                          src={messenger.iconUrl}
                                                          alt={messenger.title}
                                                          width="40"
                                                          height="40"
                                                      />
                                                  </Box>
                                                  <div>
                                                      <div>{messenger.title}</div>
                                                      {messenger.isDefault ? (
                                                          <Text size="xs" color="secondary">
                                                              {locale.header.defaultMessenger}
                                                          </Text>
                                                      ) : null}
                                                  </div>
                                              </Box>
                                          </Link>
                                      </div>
                                  ))
                                : null}
                            {messengersReq.data.data.phones?.length ? (
                                <>
                                    <Box py={1} px={2.5}>
                                        <Divider />
                                    </Box>
                                    {messengersReq.data.data.phones.map(phone => (
                                        <Box px={2.5} py={1.25} key={phone.id}>
                                            <Text size="xs" color="secondary">
                                                {phone.title}
                                            </Text>
                                            <div>
                                                <Link
                                                    size="m"
                                                    color="primary"
                                                    href={`tel:${phone.phone.replace(
                                                        /[\s*]/g,
                                                        '',
                                                    )}`}
                                                >
                                                    {phone.phone}
                                                </Link>
                                            </div>
                                        </Box>
                                    ))}
                                </>
                            ) : null}
                        </Paper>
                    </Box>
                </Overlay>
            ) : null}
            <ClickAwayListener
                node={root}
                onClickAway={active ? () => toggleActive(false) : undefined}
            />
        </Box>
    );
};

export default Support;
