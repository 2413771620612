import styled from 'styled-components';

interface ShortcutProps {
    $theme;
    $selected?;
}

const Shortcut = styled.div<ShortcutProps>`
    cursor: pointer;
    transition-property: background;
    ${({$theme, $selected}) => ({
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
        padding: $theme.theme.gutter * 2.5 + 'px',
        background: $selected ? $theme.theme.palette.grey['200'] : 'transparent',
    })}
`;

export default Shortcut;
