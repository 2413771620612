import React from 'react';
import Mascot, {IProps as MascotProps} from '@/components/mascot';

export type IProps = Omit<MascotProps, 'name'>;

const roll = [
    'Cybertruck',
    'Pharaon',
    'Superman',
    'Avatar',
    'Spiderman',
    'Hulk',
    'Paris',
    'Ninja',
    'Cowboy',
    'Banksy',
    'MeditationWithCards',
    'SteveJobs',
    'TurkishChiefSalt',
    'FrayDollars',
    'FrayCard',
    'PackOfDollars',
    'CarpetAladdin',
    'CardKingLeo',
    'Sonic',
    'TurtleLeonardo',
    'PlateOldBanks',
    'HarryPotter',
    'WolverineLogan',
    'ScroogeMcDuck',
    'Meditation',
    'RodenThinker',
    'Witcher',
    'Zhdun',
    'Anonymus',
    'VanGoghHat',
    'Sith',
    'Sherlock',
    'PopcornCinema',
    'BallenciagaRedJacket',
    'SadBroccoli',
    'MagritteApple',
    'LoveIs',
    'Mario',
    'MoneyLayDown',
    'Picasso',
    'SculptureDiscus',
    'Sparta',
    'TrucksVanDamme',
    'MoneyGunFront',
    'Leocraft',
    'Leocoin',
    'Newton',
    'Yoda',
    'Leoground',
    'Scorpio',
    'Squid',
    'Joker',
    'Dune',
    'IronMan',
    'Shining',
    'Matrix',
    'YouCantJustTake',
    'BreakingBad',
    'MonaLisa',
    'Einstein',
    'DaliMustache',
    'GoldwynMayer',
    'Leonardo',
    'Vincent',
    'MunchScream',
    'WinterIsComing',
    'Positive',
    'Dicaprio',
    'BakuF1',
    'Karateka',
    'Drum',
    'DanceHat',
    'Taxi',
    'DanceWithGirl',
    'MoneyFanHat',
    'Policeman',
    'TaxiRedCab',
    'FruitNinja',
    'Oilman',
    'CapitanAzerbaijan',
    'Tea',
    'Donkey',
    'N1001',
    'Horse',
    'Carpets',
    'SendMoney',
];

const Success = (props: IProps) => {
    return (
        <Mascot
            name={roll[Math.floor(Math.random() * roll.length)] as MascotProps['name']}
            {...props}
        />
    );
};

export default Success;
