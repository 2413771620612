import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Download: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m3.586 11 8.207 8.207L20 11l-1.414-1.414-5.8 5.8V.792h-2v14.58L5 9.585 3.586 11Z"
                    fill={color}
                />
                <path d="M22 23H2v-2h20v2Z" fill={color} fillRule="evenodd" />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Download, 'icon', 'Download');
