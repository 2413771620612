'use client';
'use strict';
import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import theme from '../theme';
import ModalBase from './modalBase';
import {animated, useTransition} from '@react-spring/web';
import Root from './style/root';
import defaultTheme from './theme';
import useMergeTheme from '../../hooks/useMergeTheme';
import useEventListener from 'react-use-event-listener';
import Overlay from './style/overlay';
import Box from '../Box';
import {DeepPartial} from 'themes/index';

export interface IProps {
    open: boolean;
    children?: React.ReactNode;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    onClose?: () => void;
    offsetTop?: number;
    offsetBottom?: number;
}

const Modal = ({
    open,
    children,
    theme,
    style,
    onClose,
    offsetTop = 80,
    offsetBottom = 80,
}: IProps) => {
    const computedTheme = useMergeTheme(theme, style);
    const transition = useTransition(open ? [1] : [], {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        config: {duration: 150},
    });
    const root = useRef<HTMLDivElement | null>(null);
    const content = useRef<HTMLDivElement | null>(null);
    const [windowSize, setWindowSize] = useState<{minWidth?: number; minHeight?: number}>({
        minWidth: undefined,
        minHeight: undefined,
    });
    const close = useCallback(
        e => {
            if (
                typeof onClose === 'function' &&
                root.current &&
                content.current &&
                root.current.contains(e.target) &&
                !content.current.contains(e.target)
            ) {
                onClose();
            }
        },
        [onClose],
    );
    useEventListener('resize', () =>
        setWindowSize({
            minWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
            minHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
        }),
    );

    // for NextJS! window is undefined outside effect
    useLayoutEffect(() => {
        if (open) {
            setWindowSize({
                minWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
                minHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
            });
        }
    }, [open]);

    return transition(transitionStyle => (
        <ModalBase>
            <animated.div style={transitionStyle}>
                <Root $theme={computedTheme} style={windowSize} ref={root} onClick={close}>
                    <Overlay $theme={computedTheme.overlay} style={windowSize} />
                    <Box
                        df
                        jc="center"
                        ai="center"
                        pos="relative"
                        bs="border-box"
                        h={windowSize.minHeight ? `${windowSize.minHeight}px` : 'auto'}
                    >
                        <Box df jc="center" of="auto" w="100%" h="100%">
                            <Box
                                df
                                jc="center"
                                ai="center"
                                minH="100%"
                                bs="border-box"
                                h="fit-content"
                            >
                                <div
                                    ref={content}
                                    style={{
                                        paddingTop: !isNaN(offsetTop)
                                            ? offsetTop + 'px'
                                            : undefined,
                                        paddingBottom: !isNaN(offsetBottom)
                                            ? offsetBottom + 'px'
                                            : undefined,
                                    }}
                                >
                                    {children}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Root>
            </animated.div>
        </ModalBase>
    ));
};

export default theme<IProps, HTMLDivElement>(Modal, 'modal', 'Modal');
