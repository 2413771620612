import React, {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import getCreditState from '@/features/Accounts/api/getCreditState';
import BadgeButton from '@ff/web-components/components/BadgeButton';
import useTheme from '@/hooks/useTheme';
import ArrowRight from '@ff/web-components/components/Icon/ArrowRight';
import Box from '@ff/web-components/components/Box';
import getLoanInfo from '@/features/Accounts/api/getLoanInfo';
import Modal from '@ff/web-components/components/Modal';
import Paper from '@ff/web-components/components/Paper';
import useToggle from '@/hooks/useToggle';
import IconButton from '@ff/web-components/components/IconButton';
import CloseIcon from '@ff/web-components/components/Icon/CrossClose';
import LoanInfo from '@/features/Dashboard/style/loanInfo';
import GradientSpinner from '@/components/GradientSpinner';
import useLang from '@/hooks/useLang';

const CreditState = () => {
    const {c} = useTheme();
    const lang = useLang();
    const creditStateReq = useQuery({
        queryKey: ['credit-state', lang],
        queryFn: getCreditState,
        staleTime: 10000,
    });
    const loanInfoReq = useQuery({
        queryKey: ['loan-info', lang],
        queryFn: getLoanInfo,
        enabled: false,
        staleTime: 10000,
    });
    const [modal, toggleModal] = useToggle(false);
    const [openOnSuccess, toggleOpenOnSuccess] = useToggle(false);

    useEffect(() => {
        if (!loanInfoReq.isFetching && loanInfoReq.isSuccess && openOnSuccess) {
            toggleModal(true);
        }
    }, [loanInfoReq.isFetching]);

    return (
        <>
            <BadgeButton
                warning={creditStateReq.data?.data.isLate}
                onClick={
                    creditStateReq.isSuccess && !loanInfoReq.isFetching
                        ? loanInfoReq.isSuccess && !loanInfoReq.isStale
                            ? () => toggleModal(true)
                            : () => {
                                  toggleOpenOnSuccess(true);
                                  loanInfoReq.refetch();
                              }
                        : undefined
                }
            >
                {creditStateReq.isFetching || creditStateReq.isLoading ? (
                    <GradientSpinner size={c.text.lineHeight.xs2} color={c.palette.grey['800']} />
                ) : creditStateReq.data ? (
                    <Box df ai="center">
                        {creditStateReq.data.data.title}
                        <Box ml={0.5}>
                            {loanInfoReq.isFetching ? (
                                <GradientSpinner
                                    size={c.gutter * 1.5 + 'px'}
                                    color={
                                        creditStateReq.data.data.isLate
                                            ? c.palette.white
                                            : c.palette.grey['1000']
                                    }
                                />
                            ) : (
                                <ArrowRight
                                    color={
                                        creditStateReq.data.data.isLate
                                            ? c.palette.grey['800']
                                            : c.palette.grey['600']
                                    }
                                    width={c.gutter * 1.5 + 'px'}
                                    height={c.gutter * 1.5 + 'px'}
                                />
                            )}
                        </Box>
                    </Box>
                ) : null}
            </BadgeButton>
            <Modal open={modal} onClose={() => toggleModal(false)}>
                <Paper px={2.5} py={2.5} pb={5} overlay w={47}>
                    <Box df jc="flex-end" ai="center" mb={1}>
                        <IconButton secondary onClick={() => toggleModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <LoanInfo dangerouslySetInnerHTML={{__html: loanInfoReq.data?.data}} />
                </Paper>
            </Modal>
        </>
    );
};

export default CreditState;
