import styled from 'styled-components';
import STATE from '../../../globals';

interface RootProps {
    $wide;
    $hover;
    $error;
    $disabled;
    $focus;
    $theme;
}

const Root = styled.div<RootProps>`
    position: relative;
    ${({$wide, $hover, $error, $disabled, $focus, $theme}) => ({
        width: $wide ? '100%' : 'auto',
        borderRadius: $theme.borderRadius,
        transitionProperty: 'background, opacity',
        transitionDuration: $theme.theme.transition?.duration,
        transitionTimingFunction: $theme.theme.transition?.timingFunction,
        background: $disabled
            ? $theme[STATE.DISABLED]?.background ?? $theme.background
            : $error
            ? $theme[STATE.ERROR]?.background ?? $theme.background
            : $focus
            ? $theme[STATE.FOCUS]?.background ?? $theme.background
            : $hover
            ? $theme[STATE.HOVER]?.background ?? $theme.background
            : $theme.background,
        opacity: $disabled
            ? $theme[STATE.DISABLED]?.opacity ?? 1
            : $error
            ? $theme[STATE.ERROR]?.opacity ?? 1
            : $focus
            ? $theme[STATE.FOCUS]?.opacity ?? 1
            : $hover
            ? $theme[STATE.HOVER]?.opacity ?? 1
            : 1,
    })}
`;

export default Root;
