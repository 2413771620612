import React, {useCallback, useEffect} from 'react';
import {
    PAYMENT_TYPE,
    STEP_CONFIRM,
    STEP_FORM,
    STEP_RECEIVER,
    STEP_RESULT,
    STEP_TEMPLATE_LOADER,
    STEP_TEMPLATES,
} from './constants';
import {PaymentTemplate} from '@/features/Templates/api/getList';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useForm from '@/hooks/useForm';
import StepController from '@/components/StepController';
import Templates from '@/features/Templates/list';
import dashboardLink, {templates} from '@/features/Dashboard/makeLink';
import Box from '@ff/web-components/components/Box';
import Receiver from '@/features/TransferToAccount/receiver';
import Form from './form';
import Confirm from './confirm';
import Result from './result';
import {Branch, Budget, Response as ReceiverResponse} from './api/saveReceiver';
import {Response as PaymentResponse} from './api/confirm';
import {ResponseError} from '@/api/protocol';
import TemplateLoader from '@/features/TransferToAccount/templateLoader';
import link from './makeLink';
import {pageView} from '@/utils/gtag';
import {BudgetClassificator} from '@/features/TransferToAccount/api/getBudgetClassificators';

export interface State {
    step:
        | typeof STEP_TEMPLATE_LOADER
        | typeof STEP_FORM
        | typeof STEP_CONFIRM
        | typeof STEP_RESULT
        | typeof STEP_TEMPLATES
        | typeof STEP_RECEIVER;
    iban: string;
    dest: string;
    sum: string | number | null;
    commission: number;
    currency: string;
    template: PaymentTemplate | null;
    includeNSD: boolean;
    nds?: number;
    receiverVoin?: string;
    receiverName?: string;
    receiverBankCode?: Branch['bankCode'];
    senderCardId?: string;
    receiver?: ReceiverResponse['response']['data'];
    payment?: PaymentResponse['response'];
    confirmData?: {
        budgetLevel: Budget;
        budgetClassificator: BudgetClassificator;
    };
    confirmError?: ResponseError;
    budgetLevelCode?: Budget['code'];
    budgetClassificatorCode?: BudgetClassificator['code'];
}

const defaultParams: State = {
    step: STEP_TEMPLATES,
    iban: 'AZ',
    dest: null,
    sum: null,
    receiverVoin: null,
    receiverName: null,
    receiverBankCode: null,
    commission: 0,
    currency: 'AZN',
    template: null,
    includeNSD: false,
    nds: undefined,
    receiver: undefined,
    senderCardId: null,
    confirmError: undefined,
    budgetLevelCode: null,
    budgetClassificatorCode: null,
    confirmData: undefined,
};

const TransferToAccount = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [state, onChange, setState] = useForm<State>({...defaultParams});
    const handleConfirmError = useCallback(
        (data: ResponseError) =>
            setState({
                confirmError: data,
                step: STEP_FORM,
            }),
        [],
    );
    const handleConfirmSubmit = useCallback(
        (data: PaymentResponse['response']) =>
            setState({
                payment: data,
                step: STEP_RESULT,
            }),
        [],
    );
    const handleFormSubmit = useCallback(
        (data: Partial<State>) =>
            setState({
                ...data,
                confirmError: undefined,
                step: STEP_CONFIRM,
            }),
        [],
    );
    const handleReceiverSubmit = useCallback(
        (data: Partial<State>) =>
            setState({
                ...data,
                step: STEP_FORM,
            }),
        [],
    );
    const handleTemplateSelect = useCallback(
        (template: PaymentTemplate) =>
            setState({
                ...defaultParams,
                step: STEP_TEMPLATE_LOADER,
                dest: template.templateDetails.paymentDescription,
                sum: template.templateDetails.amount,
                receiverVoin: template.templateDetails.receiverVoin,
                receiverName: template.templateDetails.receiverName,
                receiverBankCode: template.templateDetails.receiverBankCode,
                iban: template.templateDetails.receiverIban,
                budgetLevelCode: template.templateDetails.budgetLevel?.code ?? null,
                budgetClassificatorCode: template.templateDetails.budgetClassificator?.code ?? null,
                template,
            }),
        [],
    );

    useEffect(() => {
        pageView({path: '/account', title: 'Transfer to Account'});
    }, []);

    return (
        <Box w={47} center>
            <StepController value={state.step} onChange={onChange} name="step">
                <Templates
                    id={STEP_TEMPLATES}
                    onCreateNew={() => setState({...defaultParams, step: STEP_RECEIVER})}
                    type={PAYMENT_TYPE}
                    onSelect={handleTemplateSelect}
                    onBack={() => navigate(dashboardLink())}
                />
                <TemplateLoader
                    data={state}
                    onSubmit={data => setState({...data, step: STEP_FORM})}
                    onBack={() => {
                        setState({...defaultParams, step: STEP_TEMPLATES});
                        navigate(link());
                    }}
                    id={STEP_TEMPLATE_LOADER}
                />
                <Receiver
                    data={state}
                    onSubmit={handleReceiverSubmit}
                    id={STEP_RECEIVER}
                    onBack={() => setState({step: STEP_TEMPLATES})}
                />
                <Form
                    id={STEP_FORM}
                    data={state}
                    onSubmit={handleFormSubmit}
                    onBack={() => {
                        return searchParams.get('t')
                            ? navigate(templates())
                            : setState({...defaultParams, iban: state.iban, step: STEP_RECEIVER});
                    }}
                />
                <Confirm
                    id={STEP_CONFIRM}
                    data={state}
                    onSubmit={handleConfirmSubmit}
                    onError={handleConfirmError}
                />
                <Result id={STEP_RESULT} data={state} />
            </StepController>
        </Box>
    );
};

export default TransferToAccount;
