import React, {useMemo} from 'react';
import {Response} from '@/features/Accounts/api/getStatement';
import Box from '@ff/web-components/components/Box';
import DateInput from '@ff/web-components/components/DateInput';
import Search from '@/components/Search';
import Options from '@ff/web-components/components/Options';
import Button from '@ff/web-components/components/Button';
import ExportIcon from '@ff/web-components/components/Icon/Download';
import OptionsItem from '@ff/web-components/components/Options/Item';
import Text from '@ff/web-components/components/Text';
import {getLocale, t} from '@ff/web-components/utils/l10n';
import stringify from '@ff/web-components/utils/number/stringify';
import useTheme from '@/hooks/useTheme';
import getTotalIncomeAndSpend from '@/features/Accounts/utils/statements/getTotalIncomeAndSpend';
import {State} from '@/features/Dashboard/statement';
import {OnChange} from '@/hooks/useForm';
import {Dictionary} from '@/locale';
import url from '@/api/url';
import {Response as BalanceResponse} from '@/features/Accounts/api/getBalance';
import moment from 'moment';
import useAccount from '@/hooks/useAccount';

interface IProps {
    collection?: Response['data'];
    state: State;
    onChange: OnChange;
    cardId?: BalanceResponse['data']['id'];
}

const StatementsFilter = ({collection, state, onChange, cardId}: IProps) => {
    const account = useAccount();
    const locale: Dictionary['statements'] = getLocale().statements;
    const {c} = useTheme();
    const totals = useMemo(
        () => (collection?.length ? getTotalIncomeAndSpend(collection) : null),
        [collection],
    );
    const handleDownload = (format: 'pdf' | 'xls' | 'csv') => {
        let startDate, endDate;
        if (state.date?.start && state.date?.end) {
            startDate = state.date.start.valueOf();
            endDate = state.date.end.valueOf();
        } else {
            startDate = moment().add(-6, 'M').startOf('day').valueOf();
            endDate = moment().valueOf();
        }

        window.open(
            url(
                `statements/file?type=business&cardId=${cardId}&startDate=${startDate}&endDate=${endDate}&fileFormat=${format}&enterpriseId=${account.id}`,
            ),
        );
    };

    return (
        <>
            <Box df>
                <Box w={27}>
                    <DateInput
                        value={state.date}
                        onChange={onChange}
                        period
                        name="date"
                        placeholder={locale.period}
                    />
                </Box>
                <Box fg={3} mx={2.5}>
                    <Search
                        name="search"
                        value={state.search}
                        onChange={onChange}
                        placeholder={locale.searchPlaceholder}
                    />
                </Box>
                <Box>
                    <Options
                        control={active => (
                            <Button
                                pressed={active}
                                secondary
                                small
                                startIcon={<ExportIcon color={c.palette.grey['600']} />}
                            >
                                {locale?.export}
                            </Button>
                        )}
                    >
                        <OptionsItem onClick={() => handleDownload('pdf')}>PDF</OptionsItem>
                        <OptionsItem onClick={() => handleDownload('xls')}>Excel</OptionsItem>
                        <OptionsItem onClick={() => handleDownload('csv')}>CSV</OptionsItem>
                    </Options>
                </Box>
            </Box>
            {collection?.length ? (
                <Box mt={2.5} df ai="center">
                    <Box mr={2.5} df ai="center">
                        <Text size="xs" color="secondary">
                            {t(locale?.total, {
                                n: String(collection.length),
                            })}
                        </Text>
                    </Box>
                    {totals ? (
                        <>
                            <Box mr={2.5} df ai="center">
                                <Text size="xs" color="secondary">
                                    {locale?.filterIncome} {stringify(totals.income, {toFixed: 2})}{' '}
                                    {collection[0].value.currencySymbol}
                                </Text>
                            </Box>
                            <Text size="xs" color="secondary">
                                {locale?.filterSpend} {stringify(totals.spend, {toFixed: 2})}{' '}
                                {collection[0].value.currencySymbol}
                            </Text>
                        </>
                    ) : null}
                </Box>
            ) : null}
        </>
    );
};

export default StatementsFilter;
