interface Options {
    fractionPointer?: string;
    thousandPointer?: string;
    toFixed?: number;
}

export default function stringify(value: number, options?: Options) {
    const {fractionPointer = '.', thousandPointer = ' ', toFixed} = options || {};
    const intArr: string[] = [];

    if (isNaN(value)) {
        return '';
    }

    let string = toFixed ? value.toFixed(toFixed) : String(value);

    if (fractionPointer) {
        string = string.replace(/\./, String(fractionPointer));
    }

    // Добавляем разделители для тысячных
    if (value >= 1000 || value <= -1000) {
        // выделяет целую часть числа из строки
        const intRegexp = new RegExp('([0-9]*)(\\' + fractionPointer + '|$)');
        const match = string.match(intRegexp);
        const intString = match ? match[1] : '';

        const length = intString.length * -1;
        let subPosition = 0,
            breaker = true,
            step = 3;

        /** перебор строки целого числа (123456) с конца, с шагом 3
         * если число кратное 3м, то последний проход цикла будет в холостую
         * в противном случае в последней итерации будет взят остаток
         * который остался после выбора всех трехзначных блоков
         */
        while (breaker) {
            subPosition -= 3;
            if (subPosition <= length) {
                step = length * -1 - intArr.length * 3;
                subPosition = length;
                breaker = false;
            }
            intArr.unshift(intString.substr(subPosition, step));
        }

        // целая часть числа заменяется новой с разделителями, добавляетя дробная часть
        string = string.replace(intRegexp, intArr.join(thousandPointer) + '$2');
    }

    return string;
}
