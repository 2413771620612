import {Theme} from 'themes/index';
import STATE from '../../../globals';

export default function (theme: Theme) {
    return {
        theme,
        fontFamily: theme.text?.fontFamily,
        fontSize: theme.text?.fontSize.xs,
        lineHeight: theme.text?.lineHeight.xs,
        padding: `${(theme.gutter ?? 0) * 1.25}px ${(theme.gutter ?? 0) * 2.5}px`,
        background: 'transparent',
        color: theme.palette?.text.secondary,
        borderRadius: '200px',
        [STATE.HOVER]: {
            background: theme.palette?.grey['200'],
        },
        [STATE.ACTIVE]: {
            background: theme.palette?.grey['400'],
        },
        selected: {
            background: theme.palette?.grey['200'],
        },
    };
}
