import React, {useRef} from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Item from '@/features/Dashboard/statement/list/item';
import Paper from '@ff/web-components/components/Paper';
import {Statement} from '@/features/Accounts/api/getStatement';
import PinnedHeading from '@/features/Dashboard/statement/list/pinnedHeading';
import {Response as PaymentsResponse} from '@/features/Accounts/api/getPayments';
import PaymentsList from '@/features/Dashboard/statement/payments/list';

interface IProps {
    list: Statement[];
    root: React.MutableRefObject<HTMLDivElement>;
    payments?: PaymentsResponse['data'];
}

const DateGroup = ({list, root, payments}: IProps) => {
    const group = useRef();
    const date = list[0].date.format('D MMMM YYYY');

    return (
        <div ref={group}>
            <PinnedHeading root={root} group={group} enabled={list.length > 1}>
                {date}
            </PinnedHeading>
            <Paper pt={2.5} pb={1} mt={2.5}>
                {payments?.length ? (
                    <Box mb={2.5}>
                        <PaymentsList collection={payments} />
                    </Box>
                ) : null}
                <Box pl={2.5}>
                    <Text size="xs3" color="secondary" tt="uppercase">
                        {date}
                    </Text>
                </Box>
                {list.map(item => (
                    <Item {...item} key={item.id} />
                ))}
            </Paper>
        </div>
    );
};

export default DateGroup;
