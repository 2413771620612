/*
 * Helper for carriage position management.
 * It works for cases with on fly formatting in inputs.
 * works only with formats like {{dd}} {{ww}}
 * */
export const getPosition = (value: string, selectionStart: number, allowedCharacters: RegExp) => {
    const formatted = value.substring(0, selectionStart);
    let filtered = '';
    for (let i = 0; i < formatted.length; i++) {
        if (allowedCharacters.test(formatted[i])) {
            filtered += formatted[i];
        }
    }
    return filtered.length;
};

export const setCarriage = (
    input: HTMLInputElement,
    value: string,
    position: number,
    allowedCharacters: RegExp,
) => {
    if (position && input && value) {
        let carriagePosition = 0;
        let digitCount = 0;

        for (let i = 0; i < value.length; i++) {
            if (position > digitCount) {
                carriagePosition += 1;
            }

            if (allowedCharacters) {
                if (allowedCharacters.test(value[i])) {
                    digitCount += 1;
                }
            } else {
                digitCount += 1;
            }
        }

        input.setSelectionRange(carriagePosition, carriagePosition);
    }
};
