import React from 'react';
import IBANInput, {IProps as IBANInputProps} from '../index';

export const IBAN_FORMAT = '{{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}} {{wwww}}';

const IBANInputAZ = React.forwardRef<HTMLInputElement, IBANInputProps>(
    ({country = 'AZ', ...rest}: IBANInputProps, ref) => {
        return (
            <IBANInput ref={ref} {...rest} maxLength={34} country={country} format={IBAN_FORMAT} />
        );
    },
);

IBANInputAZ.displayName = 'IBANInputAZ';

export default IBANInputAZ;
