import React from 'react';
import {Response} from '@/features/Accounts/api/getPayments';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Item from '@/features/Dashboard/statement/payments/item';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';

interface IProps {
    collection: Response['data'];
}

const PaymentsList = ({collection}: IProps) => {
    const locale: Dictionary['statements'] = getLocale().statements;

    return (
        <>
            <Box pl={2.5}>
                <Text size="xs3" color="secondary" tt="uppercase">
                    {locale.confirmation}
                </Text>
            </Box>
            {collection.map(item => (
                <Item {...item} key={item.id} />
            ))}
        </>
    );
};

export default PaymentsList;
