import React, {useCallback, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import defaultTheme from './theme';
import Calendar from './calendar';
import Box from '../Box';
import Button from '../Button';
import {getLocale} from 'utils/l10n';
import {
    DatePickerDayValue,
    DatePickerPeriodValue,
    DatePickerSelected,
    DatePickerSelectedDay,
    DatePickerSelectedPeriod,
    DatePickerValue,
} from './index';

interface ICalendar {
    startMonth?: number;
    startMonthExt?: number;
    startYear?: number;
    startYearExt?: number;
}

interface IProps {
    value?: DatePickerValue;
    onSubmit?: (value: DatePickerSelected) => void;
    onCancel?: () => void;
    minYear?: number;
    maxYear?: number;
    minMonth?: number;
    maxMonth?: number;
    allowedWeekDays?: number[];
    deniedDates?: string | string[] | moment.Moment | moment.Moment[];
    allowedDates?: string | string[] | moment.Moment | moment.Moment[];
    period?: boolean;
    extended?: boolean;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const now = moment();
const nextMonth = moment(now).startOf('month').add(1, 'month');

const allowedFormats = ['DD.MM.YYYY', 'DD.MM.YY'];

export const parseDate = (date: string): moment.Moment | null => {
    const list = [...allowedFormats];
    let d;

    while (!d || !d.isValid()) {
        if (list.length) {
            const format = list.shift();
            d = moment(date, format);
        } else {
            break;
        }
    }

    if (!d.isValid()) {
        d = moment(date);
    }

    return d.isValid() ? d : null;
};

const defaultCalendar = {
    startMonth: now.get('month'),
    startYear: now.get('year'),
    startMonthExt: nextMonth.get('month'),
    startYearExt: nextMonth.get('year'),
};

const getCalendar = (value, period) => {
    if (value) {
        if (period) {
            const newCalendar = {
                ...defaultCalendar,
            };

            if (value.start && value.end) {
                const s = moment(value.start).startOf('date');
                const e = moment(value.end).startOf('date');
                newCalendar.startMonth = s.get('month');
                newCalendar.startYear = s.get('year');
                newCalendar.startMonthExt = e.get('month');
                newCalendar.startYearExt = e.get('year');

                if (s.isSame(e, 'month')) {
                    const nm = moment(e).add(1, 'month');
                    newCalendar.startMonthExt = nm.get('month');
                    newCalendar.startYearExt = nm.get('year');
                }
            }

            return newCalendar;
        } else {
            const nextMonth = moment(value).add(1, 'month');
            return {
                startMonth: value.get('month'),
                startMonthExt: nextMonth.get('month'),
                startYear: value.get('year'),
                startYearExt: nextMonth.get('year'),
            };
        }
    } else {
        return defaultCalendar;
    }
};

const DateSelector = ({
    extended,
    onCancel,
    onSubmit,
    value,
    theme,
    period,
    minYear,
    maxYear,
    minMonth,
    maxMonth,
    allowedWeekDays,
    deniedDates,
    allowedDates,
}: IProps) => {
    const locale = getLocale().datePicker;
    const [selected, setSelected] = useState<DatePickerSelected>(
        period ? {start: null, end: null} : null,
    );
    const calendarMemo = useMemo(() => getCalendar(value, period), [value, period]);
    const [calendar, setCalendar] = useState<ICalendar>(calendarMemo);
    const additionalCalendar = typeof extended === 'boolean' ? extended : period;
    const sp = selected as DatePickerSelectedPeriod;
    const s = selected as DatePickerSelectedDay;
    const deniedDatesParsed = useMemo(() => {
        if (deniedDates) {
            const datesArray = Array.isArray(deniedDates) ? deniedDates : [deniedDates];
            return datesArray
                .map(date =>
                    moment.isMoment(date) && date.isValid()
                        ? date
                        : typeof date === 'string'
                        ? parseDate(date)
                        : null,
                )
                .filter(date => !!date);
        } else {
            return undefined;
        }
    }, [deniedDates]) as moment.Moment[];
    const allowedDatesParsed = useMemo(() => {
        if (allowedDates) {
            const datesArray = Array.isArray(allowedDates) ? allowedDates : [allowedDates];
            return datesArray
                .map(date =>
                    moment.isMoment(date) && date.isValid()
                        ? date
                        : typeof date === 'string'
                        ? parseDate(date)
                        : null,
                )
                .filter(date => !!date);
        } else {
            return undefined;
        }
    }, [allowedDates]) as moment.Moment[];
    const handleSubmit = useCallback(() => {
        if (typeof onSubmit === 'function') {
            onSubmit(selected);
        }
    }, [onSubmit, selected]);
    const handleSelect = useCallback(
        (value, unset, name) => {
            if (period) {
                const s = selected as DatePickerSelectedPeriod;
                let state;
                if (unset) {
                    state = {
                        ...s,
                        [name]: null,
                    };
                } else {
                    state = {
                        ...s,
                        [name]: value,
                    };
                }

                if (state.start && state.end) {
                    const memo = state.start;
                    if (state.start.isAfter(state.end)) {
                        state.start = state.end;
                        state.end = memo;
                    }
                }

                setSelected(state);
                if (!unset) {
                    let newCalendar = {...calendar};
                    if (!additionalCalendar) {
                        if (
                            name === 'start' &&
                            state.start &&
                            !state.start.isSame(newCalendar.startMonth, 'month')
                        ) {
                            newCalendar = {
                                ...newCalendar,
                                startMonth: state.start.get('month'),
                                startYear: state.start.get('year'),
                            };
                        } else if (
                            name === 'end' &&
                            state.end &&
                            !state.end.isSame(newCalendar.startMonth, 'month')
                        ) {
                            newCalendar = {
                                ...newCalendar,
                                startMonth: state.end.get('month'),
                                startYear: state.end.get('year'),
                            };
                        }
                    } else {
                        if (state.start && !state.start.isSame(newCalendar.startMonth, 'month')) {
                            newCalendar = {
                                ...newCalendar,
                                startMonth: state.start.get('month'),
                                startYear: state.start.get('year'),
                            };
                        }
                        if (state.end && !state.end.isSame(newCalendar.startMonthExt, 'month')) {
                            newCalendar = {
                                ...newCalendar,
                                startMonthExt: state.end.get('month'),
                                startYearExt: state.end.get('year'),
                            };
                        }
                    }
                    setCalendar(newCalendar);
                }
            } else {
                setSelected(unset ? null : value);
                if (!unset) {
                    setCalendar({
                        ...calendar,
                        startMonth: value.get('month'),
                        startYear: value.get('year'),
                    });
                }
            }
        },
        [selected, period, calendar],
    );

    useEffect(() => {
        if (period) {
            if (value !== null && typeof value === 'object') {
                const state: DatePickerSelected = {start: null, end: null};
                const v = value as DatePickerPeriodValue;
                if (v.start) {
                    if (typeof v.start === 'string') {
                        state.start = parseDate(v.start);
                    } else if (moment.isMoment(v.start)) {
                        state.start = v.start.isValid() ? v.start : null;
                    }
                }
                if (v.end) {
                    if (typeof v.end === 'string') {
                        state.end = parseDate(v.end);
                    } else if (moment.isMoment(v.end)) {
                        state.end = v.end.isValid() ? v.end : null;
                    }
                }
                setSelected(state);
                setCalendar(calendarMemo);
            } else {
                setSelected({start: null, end: null});
            }
        } else {
            let nextValue: DatePickerSelectedDay = null;
            const v = value as DatePickerDayValue;
            if (typeof v === 'string') {
                nextValue = parseDate(v);
            } else if (moment.isMoment(v)) {
                nextValue = v.isValid() ? v : null;
            }
            setSelected(nextValue);
            if (nextValue) {
                setCalendar(calendarMemo);
            }
        }
    }, [value]);

    return (
        <Box dif fd="column">
            <Box df mb={2}>
                <Calendar
                    theme={theme}
                    startMonth={calendar.startMonth as number}
                    startYear={calendar.startYear as number}
                    selected={selected}
                    onSelect={handleSelect}
                    period={period}
                    minYear={minYear}
                    maxYear={maxYear}
                    minMonth={minMonth}
                    maxMonth={maxMonth}
                    allowedWeekDays={allowedWeekDays}
                    deniedDates={deniedDatesParsed}
                    allowedDates={allowedDatesParsed}
                    inputValue={
                        period
                            ? sp.start
                                ? sp.start.format('DD.MM.YY')
                                : ''
                            : s
                            ? s.format('DD.MM.YY')
                            : ''
                    }
                    name={period && !additionalCalendar && sp.start ? 'end' : 'start'}
                />
                {additionalCalendar ? (
                    <Box ml={5}>
                        <Calendar
                            theme={theme}
                            startMonth={calendar.startMonthExt as number}
                            startYear={calendar.startYearExt as number}
                            selected={selected}
                            onSelect={handleSelect}
                            period={period}
                            minYear={minYear}
                            maxYear={maxYear}
                            minMonth={minMonth}
                            maxMonth={maxMonth}
                            allowedWeekDays={allowedWeekDays}
                            deniedDates={deniedDatesParsed}
                            allowedDates={allowedDatesParsed}
                            inputValue={period ? (sp.end ? sp.end.format('DD.MM.YY') : '') : ''}
                            name="end"
                        />
                    </Box>
                ) : null}
            </Box>
            <Box df>
                <Box mr={2.5}>
                    <Button
                        primary
                        onClick={handleSubmit}
                        disabled={period ? !sp.start || !sp.end : !s}
                    >
                        {locale?.submit}
                    </Button>
                </Box>
                <Button secondary onClick={onCancel}>
                    {locale?.cancel}
                </Button>
            </Box>
        </Box>
    );
};

export default DateSelector;
