'use client';
'use strict';
import React, {useId} from 'react';
import theme from '../theme';
import defaultTheme from './theme';
import useMergeTheme from '../../hooks/useMergeTheme';
import Input from './style/input';
import Icon from './style/icon';
import Box from '../Box';
import Label from './style/label';
import {DeepPartial} from 'themes/index';

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'style'> {
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    className?: string;
    checked: boolean;
    partial?: boolean;
    disabled?: boolean;
    id?: string;
    children?: React.ReactNode;
}

const Checkbox = React.forwardRef<HTMLInputElement, IProps>(
    ({style, theme, className, checked, partial, id, disabled, children, ...rest}: IProps, ref) => {
        const computedTheme = useMergeTheme(theme, style);
        const innerId = useId();

        return (
            <div className={className}>
                <Box df ai="flex-start">
                    <Icon $theme={computedTheme} htmlFor={id || innerId} $disabled={disabled}>
                        {React.createElement(
                            checked
                                ? partial
                                    ? computedTheme.partialCheckedIcon
                                    : computedTheme.checkedIcon
                                : computedTheme.uncheckedIcon,
                            {
                                width: computedTheme.theme.gutter * 3 + 'px',
                                height: computedTheme.theme.gutter * 3 + 'px',
                                color: checked
                                    ? computedTheme.theme.palette.background.accent
                                    : undefined,
                            },
                        )}
                    </Icon>
                    <Input
                        type="checkbox"
                        ref={ref}
                        {...rest}
                        id={id || innerId}
                        checked={checked}
                        disabled={disabled}
                    />
                    {children ? (
                        <Box ml={1}>
                            <Label htmlFor={id || innerId} $theme={computedTheme}>
                                {children}
                            </Label>
                        </Box>
                    ) : null}
                </Box>
            </div>
        );
    },
);

Checkbox.displayName = 'Checkbox';

export default theme<IProps, HTMLInputElement>(Checkbox, 'checkbox', 'Checkbox');
