import React from 'react';
import Paper from '@ff/web-components/components/Paper';
import Item from './item.skeleton';

const ListSkeleton = () => (
    <Paper py={2.5} pb={1} mt={2.5}>
        <Item />
        <Item />
        <Item />
    </Paper>
);

export default ListSkeleton;
