'use client';
'use strict';
import React, {Ref, useCallback, useEffect, useRef, useState} from 'react';
import useEventListener from 'react-use-event-listener';
import ownerDocument from 'utils/ownerDocument';

export interface IProps {
    children?: React.ReactNode | undefined;
    mouseEvent?: 'onClick' | 'onMouseDown' | 'onMouseUp' | false;
    touchEvent?: 'onTouchStart' | 'onTouchEnd' | false;
    node?: HTMLElement | Ref<HTMLElement>;
    onClickAway?: (e) => void;
}

const noReaction = () => {
    return undefined;
};

const ClickAwayListener: React.FC<IProps> = ({
    children,
    mouseEvent,
    touchEvent,
    node,
    onClickAway,
}: IProps) => {
    const moved = useRef(false);
    const [targetNode, setNode] = useState<null | HTMLElement>(
        node instanceof HTMLElement ? node : null,
    );
    const handleTouchMove = useCallback(() => (moved.current = true), []);
    const handleClickAway = useCallback(
        event => {
            const doc = ownerDocument(targetNode);

            // Ignore events that have been `event.preventDefault()` marked.
            if (event.defaultPrevented) {
                return;
            }

            // Do not act if user performed touchmove
            if (moved.current) {
                moved.current = false;
                return;
            }

            if (
                doc &&
                typeof onClickAway === 'function' &&
                doc.documentElement &&
                doc.documentElement.contains(event.target) &&
                targetNode &&
                !targetNode.contains(event.target)
            ) {
                onClickAway(event);
            }
        },
        [targetNode, onClickAway],
    );

    useEffect(() => {
        if (node) {
            if (node instanceof HTMLElement) {
                if (node !== targetNode) {
                    setNode(node);
                }
            } else if (typeof node === 'object' && node.current) {
                setNode(node.current);
            }
        }
    }, [node]);

    useEventListener(
        mouseEvent === 'onClick'
            ? 'click'
            : mouseEvent === 'onMouseUp'
            ? 'mouseup'
            : mouseEvent === 'onMouseDown'
            ? 'mousedown'
            : 'click',
        mouseEvent !== false ? handleClickAway : noReaction,
        ownerDocument(targetNode) ?? undefined,
    );

    useEventListener(
        touchEvent === 'onTouchStart'
            ? 'touchstart'
            : touchEvent === 'onTouchEnd'
            ? 'touchend'
            : 'touchstart',
        touchEvent !== false ? handleClickAway : noReaction,
        ownerDocument(targetNode) ?? undefined,
    );

    useEventListener(
        'touchmove',
        touchEvent !== false ? handleTouchMove : noReaction,
        ownerDocument(targetNode) ?? undefined,
    );

    return <>{children}</>;
};

ClickAwayListener.defaultProps = {
    mouseEvent: 'onMouseUp',
    touchEvent: 'onTouchEnd',
};

export default ClickAwayListener;
