import moment from 'moment/moment';
import {getLocale, placeholder} from 'utils/l10n';
import {DatePickerPeriodValue} from '../../DatePicker';
import {displayAlias as quarterAlias} from '../../DatePicker/shortcut/quarter';
import capitalize from 'utils/capitalize';

const now = moment();

export default function stringify(value: DatePickerPeriodValue) {
    const locale = getLocale().datePicker;
    let result = '';
    value.start = moment.isMoment(value.start) ? value.start : moment(value.start);
    value.end = moment.isMoment(value.end) ? value.end : moment(value.end);

    if (value.start.isSame(value.end, 'date')) {
        if (value.start.isSame(now, 'date')) {
            result = locale?.today;
        } else if (value.start.isSame(moment(now).add(-1, 'day'), 'date')) {
            result = locale?.yesterday;
        } else {
            result = value.start.format('DD.MM.YY');
        }
    } else if (
        moment().startOf('week').isSame(value.start, 'date') &&
        moment().isSame(value.end, 'date')
    ) {
        result = locale?.week;
    } else if (
        moment().add(-1, 'week').startOf('week').isSame(value.start, 'date') &&
        moment().add(-1, 'week').endOf('week').isSame(value.end, 'date')
    ) {
        result = locale?.prevWeek;
    } else if (
        value.start.isSame(value.end, 'month') &&
        value.start.date() === 1 &&
        value.end.date() === value.end.daysInMonth()
    ) {
        result = capitalize(value.start.format('MMMM'));
    } else if (
        value.start.isSame(value.end, 'quarter') &&
        value.start.isSame(moment(value.start).startOf('quarter'), 'date') &&
        value.end.isSame(moment(value.end).endOf('quarter'), 'date')
    ) {
        result = value.start.isSame(now, 'quarter')
            ? locale?.currentQuarter
            : placeholder(locale?.selectedQuarter, {
                  q: quarterAlias[value.start.get('quarter')],
                  y: String(value.start.get('year')),
              });
    } else if (
        value.start.isSame(value.end, 'year') &&
        value.start.isSame(moment(value.start).startOf('year'), 'date') &&
        value.end.isSame(moment(value.end).endOf('year'), 'date')
    ) {
        result =
            value.start.get('year') === now.get('year')
                ? locale?.currentYear
                : placeholder(locale?.selectedYear, {n: String(value.start.get('year'))});
    } else {
        result = `${value.start.format('DD.MM.YY')} - ${value.end.format('DD.MM.YY')}`;
    }

    return result;
}
