import styled from 'styled-components';

interface CloseActionRootProps {
    $theme;
}

const CloseActionRoot = styled.div<CloseActionRootProps>`
    transition-property: opacity;
    cursor: pointer;
    ${({$theme}) => ({
        opacity: 0.2,
        transitionDuration: $theme.transition?.duration,
        transitionTimingFunction: $theme.transition?.timingFunction,
        '&:hover': {
            opacity: 0.5,
        },
    })}
`;

export default CloseActionRoot;
