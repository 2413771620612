import React from 'react';
import Box from '@ff/web-components/components/Box';
import Skeleton from '@ff/web-components/components/Skeleton';
import TextSkeleton from '@/components/TextSkeleton';

const TemplateSkeleton = () => {
    return (
        <Box py={1.25} df ai="center">
            <Box fs={0} mr={2.5} df ai="center">
                <Skeleton w={5} h={5} />
            </Box>
            <div>
                <TextSkeleton w={10} />
                <TextSkeleton size="xs" w={20} />
            </div>
        </Box>
    );
};

export default TemplateSkeleton;
