'use client';
'use strict';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import Button from '../Button';
import Calendar from '../Icon/Calendar';
import theme from '../theme';
import Overlay from '../Overlay';
import Box from '../Box';
import DatePicker, {DatePickerValue, IProps as DatePickerProps} from '../DatePicker';
import ClickAwayListener from '../ClickAwayListener';
import makeEvent, {SyntheticEvent} from 'utils/syntheticEvent';
import moment from 'moment';
import stringify from './utils/stringify';

export interface IProps
    extends Omit<DatePickerProps, 'onCancel' | 'onSubmit'>,
        React.HTMLAttributes<HTMLDivElement> {
    name?: string;
    placeholder?: string;
    onChange: (e: SyntheticEvent) => void;
    value: DatePickerValue;
}

const DateInput = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            placeholder,
            theme,
            onChange,
            name,
            value,
            allowedDates,
            deniedDates,
            allowedWeekDays,
            exclude,
            period,
            maxYear,
            maxMonth,
            minYear,
            minMonth,
            extended,
            ...rest
        }: IProps,
        ref,
    ) => {
        const [open, setOpen] = useState(false);
        const root = useRef<null | HTMLDivElement>(null);
        const handleCancel = () => setOpen(false);
        const handleSubmit = useCallback(
            nextValue => {
                if (typeof onChange === 'function') {
                    onChange(makeEvent({name, value: nextValue}));
                }
                handleCancel();
            },
            [onChange, name],
        );
        const valueString = useMemo(() => {
            if (value) {
                if (moment.isMoment(value)) {
                    return value.format('DD.MM.YY');
                } else if (typeof value === 'object' && value.start && value.end) {
                    return stringify(value);
                } else if (typeof value === 'string') {
                    return value;
                }
            } else {
                return placeholder;
            }
        }, [value, placeholder]);

        return (
            <Box pos="relative" ref={ref || root} {...rest}>
                <Button secondary small onClick={() => setOpen(!open)} wide pressed={open}>
                    <Box df ai="center" w="100%">
                        <Box mr={1}>
                            <Calendar color={theme?.theme?.palette?.grey['600']} />
                        </Box>
                        {valueString}
                    </Box>
                </Button>
                <Overlay active={open} offsetTop={8}>
                    <DatePicker
                        allowedDates={allowedDates}
                        deniedDates={deniedDates}
                        allowedWeekDays={allowedWeekDays}
                        exclude={exclude}
                        period={period}
                        maxYear={maxYear}
                        maxMonth={maxMonth}
                        minYear={minYear}
                        minMonth={minMonth}
                        extended={extended}
                        onCancel={handleCancel}
                        onSubmit={handleSubmit}
                        value={value}
                    />
                </Overlay>
                <ClickAwayListener node={ref || root} onClickAway={handleCancel} />
            </Box>
        );
    },
);

DateInput.displayName = 'DateInput';

export default theme<IProps, HTMLDivElement>(DateInput, 'datePicker', 'DateInput');
