import styled from 'styled-components';

interface IProps {
    $visible: boolean;
    $width: number;
    $left: number;
}

const PinnedHeadingRoot = styled.div<IProps>`
    position: fixed;
    top: 0;
    ${({$visible, $width, $left}) => ({
        display: $visible ? 'block' : 'none',
        width: $width + 'px',
        left: $left + 'px',
    })}}
`;

export default PinnedHeadingRoot;
