import runtime from '@/store/runtime';
import {IData, toSearchString} from '@/utils/url';

export interface SearchParams {
    edit?: boolean;
    t?: string;
}

const link = (search?: SearchParams) => {
    const queryParams = search ? toSearchString(search as IData) : '';

    return `/u/${runtime.get('ALIAS')}/${runtime.get('ENTERPRISE')}/transfer/account${
        queryParams ? '?' + queryParams : ''
    }`;
};

export default link;
