import React, {useCallback} from 'react';
import {Template as ITemplate} from '../api/getList';
import {PAYMENT_TYPE as TAX} from '@/features/TransferToTaxService/constants';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Root from '@/features/Templates/template/style/root';
import taxService from '@/features/TransferToTaxService/img/service.svg';
import {PAYMENT_TYPE as ACCOUNT} from '@/features/TransferToAccount/constants';
import accountService from '@/features/TransferToAccount/img/service.svg';
import Receiver from '@/features/Templates/template/style/receiver';
import Description from '@/features/Templates/template/style/description';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import {get} from '@/store/runtime';

interface IProps {
    onClick?: (template?: ITemplate) => void;
    template: ITemplate;
}

const serviceImages = {
    [TAX]: taxService,
    [ACCOUNT]: accountService,
};

const Template = ({onClick, template}: IProps) => {
    const img =
        (template.img ? categoryIcon(template.img, get('ENTERPRISE')) : null) ||
        serviceImages[template.type];
    const handleClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick(template);
        }
    }, [template, onClick]);

    return (
        <Root>
            <Box py={1.25} df ai="center" onClick={handleClick}>
                <Box fs={0} mr={2.5} df ai="center" rad="50%" of="hidden">
                    <img src={img} width="40" height="40" alt={template.title} />
                </Box>
                <Box w="calc(100% - 60px)" df fd="column">
                    <Receiver>{template.title}</Receiver>
                    {template.subTitle ? (
                        <Text size="xs" color="secondary">
                            <Description>{template.subTitle}</Description>
                        </Text>
                    ) : null}
                </Box>
            </Box>
        </Root>
    );
};

export default Template;
