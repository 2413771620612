import styled from 'styled-components';

interface RootProps {
    $theme;
    $checked;
    $disabled;
}

const Root = styled.label<RootProps>`
    display: block;
    user-select: none;
    ${({$theme, $checked, $disabled}) => {
        const {theme, checked, ...rest} = $theme;

        return {
            ...rest,
            transitionProperty: 'color, background',
            transitionDuration: theme.transition.duration,
            transitionTimingFunction: theme.transition.timingFunction,
            ...($checked ? checked : {}),
            cursor: $disabled ? 'not-allowed' : $checked ? 'default' : 'pointer',
        };
    }}
`;

export default Root;
