const preventDefault = () => {
    return undefined;
};
const isDefaultPrevented = () => false;
const isPropagationStopped = () => false;
const stopPropagation = () => {
    return undefined;
};

const syntheticEvent = (target, options?) => {
    return {
        target,
        currentTarget: target,
        preventDefault,
        stopPropagation,
        type: options?.type || 'change',
        bubbles: false,
        cancelable: false,
        defaultPrevented: false,
        isTrusted: true,
        isDefaultPrevented,
        isPropagationStopped,
        timeStamp: new Date().getTime(),
        nativeEvent: {
            target,
            currentTarget: target,
            type: options?.type || 'change',
        },
    };
};

export type SyntheticEvent = ReturnType<typeof syntheticEvent>;

export default syntheticEvent;
