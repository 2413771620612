import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '../index';
import dashboardLink from '@/features/Dashboard/makeLink';
import {getLocale, placeholder} from '@ff/web-components/utils/l10n';
import {useNavigate} from 'react-router-dom';
import useToggle from '@/hooks/useToggle';
import useForm from '@/hooks/useForm';
import useValidation from '@/hooks/useValidation';
import required from '@/hooks/useValidation/required';
import Layout from '../layout';
import Box from '@ff/web-components/components/Box';
import SumInput from '@ff/web-components/components/Input/Sum';
import {findError, ResponseError} from '@/api/protocol';
import FieldError from '@/components/FieldError';
import GradientSpinner from '@/components/GradientSpinner';
import Text from '@ff/web-components/components/Text';
import stringify from '@ff/web-components/utils/number/stringify';
import LoadingButton from '@/components/LoadingButton';
import {useMutation, useQuery} from '@tanstack/react-query';
import getBalance, {QUERY_KEY} from '@/features/Accounts/api/getBalance';
import saveForm, {Params, Response as SaveResponse} from '../api/saveForm';
import parse from '@ff/web-components/utils/number/parse';
import getCard from '@/features/TransferToMyCard/api/getCard';
import getCommission from '@/features/TransferToMyCard/api/getCommission';
import {Dictionary} from '@/locale';
import sumMinmax from '@/hooks/useValidation/sumMinmax';
import FormError from '@/components/FormError';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: SaveResponse['response']) => void;
}

const Form = ({data, onSubmit}: IProps) => {
    const fetchCommissionTimeout = useRef<ReturnType<typeof setTimeout>>();
    const locale = getLocale();
    const formLocale: Dictionary['toCard']['form'] = locale.toCard.form;
    const navigate = useNavigate();
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange] = useForm(data);
    const [valid, errors] = useValidation(state, required(['sum']), sumMinmax(['sum'], 0.01));
    const sumNumber = parse(state.sum);
    const getBalanceReq = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
    });
    const getCardReq = useQuery({
        queryKey: ['/cards/getCard'],
        queryFn: () => getCard(),
    });
    const saveReq = useMutation<SaveResponse, ResponseError, Params>({
        mutationFn: params => saveForm(params),
    });
    const getCommissionReq = useQuery({
        queryKey: ['/cards/my/fee'],
        queryFn: () =>
            getCommission({
                amount: sumNumber,
            }),

        enabled: false,
    });
    const handleSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!valid) {
                toggleValidation(true);
            } else {
                saveReq.mutate({
                    amount: sumNumber,
                });
            }
        },
        [state, valid],
    );
    const apiErrors = useMemo(() => {
        return saveReq.error
            ? {
                  global: findError(saveReq.error),
                  amount: findError(saveReq.error, 'amount'),
              }
            : {};
    }, [saveReq.isError]);

    useEffect(() => {
        if (saveReq.isSuccess) {
            onSubmit(saveReq.data.response);
        }
    }, [saveReq.isSuccess]);

    useEffect(() => {
        if (sumNumber > 0) {
            clearTimeout(fetchCommissionTimeout.current);
            fetchCommissionTimeout.current = setTimeout(() => getCommissionReq.refetch(), 300);
        }
    }, [state.sum]);

    return (
        <Layout
            onBack={() => navigate(dashboardLink())}
            title={placeholder(formLocale.title, {
                n: getCardReq.data?.response.maskedPan || '',
            })}
        >
            {apiErrors.global ? <FormError error={[apiErrors.global]} /> : null}
            <form action="/" method="post" onSubmit={handleSubmit}>
                <Box mb={1.25}>
                    <SumInput
                        name="sum"
                        value={state.sum}
                        onChange={onChange}
                        label={formLocale.sum}
                        maxLength={10}
                        error={!!apiErrors.amount || !!(validation && errors.sum?.message)}
                    />
                    {apiErrors.amount || (validation && errors.sum?.message) ? (
                        <FieldError>
                            {apiErrors.amount?.message || (validation && errors.sum?.message)}
                        </FieldError>
                    ) : null}
                </Box>
                <Box mb={3}>
                    {getBalanceReq.isLoading ? (
                        <GradientSpinner size={2} />
                    ) : getBalanceReq.isSuccess ? (
                        <Text size="xs3" color="secondary" align="left">
                            {formLocale.balance}{' '}
                            {stringify(getBalanceReq.data?.data.balances.amount)}{' '}
                            {getBalanceReq.data?.data.displayedInfo.currencySymbol}
                        </Text>
                    ) : null}
                    {sumNumber ? (
                        <Text size="xs3" color="secondary" align="left">
                            <Box df ai="center">
                                {!getCommissionReq.isFetched || getCommissionReq.isLoading ? (
                                    <GradientSpinner size={2} />
                                ) : getCommissionReq.isSuccess ? (
                                    <span>{getCommissionReq.data?.response?.fee?.sender}</span>
                                ) : (
                                    '-'
                                )}
                            </Box>
                        </Text>
                    ) : null}
                </Box>
                <LoadingButton primary wide type="submit" loading={saveReq.isPending}>
                    {formLocale.submit}
                </LoadingButton>
            </form>
        </Layout>
    );
};

export default Form;
