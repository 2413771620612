import React, {useEffect, useRef} from 'react';
import defaultTheme from '../theme';
import moment from 'moment/moment';
import Shortcut from '../style/shortcut';
import Text from '../../Text';
import Root from '../style/side';
import {getLocale, placeholder} from 'utils/l10n';

interface IProps {
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    min: number;
    max: number;
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
}
const currentYear = moment().get('year');

const Years = ({min, max, onSelect, theme}: IProps) => {
    const locale = getLocale().datePicker;
    const root = useRef<null | HTMLDivElement>(null);
    const content: any = [];
    for (let i = max; i >= min; i--) {
        const year = moment().year(i).startOf('year');

        content.push(
            <Shortcut
                key={i}
                onClick={() => onSelect(year, moment(year).endOf('year'))}
                $theme={theme}
                data-scroll-here={currentYear === year.get('year') ? '1' : undefined}
            >
                <Text size="m" color="primary">
                    {currentYear === year.get('year')
                        ? locale?.currentYear
                        : placeholder(locale?.selectedYear, {n: year.format('YYYY')})}
                </Text>
            </Shortcut>,
        );
    }

    useEffect(() => {
        const scrollToEl = root.current?.querySelector('[data-scroll-here]');
        if (scrollToEl) {
            scrollToEl.scrollIntoView({block: 'center'});
        }
    }, []);

    return (
        <Root $theme={theme} ref={root}>
            {content}
        </Root>
    );
};

export default Years;
