import React, {useCallback, useEffect, useRef, useState} from 'react';
import Content from './style/content';
import Login from './login';
import {
    STEP_APP_CONFIRM,
    STEP_LOGIN,
    STEP_REJECT,
    STEP_SMS,
    STEP_SUCCESS,
} from '@/features/Auth/constants';
import App from './app';
import SMS from './sms';
import Reject from './reject';
import {useMutation, useQuery} from '@tanstack/react-query';
import init, {QROptions, Response} from '@/features/Auth/api/init';
import {ResponseError} from '@/api/protocol';
import Success from './success';
import Skeleton from './login/skeleton';
import queryClient from '@/api/queryClient';
import status, {Response as StatusResponse} from '@/features/Auth/api/status';
import {IVROptions} from '@/features/Auth/api/sendLogin';
import useFP from '@/hooks/useFP';
import fingerprint from '@/features/Auth/api/fingerprint';
import runtime from '@/store/runtime';
import {set as setCsrfToken} from '@/features/Application/reducers/csrfToken';
import {useDispatch} from 'react-redux';
import Box from '@ff/web-components/components/Box';
import GeneralError from '@/components/GeneralError';
import {Dictionary} from '@/locale';
import {getLocale} from '@ff/web-components/utils/l10n';
import {pageView} from '@/utils/gtag';

const steps = {
    [STEP_LOGIN]: Login,
    [STEP_APP_CONFIRM]: App,
    [STEP_SMS]: SMS,
    [STEP_REJECT]: Reject,
    [STEP_SUCCESS]: Success,
};

export type Step =
    | typeof STEP_LOGIN
    | typeof STEP_APP_CONFIRM
    | typeof STEP_SMS
    | typeof STEP_REJECT
    | typeof STEP_SUCCESS;

export interface IState {
    result: Step;
    content?: {
        title: string;
        subtitle?: string;
    };
    key?: string;
    csrfToken?: string;
    options?: {
        qr?: QROptions;
        ivr?: IVROptions;
        login?: string;
    };
}

export type onBack = () => void;
export type onSubmit = (step: IState) => void;

const Auth = () => {
    const locale: Dictionary['app'] = getLocale().app;
    const dispatch = useDispatch();
    const [step, setStep] = useState<IState>();
    const Component = steps[step?.result];
    const fpReq = useMutation({
        mutationKey: ['auth/fp'],
        mutationFn: fingerprint,
    });
    const initReq = useQuery<Response, ResponseError>({
        queryKey: ['auth/init'],
        queryFn: init,
    });
    const handleBack = useCallback(() => initReq.refetch(), []);
    const statusReq = useQuery<StatusResponse, ResponseError>({
        queryKey: ['auth/status'],
        queryFn: status,
        enabled: false,
    });
    const reqTimeout = useRef<ReturnType<typeof setTimeout>>();
    const fp = useFP();

    useEffect(() => {
        if (initReq.isFetched && initReq.isSuccess && steps[initReq.data.result]) {
            if (initReq.data.csrfToken) {
                dispatch(setCsrfToken(initReq.data.csrfToken));
            }
            runtime.set('ALIAS', initReq.data.alias);
            setStep(initReq.data);

            clearTimeout(reqTimeout.current);
            reqTimeout.current = setTimeout(() => statusReq.refetch(), 1000);
        }
        // fetch + refetch
    }, [initReq.isFetching]);

    useEffect(() => {
        if (initReq.isSuccess && !statusReq.isFetching) {
            if (statusReq.data?.result && statusReq.data.result !== step?.result) {
                setStep(statusReq.data);
            } else {
                reqTimeout.current = setTimeout(() => statusReq.refetch(), 1000);
            }
        }
    }, [statusReq.isFetching]);

    useEffect(() => {
        pageView({path: '/auth', title: 'Auth'});
        return () => {
            queryClient.removeQueries({queryKey: ['auth/init']});
            queryClient.removeQueries({queryKey: ['auth/status']});
            clearTimeout(reqTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (fp && initReq.data?.csrfToken) {
            fpReq.mutate({fingerprint: fp});
        }
    }, [fp, initReq.data?.csrfToken]);

    return (
        <Content>
            {Component ? (
                <Component
                    onSubmit={step => setStep(step)}
                    options={step?.options}
                    onBack={handleBack}
                    reqKey={step?.key}
                    content={step?.content}
                />
            ) : initReq.isLoading || initReq.isPending ? (
                <Skeleton />
            ) : initReq.isError ? (
                <Box mt={14}>
                    <GeneralError
                        title={locale.error.title}
                        subTitle={locale.error.subTitle}
                        onRetry={() => initReq.refetch()}
                    />
                </Box>
            ) : null}
        </Content>
    );
};

export default Auth;
