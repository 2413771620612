import axios from 'axios';
import {Step} from '@/features/Auth';
import url from '@/api/url';

export interface Response {
    result: Step;
}

export interface RequestData {
    otp: string;
    key: string;
}

export default async function sendLogin(payload: RequestData): Promise<Response> {
    return axios.post(url('auth/confirm-otp'), payload);
}
