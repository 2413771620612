/**
 * форматирует строку
 * @param string
 * @param format: static text {{www}} {{dd}}; w - chars, d - digits
 * @returns {string}
 */

const // начало блока подстановки значений
    enterReplace = /[{]/i,
    // конец блока подстановки значений
    exitReplace = /[}]/i,
    digitTest = /^[0-9]$/,
    wordTest = /^\S$/;

export default function (string: string, format: string): string {
    string = string === undefined || string === null ? '' : String(string);

    if (!format) return string;

    const len = format.length;
    let i = 0,
        j = 0,
        result = '',
        pattern,
        subject,
        replacementMode = false;

    for (i; i < len; i++) {
        pattern = format[i];
        subject = string[j];

        if (!subject) {
            break;
        }

        if (enterReplace.test(pattern)) {
            replacementMode = true;
            continue;
        }

        if (exitReplace.test(pattern)) {
            replacementMode = false;
            continue;
        }

        // start listening for keywords (d | w)
        if (replacementMode) {
            // замена числового значения
            if (pattern === 'd' && digitTest.test(subject)) {
                result += subject;
                j++;

                // замена не числового значения
            } else if (pattern === 'w' && wordTest.test(subject)) {
                result += subject;
                j++;

                // если символ не подошел к шаблону - пропускаем его
                // шаблон остается прежним
            } else {
                i--;
                j++;
            }
        } else {
            // if we have already formatted part outside of replacement zone
            if (pattern === subject) {
                result += subject;
                j++;
            } else {
                result += pattern;
            }
        }
    }

    return result.trim();
}
