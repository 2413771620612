import {createGlobalStyle} from 'styled-components';

const Global = createGlobalStyle<{$scrollWidth: number; $paddingRight: string}>`
    body {
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        ${({$scrollWidth, $paddingRight}) => ({
            paddingRight: $paddingRight || $scrollWidth,
        })}
    }
`;

export default Global;
