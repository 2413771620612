import {useReducer} from 'react';

const reducer = (state: boolean, action?: boolean) => {
    return typeof action === 'boolean' ? action : !state;
};

// Hook for switching boolean state of something.
// Passing an action with bool will turn "true" or "false" state value
const useToggle = (initialState = false): [boolean, (value?: boolean) => void] => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return [state, dispatch];
};

export default useToggle;
