import {Template as ITemplate} from '@/features/Templates/api/getList';
import escapeRegExp from '@/utils/escapeRegExp';

export default function search(list: ITemplate[], query: string) {
    let result = list;
    query = query?.trim();

    if (query) {
        result = list.filter(({title, subTitle}) => {
            const reg = new RegExp(
                `(${query
                    .split(' ')
                    .map(t => t.trim())
                    .filter(t => !!t)
                    .map(t => escapeRegExp(t))
                    .join('|')})`,
                'gi',
            );
            return reg.test(title) || reg.test(subTitle);
        });
    }

    return result;
}
