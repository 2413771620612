let store: Store = {};

export interface Store {
    ALIAS?: string;
    ENTERPRISE?: string;
    LANG?: string;
    TOKEN?: string;
}

type Value = Store[keyof Store];

export function set(name: keyof Store | Partial<Store>, value?: Value): Store {
    if (name && typeof name === 'object') {
        store = {...store, ...name};
    } else if (name && typeof name === 'string') {
        store[name] = value;
    }

    return store;
}

export function get(name: keyof Store) {
    return store[name];
}

export default {
    set,
    get,
};
