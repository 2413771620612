import {createSlice} from '@reduxjs/toolkit';

const fp = createSlice({
    name: 'fp',
    initialState: null,
    reducers: {
        set(state, action) {
            if (action.payload !== state) {
                return action.payload;
            } else {
                return state;
            }
        },
    },
});

export const {set} = fp.actions;
export default fp.reducer;
