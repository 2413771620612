import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Logout: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15.5 3h-13v18h13v-4h-2v2h-9V5h9v2h2V3Z" fill={color} />
                <path
                    d="m18.086 13-1.293 1.293 1.414 1.414L21.914 12l-3.707-3.707-1.414 1.414L18.086 11H9v2h9.086Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Logout, 'icon', 'Logout');
