import React, {useCallback} from 'react';
import syntheticEvent, {SyntheticEvent} from '@ff/web-components/utils/syntheticEvent';

type Value = string | number;

export interface IProps {
    value: Value;
    onChange: (e: SyntheticEvent) => void;
    children?: React.ReactElement<StepProps> | React.ReactElement<StepProps>[];
    name?: string;
}

export type StepProps = {
    toNextStep?: () => void;
    toPrevStep?: () => void;
    toStep?: (step: string) => void;
    activeStepIndex?: number;
    stepCount?: number;
    id: Value;
};

const getChild = (
    id: Value,
    children: React.ReactElement<StepProps> | React.ReactElement<StepProps>[],
): [React.ReactElement | null, number, number] => {
    const list = children ? React.Children.toArray(children) : [];
    let result: React.ReactElement | null = null;
    let index = -1;

    if (list?.length) {
        result = list.find(
            (child: React.ReactElement) => child?.props?.id === id,
        ) as React.ReactElement;
        index = list.indexOf(result);
    }

    return [result, index, list?.length || 0];
};

const getNext = (value: Value, children: React.ReactElement | React.ReactElement[]) => {
    let result = null;

    if (value && children) {
        const list = React.Children.toArray(children) as React.ReactElement[],
            current = list.find((child: React.ReactElement) => child?.props?.id === value),
            currentIndex = list.indexOf(current),
            rest = list.slice(currentIndex + 1),
            nextStep: React.ReactElement = rest.find(
                (child: React.ReactElement) => !!child?.props?.id,
            );

        if (nextStep) {
            result = nextStep.props.id;
        }
    }

    return result;
};

const getPrev = (value: Value, children: React.ReactElement | React.ReactElement[]) => {
    let result = null;

    if (value && children) {
        const list = React.Children.toArray(children) as React.ReactElement[],
            current = list.find((child: React.ReactElement) => child?.props?.id === value),
            currentIndex = list.indexOf(current),
            prev = list.slice(0, currentIndex).reverse(),
            prevStep = prev.find((child: React.ReactElement) => !!child?.props?.id);

        if (prevStep) {
            result = prevStep.props.id;
        }
    }

    return result;
};

const StepController = ({value, onChange, children, name}: IProps) => {
    const toStep = useCallback(
        (nextValue: Value) => {
            if (typeof onChange === 'function') {
                onChange(
                    syntheticEvent({
                        value: nextValue,
                        name,
                    }),
                );
            }
        },
        [onChange],
    );
    const toNextStep = useCallback(() => toStep(getNext(value, children)), [value]);
    const toPrevStep = useCallback(() => toStep(getPrev(value, children)), [value]);

    if (!children) {
        /* eslint-env node */
        if (process.env.NODE_ENV !== 'production') {
            window.console.error(`Creating StepController without steps.`);
        }
    }

    const [activeStep, activeStepIndex, stepCount] = getChild(value, children);

    if (activeStep) {
        return React.cloneElement(activeStep, {
            toNextStep,
            toPrevStep,
            toStep,
            activeStepIndex,
            stepCount,
        });
    } else {
        return null;
    }
};

export default StepController;
