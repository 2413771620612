import {createContext} from 'react';
import {IProps as SnackProps} from './snack';

export interface SnackBarContextValue {
    add: (id: string, args: SnackProps) => void;
    remove: (id: string) => void;
}

export default createContext<SnackBarContextValue>({
    add: () => {
        return undefined;
    },
    remove: () => {
        return undefined;
    },
});
