import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {StepProps} from '@/components/StepController';
import Layout from '@/features/TransferToTaxService/layout';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getLocale, placeholder} from '@ff/web-components/utils/l10n';
import useForm from '@/hooks/useForm';
import Box from '@ff/web-components/components/Box';
import DropDown from '@ff/web-components/components/DropDown';
import Sum from '@ff/web-components/components/Input/Sum';
import Text from '@ff/web-components/components/Text';
import {useMutation, useQuery} from '@tanstack/react-query';
import getBalance, {QUERY_KEY} from '@/features/Accounts/api/getBalance';
import GradientSpinner from '@/components/GradientSpinner';
import LoadingButton from '@/components/LoadingButton';
import stringify from '@ff/web-components/utils/number/stringify';
import useValidation from '@/hooks/useValidation';
import required from '@/hooks/useValidation/required';
import FieldError from '@/components/FieldError';
import useToggle from '@/hooks/useToggle';
import {State} from '@/features/TransferToTaxService';
import getTypes from '@/features/TransferToTaxService/api/getTypes';
import getDestinations from '@/features/TransferToTaxService/api/getDestinations';
import saveForm, {
    Params,
    Response as SaveResponse,
} from '@/features/TransferToTaxService/api/saveForm';
import parse from '@ff/web-components/utils/number/parse';
import {findError, ResponseError} from '@/api/protocol';
import Controls from '@/features/Templates/controls';
import saveTaxTemplate, {
    Params as SaveTemplateParams,
    Response as SaveTemplateResponse,
} from '@/features/Templates/api/saveTaxTemplate';
import deleteTemplate, {
    Response as DeleteTemplateResponse,
} from '@/features/Templates/api/deleteTemplate';
import dashboardLink from '@/features/Dashboard/makeLink';
import {Dictionary} from '@/locale';
import getCommission from '@/features/TransferToTaxService/api/getCommission';
import {SyntheticEvent} from '@ff/web-components/utils/syntheticEvent';
import useLang from '@/hooks/useLang';
import {Template as ITemplate} from '@/features/Templates/api/getList';
import FormError from '@/components/FormError';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: Partial<State>) => void;
    onBack: () => void;
    type: ITemplate['type'];
}

const Form: React.FC<IProps> = ({data, onSubmit, onBack}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const locale: Dictionary = getLocale();
    const formLocale: Dictionary['tax']['form'] = locale.tax.form;
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange, setState] = useForm(data);
    const [valid, errors] = useValidation(state, required(['type', 'dest', 'sum']));
    const amount = typeof state.sum === 'number' ? state.sum : parse(state.sum);
    const lang = useLang();
    const saveReq = useMutation<SaveResponse, ResponseError, Params>({
        mutationFn: params => saveForm(params),
    });
    const saveTemplateReq = useMutation<SaveTemplateResponse, ResponseError, SaveTemplateParams>({
        mutationFn: params => saveTaxTemplate(params),
    });
    const deleteTemplateReq = useMutation<DeleteTemplateResponse, ResponseError, string>({
        mutationFn: id => deleteTemplate(id),
    });
    const getBalanceReq = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => getBalance(),
    });
    const getTypesReq = useQuery({
        queryKey: ['/tax/get-payment-types', state.voen, lang],
        queryFn: () => getTypes({voen: state.voen}),
        staleTime: 300000,
    });
    const getDestReq = useQuery({
        queryKey: ['/tax/get-payment-details', state.type, state.voen, lang],
        queryFn: () => getDestinations({paymentTypeId: state.type, voen: state.voen}),
        enabled: !!state.type,
        staleTime: 300000,
    });
    const paymentDetail = getDestReq.data?.details?.find(item => item.code === state.dest);
    const getCommissionReq = useQuery({
        queryKey: ['/tax/calculate-fee'],
        queryFn: () =>
            getCommission({
                voen: state.voen,
                amount,
                paymentTypeId: state.type,
                paymentDetail,
            }),

        enabled: false,
    });
    const handleTypeChange = (e: SyntheticEvent) => setState({type: e.target.value, dest: null});
    const handleSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!valid) {
                toggleValidation(true);
            } else {
                saveTemplateReq.reset();
                deleteTemplateReq.reset();
                saveReq.mutate({
                    voen: state.voen,
                    paymentTypeId: state.type,
                    paymentDetail,
                    amount,
                });
            }
        },
        [state, valid, paymentDetail],
    );
    const handleSaveTemplateReq = useCallback(() => {
        if (!valid) {
            toggleValidation(true);
        } else {
            saveReq.reset();
            deleteTemplateReq.reset();
            saveTemplateReq.mutate({
                voen: state.voen,
                paymentTypeId: state.type,
                paymentDetail,
                amount,
                id: state.template.id,
                cardId: state.template.templateDetails.cardId || getBalanceReq.data?.data.id,
            });
        }
    }, [state, valid, paymentDetail]);
    const handleDeleteTemplate = useCallback(() => {
        saveTemplateReq.reset();
        saveReq.reset();
        deleteTemplateReq.mutate(state.template.id);
    }, [state.template?.id]);
    const errorObject = saveReq.error || saveTemplateReq.error || deleteTemplateReq.error;
    const apiErrors = useMemo(() => {
        return errorObject
            ? {
                  global: findError(errorObject),
                  type: findError(errorObject, 'paymentTypeId'),
                  dest: findError(errorObject, 'paymentDetail'),
                  sum: findError(errorObject, 'amount'),
              }
            : {};
    }, [saveReq.isError, saveTemplateReq.isError, deleteTemplateReq.isError]);
    const fetchCommissionTimeout = useRef<ReturnType<typeof setTimeout>>();
    const validForRequestCommission = !isNaN(amount) && amount > 0 && state.type && paymentDetail;

    useEffect(() => {
        return () => {
            clearTimeout(fetchCommissionTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (saveReq.isSuccess) {
            onSubmit({
                ...state,
                ...saveReq.data,
                dataForReq: {
                    voen: state.voen,
                    paymentTypeId: state.type,
                    paymentDetail,
                    amount,
                    cardId: getBalanceReq.data.data.id,
                    templateId: data.template?.id ?? undefined,
                },
            });
        }
    }, [saveReq.isSuccess]);

    useEffect(() => {
        if (saveTemplateReq.isSuccess || deleteTemplateReq.isSuccess) {
            navigate(dashboardLink({tab: 'templates'}));
        }
    }, [saveTemplateReq.isSuccess, deleteTemplateReq.isSuccess]);

    useEffect(() => {
        if (saveReq.isError) {
            saveReq.reset();
        }
        if (saveTemplateReq.isError) {
            saveTemplateReq.reset();
        }
        if (deleteTemplateReq.isError) {
            deleteTemplateReq.reset();
        }
    }, [state]);

    useEffect(() => {
        if (validForRequestCommission) {
            clearTimeout(fetchCommissionTimeout.current);
            fetchCommissionTimeout.current = setTimeout(() => getCommissionReq.refetch(), 300);
        }
    }, [state.sum, state.type, paymentDetail]);

    return (
        <Layout
            onBack={onBack}
            title={formLocale.title}
            subTitle={placeholder(formLocale.voen, {n: data.voen})}
        >
            {apiErrors.global ? <FormError error={[apiErrors.global]} /> : null}
            {saveTemplateReq.isError ? <FormError error={saveTemplateReq.error} /> : null}
            {deleteTemplateReq.isError ? <FormError error={deleteTemplateReq.error} /> : null}
            <form action="/" method="post" onSubmit={handleSubmit}>
                <Box df fd="column" gap={2.5} mb={3}>
                    <div>
                        <DropDown
                            name="type"
                            value={state.type}
                            onChange={handleTypeChange}
                            label={formLocale.type}
                            error={!!apiErrors.type || !!(validation && errors.type?.message)}
                            options={getTypesReq.data?.types.map(item => ({
                                value: item.id,
                                title: item.name,
                                subTitle: item.description,
                            }))}
                            disabled={
                                getTypesReq.isLoading ||
                                getTypesReq.isPending ||
                                !getTypesReq.data?.types?.length
                            }
                        />
                        <FieldError>
                            {apiErrors.type?.message || (validation && errors.type?.message)}
                        </FieldError>
                        <FieldError>
                            {getTypesReq.isError ? (
                                <div>
                                    {formLocale.loadDictError}{' '}
                                    <Text
                                        td="underline"
                                        cursor="pointer"
                                        onClick={() => getTypesReq.refetch()}
                                    >
                                        {formLocale.reload}
                                    </Text>
                                </div>
                            ) : null}
                        </FieldError>
                    </div>
                    <div>
                        <DropDown
                            name="dest"
                            value={state.dest}
                            onChange={onChange}
                            label={formLocale.dest}
                            error={!!apiErrors.dest || !!(validation && errors.dest?.message)}
                            disabled={
                                !state.type ||
                                getDestReq.isLoading ||
                                getDestReq.isPending ||
                                !getDestReq.data?.details?.length
                            }
                            options={getDestReq.data?.details.map(item => ({
                                value: item.code,
                                title: String(item.code),
                                subTitle: item.description,
                            }))}
                        />
                        <FieldError>
                            {apiErrors.dest?.message || (validation && errors.dest?.message)}
                        </FieldError>
                        <FieldError>
                            {getDestReq.isError ? (
                                <div>
                                    {formLocale.loadDictError}{' '}
                                    <Text
                                        td="underline"
                                        cursor="pointer"
                                        onClick={() => getDestReq.refetch()}
                                    >
                                        {formLocale.reload}
                                    </Text>
                                </div>
                            ) : null}
                        </FieldError>
                    </div>
                    <div>
                        <Box mb={1.25}>
                            <Sum
                                name="sum"
                                value={state.sum}
                                onChange={onChange}
                                label={formLocale.sum}
                                maxLength={10}
                                error={!!apiErrors.sum || !!(validation && errors.sum?.message)}
                            />
                            <FieldError>
                                {apiErrors.sum?.message || (validation && errors.sum?.message)}
                            </FieldError>
                        </Box>
                        <div>
                            {getBalanceReq.isLoading ? (
                                <GradientSpinner size={2} />
                            ) : getBalanceReq.isSuccess ? (
                                <Text size="xs3" color="secondary" align="left">
                                    {formLocale.balance}{' '}
                                    {stringify(getBalanceReq.data.data.balances.amount)}{' '}
                                    {getBalanceReq.data.data.displayedInfo.currencySymbol}
                                </Text>
                            ) : null}
                            {validForRequestCommission ? (
                                <Text size="xs3" color="secondary" align="left">
                                    <Box df ai="center">
                                        {formLocale.commission}
                                        &nbsp;
                                        {!getCommissionReq.isFetched ||
                                        getCommissionReq.isLoading ? (
                                            <GradientSpinner size={2} />
                                        ) : getCommissionReq.isSuccess ? (
                                            <span>
                                                {stringify(getCommissionReq.data.feeAmount)}{' '}
                                                {
                                                    locale.app.currency[
                                                        state.currency.toUpperCase() as keyof typeof locale.app.currency
                                                    ]
                                                }
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </Box>
                                </Text>
                            ) : null}
                        </div>
                    </div>
                </Box>
                <LoadingButton
                    primary
                    wide
                    type="submit"
                    loading={saveReq.isPending}
                    disabled={
                        getTypesReq.isLoading ||
                        getTypesReq.isPending ||
                        getDestReq.isLoading ||
                        getDestReq.isPending
                    }
                >
                    {formLocale.submit}
                </LoadingButton>
                {searchParams.has('edit') && data.template ? (
                    <Box mt={2.5}>
                        <Controls
                            onSave={handleSaveTemplateReq}
                            onDelete={handleDeleteTemplate}
                            saveLoading={saveTemplateReq.isPending}
                            deleteLoading={deleteTemplateReq.isPending}
                            saveSuccess={saveTemplateReq.isSuccess}
                            deleteSuccess={deleteTemplateReq.isSuccess}
                        />
                    </Box>
                ) : null}
            </form>
        </Layout>
    );
};

export default Form;
