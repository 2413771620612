import React, {useCallback, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import getList, {Account, Response} from './api/getList';
import {ResponseError} from '@/api/protocol';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Paper from '@ff/web-components/components/Paper';
import {getLocale} from '@ff/web-components/utils/l10n';
import useResponsive from '@/hooks/useResponsive';
import User from './style/user';
import {useDispatch} from 'react-redux';
import {set} from '@/features/Accounts/reducers/account';
import {useNavigate} from 'react-router-dom';
import dashboard from '@/features/Dashboard/makeLink';
import Skeleton from './skeleton';
import useSession from '@/hooks/useSession';
import UserPic from '@/features/Accounts/userPic';
import useLang from '@/hooks/useLang';
import GeneralError from '@/components/GeneralError';
import {Dictionary} from '@/locale';
import {pageView} from '@/utils/gtag';

const Accounts = () => {
    const appLocale: Dictionary['app'] = getLocale().app;
    const locale = getLocale().auth?.accountSelection;
    const {isMobile} = useResponsive();
    const {authorized} = useSession();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSelect = useCallback((account: Account) => {
        dispatch(set(account));
        navigate(dashboard());
    }, []);
    const lang = useLang();
    const listReq = useQuery<Response, ResponseError>({
        queryKey: ['/accounts', lang],
        queryFn: getList,
        staleTime: 1000,
        enabled: authorized,
    });

    useEffect(() => {
        pageView({path: '/accounts', title: 'Accounts'});
    }, []);

    useEffect(() => {
        if (listReq.isSuccess && listReq.data?.length === 1) {
            handleSelect(listReq.data[0]);
        }
    }, [listReq.isSuccess]);

    return listReq.isLoading ? (
        <Skeleton />
    ) : listReq.isError ? (
        <Box pt={8}>
            <GeneralError
                title={appLocale.error.title}
                subTitle={appLocale.error.subTitle}
                onRetry={() => listReq.refetch()}
            />
        </Box>
    ) : listReq.isSuccess ? (
        <Box pt={8} w={isMobile ? '100%' : 46} center>
            <Paper py={5}>
                <Box px={5} mb={3}>
                    <Text align="center" size="l" bold>
                        {locale.title}
                    </Text>
                </Box>
                {listReq.data?.map(account => (
                    <User key={account.id} onClick={() => handleSelect(account)}>
                        <Box mr={2.5}>
                            <UserPic account={account} />
                        </Box>
                        <div>{account.name}</div>
                    </User>
                ))}
            </Paper>
        </Box>
    ) : null;
};

export default Accounts;
