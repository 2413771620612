import React, {useCallback} from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import CategoryIcon from '@/features/Dashboard/statement/list/style/categoryIcon';
import {Template} from '@/features/Templates/api/getList';
import Description from '@/features/Dashboard/statement/list/style/description';
import taxLink from '@/features/TransferToTaxService/makeLink';
import {PAYMENT_TYPE as TAX} from '@/features/TransferToTaxService/constants';
import {PAYMENT_TYPE as IBAN} from '@/features/TransferToAccount/constants';
import {useNavigate} from 'react-router-dom';
import ibanLink from '@/features/TransferToAccount/makeLink';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import {get} from '@/store/runtime';

const itemStyle = {cursor: 'pointer'};

const Item = ({title, subTitle, img, type, id}: Template) => {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        if (type === TAX) {
            navigate(taxLink({t: id, edit: true}));
        } else if (type === IBAN) {
            navigate(ibanLink({t: id, edit: true}));
        }
    }, [type, id]);

    return (
        <Box py={2} px={2.5} df ai="center" onClick={handleClick} style={itemStyle}>
            <Box mr={2.5} fs={0} w={5} df>
                {img ? <CategoryIcon src={categoryIcon(img, get('ENTERPRISE'))} /> : null}
            </Box>
            <Box fg={3} fd="column" df>
                {title ? <Text>{title}</Text> : null}
                {subTitle ? (
                    <Text size="xs" color="secondary">
                        <Description>{subTitle}</Description>
                    </Text>
                ) : null}
            </Box>
        </Box>
    );
};

export default Item;
