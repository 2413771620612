import axios from 'axios';
import url from '@/api/url';

export type Response = {
    data: string;
};

export default async function getLoanInfo(): Promise<Response> {
    return axios.get(url('loan-info'));
}
