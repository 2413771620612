import styled from 'styled-components';

interface QuarterWrapperProps {
    $top;
}

const QuarterWrapper = styled.div<QuarterWrapperProps>`
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    ${({$top}) => ({
        top: $top,
    })}
`;

export default QuarterWrapper;
