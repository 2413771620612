import axios from 'axios';
import url from '@/api/url';

export interface Account {
    id: string;
    img?: string;
    name?: string;
}

export type Response = Account[];

export default async function getList(): Promise<Response> {
    return axios.get(url('accounts'));
}
