import styled from 'styled-components';

interface LabelWrapperProps {
    $theme;
}

const LabelWrapper = styled.div<LabelWrapperProps>`
    position: relative;
    ${({$theme}) => ({
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
    })}
`;

export default LabelWrapper;
