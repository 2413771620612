import React from 'react';
import Box from '@ff/web-components/components/Box';
import Text, {IProps as TextProps} from '@ff/web-components/components/Text';
import stringify from '@ff/web-components/utils/number/stringify';
import getInt from '@ff/web-components/utils/number/getInt';
import getFraction from '@ff/web-components/utils/number/getFraction';
import toFixedNumber from '@ff/web-components/utils/number/toFixed';

interface IProps {
    sum: number;
    currency: string;
    size?: TextProps['size'];
    fractionSize?: TextProps['size'];
    color?: TextProps['color'];
    bold?: boolean;
    toFixed?: number;
}

const Money = ({sum, currency, size = 'm', fractionSize, bold, color, toFixed = 2}: IProps) => {
    fractionSize = fractionSize ?? size;
    return (
        <Box ai="baseline" dif>
            <Box dif ai="baseline">
                {size !== fractionSize ? (
                    <>
                        <Text bold={bold} size={size} color={color}>
                            {stringify(getInt(sum))}
                        </Text>
                        {toFixed > 0 ? (
                            <Text bold={bold} size={fractionSize} color={color}>
                                .
                                {String(
                                    toFixedNumber(Math.abs(getFraction(sum)) * 100, 0),
                                ).padStart(toFixed, '0')}
                            </Text>
                        ) : null}
                    </>
                ) : (
                    <Text bold={bold} size={size} color={color}>
                        {stringify(sum, {toFixed})}
                    </Text>
                )}
            </Box>
            {currency ? (
                <Box ml={0.5} dif>
                    <Text size={fractionSize} bold={bold} color={color}>
                        {currency}
                    </Text>
                </Box>
            ) : null}
        </Box>
    );
};

export default Money;
