'use client';
'use strict';
import LabelStyle from './style/label';
import LabelHolder from './style/labelHolder';
import Root from './style/root';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import InputStyle from './style/input';
import mergeTheme from 'themes/utils/merge';
import theme from '../theme';
import defaultTheme from './theme';
import LabelWrapper from './style/labelWrapper';
import LabelFloatBase from './style/labelFloatBase';
import CloseAction from './closeAction';
import ActionContainer from './style/actionContainer';
import syntheticEvent, {SyntheticEvent} from '../../utils/syntheticEvent';
import StartIconContainer from './style/startIconContainer';
import {DeepPartial} from 'themes/index';

type IInputNative = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'style' | 'value'>;

export interface IProps extends IInputNative {
    label?: React.ReactNode;
    value?: string | number | null;
    error?: boolean;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent) => void;
    placeholder?: string;
    style?: DeepPartial<ReturnType<typeof defaultTheme>>;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
    disabled?: boolean;
    className?: string;
    wide?: boolean;
    endIcon?: false | React.ReactNode;
    startIcon?: React.ReactNode;
}

const InputMain = React.forwardRef<HTMLInputElement, IProps>(
    (
        {
            label,
            value,
            error,
            onFocus,
            onChange,
            placeholder,
            onBlur,
            style,
            theme,
            disabled,
            onMouseEnter,
            onMouseLeave,
            className,
            wide,
            endIcon,
            startIcon,
            ...rest
        }: IProps,
        ref,
    ) => {
        const [hover, setHover] = useState(false);
        const [focus, setFocus] = useState(false);
        const inputInner = useRef<HTMLInputElement | null>(null);
        const inputReference = ref || inputInner;
        value = value ?? '';
        const handleEnter = useCallback(
            e => {
                setHover(true);
                if (typeof onMouseEnter === 'function') {
                    onMouseEnter(e);
                }
            },
            [onMouseEnter],
        );
        const handleLeave = useCallback(
            e => {
                setHover(false);
                if (typeof onMouseLeave === 'function') {
                    onMouseLeave(e);
                }
            },
            [onMouseLeave],
        );
        const handleFocus = useCallback(
            e => {
                setFocus(true);
                if (typeof onFocus === 'function') {
                    onFocus(e);
                }
            },
            [onFocus, onChange],
        );
        const handleBlur = useCallback(
            e => {
                setFocus(false);
                if (typeof onBlur === 'function') {
                    onBlur(e);
                }
            },
            [onBlur],
        );
        const handleLabelClick = useCallback(() => {
            if (typeof inputReference === 'object' && inputReference.current) {
                inputReference.current?.focus();
            }
        }, [inputReference]);
        const handleClear = useCallback(() => {
            if (
                typeof onChange === 'function' &&
                typeof inputReference === 'object' &&
                inputReference.current
            ) {
                inputReference.current.value = '';
                onChange(
                    syntheticEvent(
                        typeof inputReference === 'object' ? inputReference.current : null,
                    ),
                );
            }
        }, [onChange]);
        const labelTop = focus || !!String(value);

        // upgrade focus method
        useEffect(() => {
            if (typeof inputReference === 'object' && inputReference?.current) {
                const proto = Object.getPrototypeOf(inputReference.current);

                inputReference.current.focus = () => {
                    proto.focus.call(inputReference.current);
                    if (inputReference.current && String(value).length) {
                        try {
                            inputReference.current.selectionStart = String(value).length;
                        } catch (e) {
                            /* eslint-env node */
                            if (process.env.NODE_ENV !== 'production') {
                                window.console.error(`web-components: Input ${e}`);
                            }
                        }
                    }
                };
            }
        }, []);

        const compiledTheme = useMemo(() => {
            const compiled = mergeTheme(theme, style);
            return {
                input: {
                    ...compiled,
                    label: undefined,
                },
                label: {
                    theme: compiled.theme,
                    ...compiled.label,
                },
            };
        }, [theme, style]);

        return (
            <Root
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                $theme={compiledTheme.input}
                className={className}
                $wide={wide}
                $hover={hover}
                $focus={focus}
                $error={error}
                $disabled={disabled}
            >
                {startIcon ? (
                    <StartIconContainer $theme={compiledTheme.input.theme}>
                        {startIcon}
                    </StartIconContainer>
                ) : null}
                {label ? (
                    <LabelWrapper
                        $theme={compiledTheme.label}
                        onClick={handleLabelClick}
                        $padRight={labelTop}
                        $padLeft={!!startIcon}
                    >
                        <LabelFloatBase>
                            <LabelHolder $theme={compiledTheme.label}>&nbsp;</LabelHolder>
                            <LabelStyle
                                $theme={compiledTheme.label}
                                $error={error}
                                $top={labelTop}
                                $toFontSize={compiledTheme.input.fontSize}
                                $toLineHeight={compiledTheme.input.lineHeight}
                                $gap={2}
                                $disabled={disabled}
                            >
                                {label}
                            </LabelStyle>
                        </LabelFloatBase>
                    </LabelWrapper>
                ) : null}
                <InputStyle
                    {...rest}
                    $withLabel={!!label}
                    $theme={compiledTheme.input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={inputReference}
                    placeholder={!label ? placeholder : ''}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    $padLeft={!!startIcon}
                />
                {endIcon !== false ? (
                    <ActionContainer $theme={compiledTheme.input.theme}>
                        {endIcon ||
                            (!!String(value) && (focus || hover) ? (
                                <CloseAction
                                    theme={compiledTheme.input.theme}
                                    onClick={handleClear}
                                />
                            ) : null)}
                    </ActionContainer>
                ) : null}
            </Root>
        );
    },
);

InputMain.displayName = 'Input';

export default theme<IProps, HTMLInputElement>(InputMain, 'input', 'Input');
