import axios from 'axios';
import url from '@/api/url';

export type Response = {
    csrfToken: string;
};

export default async function getToken(): Promise<Response> {
    return axios.get(url('auth/token'));
}
