import React from 'react';
import Box from '@ff/web-components/components/Box';
import logo from '../../../../img/logo.svg';
import Root from '../../style/header';
import Divider from '@ff/web-components/components/Divider';
import {useDispatch, useSelector} from 'react-redux';
import {set} from '../../reducers/lang';
import {RootState} from '@/store';
import Chips from '@ff/web-components/components/Chips';
import Support from '@/features/Application/layout/header/support';

const getLang = ({application}: RootState) => application.lang;

const Header = () => {
    const dispatch = useDispatch();
    const lang = useSelector(getLang);

    return (
        <Root>
            <Box py={3} df jc="space-between" ai="center">
                <div>
                    <img src={logo} height="24px" alt="Leobank" />
                </div>
                <Box df ai="center">
                    <Box mr={2.5}>
                        <Chips
                            active={lang.toUpperCase() === 'AZ'}
                            onClick={() => dispatch(set('AZ'))}
                        >
                            AZ
                        </Chips>
                        <Chips
                            active={lang.toUpperCase() === 'RU'}
                            onClick={() => dispatch(set('RU'))}
                        >
                            RU
                        </Chips>
                    </Box>
                    <Support />
                </Box>
            </Box>
            <Divider />
        </Root>
    );
};

export default Header;
