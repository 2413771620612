import axios from 'axios';
import {Destination} from '@/features/TransferToTaxService/api/getDestinations';
import url from '@/api/url';

export type ExtraField = {
    name: string;
    value: string;
};

export type Response = {
    feeAmount: number;
    amount: number;
    extraFields?: ExtraField[];
};

export interface Params {
    voen: string;
    paymentTypeId: string;
    paymentDetail: Destination;
    amount: number;
}

export default async function saveForm(params: Params): Promise<Response> {
    return axios.post(url(`tax/payment-info`), params);
}
