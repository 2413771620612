import React, {lazy, Suspense} from 'react';
import Box from '@ff/web-components/components/Box';
import Skeleton from '@ff/web-components/components/Skeleton';
import {IPlayerProps} from '@lottiefiles/react-lottie-player';

const BakuF1 = lazy(() => import('./BakuF1'));
const Karateka = lazy(() => import('./Karateka'));
const Drum = lazy(() => import('./Drum'));
const DanceHat = lazy(() => import('./DanceHat'));
const Taxi = lazy(() => import('./Taxi'));
const DanceWithGirl = lazy(() => import('./DanceWithGirl'));
const MoneyFanHat = lazy(() => import('./MoneyFanHat'));
const Policeman = lazy(() => import('./Policeman'));
const TaxiRedCab = lazy(() => import('./TaxiRedCab'));
const FruitNinja = lazy(() => import('./FruitNinja'));
const Oilman = lazy(() => import('./Oilman'));
const CapitanAzerbaijan = lazy(() => import('./CapitanAzerbaijan'));
const Tea = lazy(() => import('./Tea'));
const Donkey = lazy(() => import('./Donkey'));
const N1001 = lazy(() => import('./N1001'));
const Horse = lazy(() => import('./Horse'));
const Carpets = lazy(() => import('./Carpets'));
const SendMoney = lazy(() => import('./SendMoney'));
const Dicaprio = lazy(() => import('./Dicaprio'));
const Positive = lazy(() => import('./Positive'));
const WinterIsComing = lazy(() => import('./WinterIsComing'));
const MunchScream = lazy(() => import('./MunchScream'));
const Vincent = lazy(() => import('./Vincent'));
const Leonardo = lazy(() => import('./Leonardo'));
const GoldwynMayer = lazy(() => import('./GoldwynMayer'));
const DaliMustache = lazy(() => import('./DaliMustache'));
const Einstein = lazy(() => import('./Einstein'));
const MonaLisa = lazy(() => import('./MonaLisa'));
const BreakingBad = lazy(() => import('./BreakingBad'));
const YouCantJustTake = lazy(() => import('./YouCantJustTake'));
const Matrix = lazy(() => import('./Matrix'));
const Shining = lazy(() => import('./Shining'));
const IronMan = lazy(() => import('./IronMan'));
const Dune = lazy(() => import('./Dune'));
const Joker = lazy(() => import('./Joker'));
const Squid = lazy(() => import('./Squid'));
const Scorpio = lazy(() => import('./Scorpio'));
const Leoground = lazy(() => import('./Leoground'));
const Yoda = lazy(() => import('./Yoda'));
const Newton = lazy(() => import('./Newton'));
const Leocoin = lazy(() => import('./Leocoin'));
const Leocraft = lazy(() => import('./Leocraft'));
const MoneyGunFront = lazy(() => import('./MoneyGunFront'));
const TrucksVanDamme = lazy(() => import('./TrucksVanDamme'));
const Sparta = lazy(() => import('./Sparta'));
const SculptureDiscus = lazy(() => import('./SculptureDiscus'));
const Picasso = lazy(() => import('./Picasso'));
const MoneyLayDown = lazy(() => import('./MoneyLayDown'));
const Mario = lazy(() => import('./Mario'));
const LoveIs = lazy(() => import('./LoveIs'));
const MagritteApple = lazy(() => import('./MagritteApple'));
const SadBroccoli = lazy(() => import('./SadBroccoli'));
const BallenciagaRedJacket = lazy(() => import('./BallenciagaRedJacket'));
const PopcornCinema = lazy(() => import('./PopcornCinema'));
const Sherlock = lazy(() => import('./Sherlock'));
const Sith = lazy(() => import('./Sith'));
const VanGoghHat = lazy(() => import('./VanGoghHat'));
const Anonymus = lazy(() => import('./Anonymus'));
const Witcher = lazy(() => import('./Witcher'));
const Zhdun = lazy(() => import('./Zhdun'));
const RodenThinker = lazy(() => import('./RodenThinker'));
const Meditation = lazy(() => import('./Meditation'));
const ScroogeMcDuck = lazy(() => import('./ScroogeMcDuck'));
const WolverineLogan = lazy(() => import('./WolverineLogan'));
const HarryPotter = lazy(() => import('./HarryPotter'));
const PlateOldBanks = lazy(() => import('./PlateOldBanks'));
const TurtleLeonardo = lazy(() => import('./TurtleLeonardo'));
const Sonic = lazy(() => import('./Sonic'));
const CardKingLeo = lazy(() => import('./CardKingLeo'));
const CarpetAladdin = lazy(() => import('./CarpetAladdin'));
const PackOfDollars = lazy(() => import('./PackOfDollars'));
const FrayCard = lazy(() => import('./FrayCard'));
const FrayDollars = lazy(() => import('./FrayDollars'));
const TurkishChiefSalt = lazy(() => import('./TurkishChiefSalt'));
const SteveJobs = lazy(() => import('./SteveJobs'));
const MeditationWithCards = lazy(() => import('./MeditationWithCards'));
const Banksy = lazy(() => import('./Banksy'));
const Cowboy = lazy(() => import('./Cowboy'));
const Ninja = lazy(() => import('./Ninja'));
const Paris = lazy(() => import('./Paris'));
const Hulk = lazy(() => import('./Hulk'));
const Spiderman = lazy(() => import('./Spiderman'));
const Avatar = lazy(() => import('./Avatar'));
const Superman = lazy(() => import('./Superman'));
const Pharaon = lazy(() => import('./Pharaon'));
const Cybertruck = lazy(() => import('./Cybertruck'));

export interface IProps extends Omit<IPlayerProps, 'src'> {
    width?: string;
    height?: string;
    name:
        | 'Cybertruck'
        | 'Pharaon'
        | 'Superman'
        | 'Avatar'
        | 'Spiderman'
        | 'Hulk'
        | 'Paris'
        | 'Ninja'
        | 'Cowboy'
        | 'Banksy'
        | 'MeditationWithCards'
        | 'SteveJobs'
        | 'TurkishChiefSalt'
        | 'FrayDollars'
        | 'FrayCard'
        | 'PackOfDollars'
        | 'CarpetAladdin'
        | 'CardKingLeo'
        | 'Sonic'
        | 'TurtleLeonardo'
        | 'PlateOldBanks'
        | 'HarryPotter'
        | 'WolverineLogan'
        | 'ScroogeMcDuck'
        | 'Meditation'
        | 'RodenThinker'
        | 'Witcher'
        | 'Zhdun'
        | 'Anonymus'
        | 'VanGoghHat'
        | 'Sith'
        | 'Sherlock'
        | 'PopcornCinema'
        | 'BallenciagaRedJacket'
        | 'SadBroccoli'
        | 'MagritteApple'
        | 'LoveIs'
        | 'Mario'
        | 'MoneyLayDown'
        | 'Picasso'
        | 'SculptureDiscus'
        | 'Sparta'
        | 'TrucksVanDamme'
        | 'MoneyGunFront'
        | 'Leocraft'
        | 'Leocoin'
        | 'Newton'
        | 'Yoda'
        | 'Leoground'
        | 'Scorpio'
        | 'Squid'
        | 'Joker'
        | 'Dune'
        | 'IronMan'
        | 'Shining'
        | 'Matrix'
        | 'YouCantJustTake'
        | 'BreakingBad'
        | 'MonaLisa'
        | 'Einstein'
        | 'DaliMustache'
        | 'GoldwynMayer'
        | 'Leonardo'
        | 'Vincent'
        | 'MunchScream'
        | 'WinterIsComing'
        | 'Positive'
        | 'Dicaprio'
        | 'BakuF1'
        | 'Karateka'
        | 'Drum'
        | 'DanceHat'
        | 'Taxi'
        | 'DanceWithGirl'
        | 'MoneyFanHat'
        | 'Policeman'
        | 'TaxiRedCab'
        | 'FruitNinja'
        | 'Oilman'
        | 'CapitanAzerbaijan'
        | 'Tea'
        | 'Donkey'
        | 'N1001'
        | 'Horse'
        | 'Carpets'
        | 'SendMoney';
}

const alias = {
    Cybertruck,
    Pharaon,
    Superman,
    Avatar,
    Spiderman,
    Hulk,
    Paris,
    Ninja,
    Cowboy,
    Banksy,
    MeditationWithCards,
    SteveJobs,
    TurkishChiefSalt,
    FrayDollars,
    FrayCard,
    PackOfDollars,
    CarpetAladdin,
    CardKingLeo,
    Sonic,
    TurtleLeonardo,
    PlateOldBanks,
    HarryPotter,
    WolverineLogan,
    ScroogeMcDuck,
    Meditation,
    RodenThinker,
    Witcher,
    Zhdun,
    Anonymus,
    VanGoghHat,
    Sith,
    Sherlock,
    PopcornCinema,
    BallenciagaRedJacket,
    SadBroccoli,
    MagritteApple,
    LoveIs,
    Mario,
    MoneyLayDown,
    Picasso,
    SculptureDiscus,
    Sparta,
    TrucksVanDamme,
    MoneyGunFront,
    Leocraft,
    Leocoin,
    Newton,
    Yoda,
    Leoground,
    Scorpio,
    Squid,
    Joker,
    Dune,
    IronMan,
    Shining,
    Matrix,
    YouCantJustTake,
    BreakingBad,
    MonaLisa,
    Einstein,
    DaliMustache,
    GoldwynMayer,
    Leonardo,
    Vincent,
    MunchScream,
    WinterIsComing,
    Positive,
    Dicaprio,
    BakuF1,
    Karateka,
    Drum,
    DanceHat,
    Taxi,
    DanceWithGirl,
    MoneyFanHat,
    Policeman,
    TaxiRedCab,
    FruitNinja,
    Oilman,
    CapitanAzerbaijan,
    Tea,
    Donkey,
    N1001,
    Horse,
    Carpets,
    SendMoney,
};

const Mascot = ({name, width = '300px', height = '200px', ...rest}: IProps) => {
    const Component = alias[name];
    return (
        Component && (
            <Suspense
                fallback={
                    <Box df w={width} h={height} jc="center">
                        <Skeleton w={height} h={height} rad="50%" />
                    </Box>
                }
            >
                <Box w={width} h={height}>
                    <Component style={{width, height}} {...rest} />
                </Box>
            </Suspense>
        )
    );
};

export default Mascot;
