import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import Auth from '@/features/Auth';
import Layout from './layout';
import './style/reset.css';
import './style/global.css';
import Root from './style/root';
import withLocale from '@/hocs/withLocale';
import Box from '@ff/web-components/components/Box';
import {useDispatch} from 'react-redux';
import '@/api/config';
import '@/api/interceptors';
import Accounts from '@/features/Accounts';
import PrivateLayout from '@/features/Application/layout/private';
import Dashboard from '@/features/Dashboard';
import GradientSpinner from '@/components/GradientSpinner';
import TransferToTaxService from '@/features/TransferToTaxService';
import TransferToMyCard from '@/features/TransferToMyCard';
import TransferToAccount from '@/features/TransferToAccount';
import Requisites from '@/features/Requisites';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {set as fpSet} from '@/features/Application/reducers/fp';
import useFP from '@/hooks/useFP';
import EnterpriseLayout from '@/features/Application/layout/private/enterprise';
import Test from '@/features/Test';

const ru = () => import(/* webpackChunkName: "localeRU" */ '../../locale/ru.json');
const az = () => import(/* webpackChunkName: "localeAZ" */ '../../locale/az.json');

const Application = () => {
    const dispatch = useDispatch();
    const fp = useFP();

    useEffect(() => {
        if (!fp) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // monitoring is missing in d.ts
            FingerprintJS.load({monitoring: false})
                .then(fp => fp.get())
                .then(result => dispatch(fpSet(result.visitorId)));
        }
    }, []);

    return (
        <Root>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="auth" element={<Auth />} />
                    <Route path="" element={<Auth />} />
                    <Route path="*" element={<Auth />} />
                </Route>
                <Route path="/u/:account" element={<PrivateLayout />}>
                    <Route path="accounts" element={<Accounts />} />
                    <Route path=":enterprise" element={<EnterpriseLayout />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="transfer">
                            <Route path="tax" element={<TransferToTaxService />} />
                            <Route path="card" element={<TransferToMyCard />} />
                            <Route path="account" element={<TransferToAccount />} />
                        </Route>
                        <Route path="requisites" element={<Requisites />} />
                        <Route path="test" element={<Test />} />
                        <Route path="" element={<Dashboard />} />
                        <Route path="*" element={<Dashboard />} />
                    </Route>
                    <Route path="" element={<Accounts />} />
                </Route>
            </Routes>
        </Root>
    );
};

export default withLocale(Application, {
    ru,
    az,
    fallback: (
        <Box w="100%" h="100vh" df jc="center" ai="center">
            <GradientSpinner size="64px" />
        </Box>
    ),
});
