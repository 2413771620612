import React from 'react';
import {Response} from '@/features/Accounts/api/getPayments';
import Paper from '@ff/web-components/components/Paper';
import PaymentsList from '@/features/Dashboard/statement/payments/list';

interface IProps {
    collection: Response['data'];
}

const Payments = ({collection}: IProps) => {
    return (
        <Paper pt={2.5} pb={1} mt={2.5}>
            <PaymentsList collection={collection} />
        </Paper>
    );
};

export default Payments;
