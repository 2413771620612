import React from 'react';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import {Statement} from '@/features/Accounts/api/getStatement';

interface IProps {
    params: Statement['detailContent']['params'];
}

const DetailsBody = ({params}: IProps) => {
    return params?.length ? (
        <Box py={2.5} gap={2} df fd="column">
            {params.map(({name, value}) => (
                <div key={name}>
                    <div>
                        <Text size="xs" color="secondary">
                            {name}
                        </Text>
                    </div>
                    <div>
                        <Text size="m">{value}</Text>
                    </div>
                </div>
            ))}
        </Box>
    ) : null;
};

export default DetailsBody;
