import url from '@/api/url';

export default function categoryIcon(path: string, enterpriseId: string) {
    let userPic;

    if (!path.startsWith('https')) {
        try {
            userPic = new URL(
                `${window.location.protocol}//${window.location.host}${url(path.substring(1))}`,
            );
            userPic.searchParams.append('enterpriseId', enterpriseId);
        } catch (e) {
            /* eslint-env node */
            if (process.env.NODE_ENV !== 'production') {
                window.console.error(e);
            }
        }
    }
    return userPic ? userPic.toString() : path;
}
