import React from 'react';
import Paper from '@ff/web-components/components/Paper';
import Box from '@ff/web-components/components/Box';
import Divider from '@ff/web-components/components/Divider';
import Root from './style';
import Skeleton from '@ff/web-components/components/Skeleton';
import useTheme from '@/hooks/useTheme';

const AuthSkeleton = () => {
    const {c} = useTheme();

    return (
        <Box pt={14}>
            <Root>
                <Paper py={5} px={2.5}>
                    <Box mb={5} df fd="column" ai="center">
                        <Box df ai="center" w="40%" h={c.text.lineHeight.xl}>
                            <Skeleton w="100%" h={c.text.fontSize.xl} />
                        </Box>
                        <Box df ai="center" w="60%" h={c.text.lineHeight.xl}>
                            <Skeleton w="100%" h={c.text.fontSize.xl} />
                        </Box>
                    </Box>
                    <Box mb={3.24}>
                        <Skeleton h={7} w="100%" />
                    </Box>
                    <Box mb={5}>
                        <Skeleton h={6.5} w="100%" />
                    </Box>
                    <Box mb={5}>
                        <Divider />
                    </Box>
                    <Box mb={3} df jc="center">
                        <Skeleton h="128px" w="128px" />
                    </Box>
                    <Box df ai="center" h={c.text.lineHeight.xs}>
                        <Skeleton w="100%" h={c.text.fontSize.xs} />
                    </Box>
                </Paper>
            </Root>
        </Box>
    );
};

export default AuthSkeleton;
