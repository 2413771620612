import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const ArrowDown: React.FC<IProps> = ({color, width = '16px', height = '16px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m2.293 6.207 1.414-1.414L8 9.086l4.293-4.293 1.414 1.414L8 11.914 2.293 6.207Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(ArrowDown, 'icon', 'ArrowDown');
