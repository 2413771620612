import React from 'react';
import Box from '@ff/web-components/components/Box';
import Skeleton from '@ff/web-components/components/Skeleton';

const FormSkeleton = () => {
    return (
        <Box df fd="column" gap={2.5} mb={3}>
            <Skeleton h={7} />
            <Skeleton h={7} />
            <Skeleton h={7} />
            <Skeleton h={7} />
            <Skeleton h={7} />
        </Box>
    );
};

export default FormSkeleton;
