import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Globe: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.374 4.384C11.678 4.053 11.89 4 12 4c.109 0 .322.053.626.384.306.332.627.868.917 1.614.496 1.276.846 3.02.935 5.002H9.522c.09-1.982.439-3.726.935-5.002.29-.746.611-1.282.917-1.614ZM7.52 11c.09-2.189.475-4.187 1.073-5.726.085-.22.176-.432.272-.636A8.008 8.008 0 0 0 4.062 11H7.52Zm-3.458 2H7.52c.09 2.189.475 4.187 1.073 5.726.085.22.176.432.272.636A8.008 8.008 0 0 1 4.062 13Zm5.46 0h4.956c-.09 1.982-.439 3.726-.935 5.002-.29.746-.611 1.282-.917 1.614-.304.331-.517.384-.626.384-.109 0-.322-.053-.626-.384-.306-.332-.627-.868-.917-1.614-.496-1.276-.846-3.02-.935-5.002Zm6.958 0c-.09 2.189-.474 4.187-1.073 5.726a10.12 10.12 0 0 1-.272.636A8.008 8.008 0 0 0 19.938 13H16.48Zm3.458-2a8.008 8.008 0 0 0-4.803-6.362c.096.204.187.417.272.636.599 1.539.982 3.537 1.073 5.726h3.458ZM22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Globe, 'icon', 'Globe');
