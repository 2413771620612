import styled from 'styled-components';

interface RootProps {
    $theme;
}

const Root = styled.div<RootProps>`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    transition-property: transform, background;
    box-sizing: border-box;
    ${({$theme}) => ({
        ...$theme,
        transitionDuration: $theme.theme.transition.duration,
        transitionTimingFunction: $theme.theme.transition.timingFunction,
        '&:hover': $theme.hover,
        '&:active': $theme.active,
    })};
`;

export default Root;
