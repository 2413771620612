import styled from 'styled-components';

const CategoryIcon = styled.img`
    display: block;
    user-select: none;
    border-radius: 50%;
    overflow: hidden;
    ${({theme}) => ({
        width: theme.c.gutter * 5 + 'px',
        height: theme.c.gutter * 5 + 'px',
    })}
`;

export default CategoryIcon;
