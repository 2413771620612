import React, {useCallback, useEffect, useMemo} from 'react';
import {StepProps} from '@/components/StepController';
import {State} from '@/features/TransferToAccount';
import Box from '@ff/web-components/components/Box';
import FieldError from '@/components/FieldError';
import LoadingButton from '@/components/LoadingButton';
import Layout from '../layout';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';
import {findError, ResponseError} from '@/api/protocol';
import useForm from '@/hooks/useForm';
import useValidation from '@/hooks/useValidation';
import required from '@/hooks/useValidation/required';
import useToggle from '@/hooks/useToggle';
import {useMutation} from '@tanstack/react-query';
import saveForm, {Params, Response as SaveResponse} from '../api/saveReceiver';
import IBANInput from '@ff/web-components/components/Input/IBAN/AZ';
import FormError from '@/components/FormError';

interface IProps extends StepProps {
    data: State;
    onSubmit: (data: Partial<State>) => void;
    onBack?: () => void;
}

const validateIban = (value: string, locale: Dictionary) => {
    if (!value || value.replace(/\s/g, '').length !== 28) {
        return {
            iban: {
                message: locale.account.errors.iban,
            },
        };
    } else if (!value?.toLowerCase().startsWith('az')) {
        return {
            iban: {
                message: locale.account.errors.ibanAZ,
            },
        };
    }
};

const Receiver = ({data, onSubmit, onBack}: IProps) => {
    const locale: Dictionary = getLocale();
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange] = useForm(data);
    const [valid, errors] = useValidation<{iban: State['iban']}>(state, required(['iban']), () =>
        validateIban(state.iban, locale),
    );
    const saveReq = useMutation<SaveResponse, ResponseError, Params>({
        mutationFn: params => saveForm(params),
    });
    const handleSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!valid) {
                toggleValidation(true);
            } else {
                saveReq.mutate({
                    iban: state.iban.replace(/\s/g, ''),
                });
            }
        },
        [state, valid],
    );
    const fieldErrors = useMemo(() => {
        return saveReq.error
            ? {
                  global: findError(saveReq.error),
                  iban: findError(saveReq.error, 'iban'),
              }
            : {};
    }, [saveReq.isError]);

    useEffect(() => {
        const receiver = saveReq.data?.response?.data;
        if (saveReq.isSuccess && receiver) {
            onSubmit({
                receiver,
                iban: state.iban.replace(/\s/g, ''),
                receiverBankCode:
                    receiver.notNativeClientData?.bankList?.length === 1
                        ? receiver.notNativeClientData.bankList[0].bankCode
                        : null,
            });
        }
    }, [saveReq.isSuccess]);

    useEffect(() => {
        if (saveReq.isError) {
            saveReq.reset();
        }
    }, [state]);

    return (
        <Layout
            onBack={onBack}
            title={locale.account.receiver.title}
            subTitle={locale.account.receiver.hint}
        >
            {fieldErrors.global ? <FormError error={[fieldErrors.global]} /> : null}
            <form action="/" method="post" onSubmit={handleSubmit}>
                <Box mb={3}>
                    <IBANInput
                        name="iban"
                        value={state.iban}
                        onChange={onChange}
                        label={locale.account.receiver.iban}
                        error={!!fieldErrors.iban || !!(validation && errors.iban?.message)}
                    />
                    <FieldError>
                        {fieldErrors.iban?.message || (validation && errors.iban?.message)}
                    </FieldError>
                </Box>
                <LoadingButton primary wide type="submit" loading={saveReq.isPending}>
                    {locale.account.receiver.submit}
                </LoadingButton>
            </form>
        </Layout>
    );
};

export default Receiver;
