import React from 'react';
import {Statement} from '@/features/Accounts/api/getStatement';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Money from '@/components/Money';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';
import Divider from '@ff/web-components/components/Divider';
import moment from 'moment';
import categoryIcon from '@/features/Dashboard/utils/categoryIcon';
import {get} from '@/store/runtime';

interface IProps {
    receiver: Statement['content']['title'];
    amount: Statement['value']['amount'];
    commission: Statement['value']['commission'];
    currencySymbol: Statement['value']['currencySymbol'];
    date: moment.Moment;
    balance: Statement['value']['balance'];
    declineReason: Statement['detailContent']['declineReason'];
    icon: Statement['content']['icon'];
    subTitle: Statement['content']['subTitle'];
    badgeColor: Statement['detailContent']['badgeColor'];
}

const DetailsHead = ({
    receiver,
    amount,
    commission,
    currencySymbol,
    date,
    balance,
    declineReason,
    badgeColor,
    subTitle,
    icon,
}: IProps) => {
    const locale: Dictionary = getLocale();
    return (
        <Box df fd="column">
            <Box df fd="column" gap={2} mb={3}>
                {icon ? (
                    <Box jc="center" df>
                        <Box rad="50%" of="hidden" df>
                            <img
                                src={categoryIcon(icon, get('ENTERPRISE'))}
                                width="64"
                                height="64"
                                alt={subTitle?.text}
                            />
                        </Box>
                    </Box>
                ) : null}
                <Box>
                    <Text size="xl" bold align="center">
                        {receiver}
                    </Text>
                </Box>
                {subTitle?.text && badgeColor ? (
                    <Box px={1} py={0.5} rad="20px" style={{background: badgeColor}} dif center>
                        <Text size="xs2" color="white">
                            {subTitle.text}
                        </Text>
                    </Box>
                ) : null}
                <Box jc="center" df fd="column" center>
                    <Box jc="center" df>
                        <Money
                            size="xl7"
                            fractionSize="xl2"
                            bold
                            sum={amount}
                            currency={currencySymbol}
                        />
                    </Box>
                    {!isNaN(commission) ? (
                        <Box mt={0.25} jc="center" df>
                            <Text size="xs2" color="secondary">
                                {commission === 0
                                    ? locale.statements.noCommission
                                    : `${locale.statements.commission} ${commission} ${currencySymbol}`}
                            </Text>
                        </Box>
                    ) : null}
                    {declineReason ? (
                        <Box mt={0.25} jc="center" df>
                            <Text size="xs2" color="error">
                                {declineReason}
                            </Text>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Divider />
            <Box py={2.5} df>
                <Box flex={1} df jc="center" ai="center" fd="column">
                    <Text size="xs2">{locale.statements.balance}</Text>
                    <Text size="xs2">
                        {balance} {currencySymbol}
                    </Text>
                </Box>
                <Box flex={1} df jc="center" ai="center" fd="column">
                    <Text size="xs2">{date.format('D MMMM YYYY')}</Text>
                    <Text size="xs2">{date.format('k:mm')}</Text>
                </Box>
            </Box>
        </Box>
    );
};

export default DetailsHead;
