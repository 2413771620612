import axios from 'axios';
import url from '@/api/url';
import {Statement} from '@/features/Accounts/api/getPayments';

export type Response = {
    status: string;
};

export default async function deletePayment(id: Statement['id']): Promise<Response> {
    return axios.delete(url(`statements/${id}`));
}
