import React from 'react';
import Shortcut from '../style/shortcut';
import {getLocale} from 'utils/l10n';
import moment from 'moment';
import Text from '../../Text';
import defaultTheme from '../theme';

export interface IProps {
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
    theme: Partial<ReturnType<typeof defaultTheme>>;
}

const Yesterday = ({onSelect, theme}: IProps) => {
    return (
        <Shortcut
            onClick={() =>
                onSelect(
                    moment().add(-1, 'day').startOf('date'),
                    moment().add(-1, 'day').endOf('date'),
                )
            }
            $theme={theme}
        >
            <Text size="m" color="primary">
                {getLocale().datePicker?.yesterday}
            </Text>
        </Shortcut>
    );
};

export default Yesterday;
