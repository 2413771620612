import axios from 'axios';
import url from '@/api/url';

export type Response = {
    voen: string;
};

export default async function getInfo(): Promise<Response> {
    return axios.get(url('tax/payer-info'));
}
