import React, {SyntheticEvent, useCallback, useEffect, useMemo} from 'react';
import Root from './style';
import Paper from '@ff/web-components/components/Paper';
import Button from '@ff/web-components/components/Button';
import Box from '@ff/web-components/components/Box';
import Text from '@ff/web-components/components/Text';
import Input from '@ff/web-components/components/Input';
import PointerLeft from '@ff/web-components/components/Icon/PointerLeft';
import sms from '../img/sms.svg';
import {getLocale} from '@ff/web-components/utils/l10n';
import useForm from '@/hooks/useForm';
import Alternate from '@/features/Auth/sms/alternate';
import FieldError from '@/components/FieldError';
import {onBack, onSubmit} from '@/features/Auth';
import useToggle from '@/hooks/useToggle';
import useValidation from '@/hooks/useValidation';
import {useMutation} from '@tanstack/react-query';
import sendSMS, {RequestData, Response} from '@/features/Auth/api/sendSMS';
import {findError, ResponseError} from '@/api/protocol';
import LoadingButton from '@/components/LoadingButton';
import format from '@/hooks/useValidation/format';
import {IVROptions} from '@/features/Auth/api/sendLogin';
import {Dictionary} from '@/locale';

interface IProps {
    onSubmit: onSubmit;
    options?: {
        login?: string;
        ivr?: IVROptions;
    };
    onBack?: onBack;
    reqKey: string;
}

interface State {
    otp: string;
}

const codeFormat = /^\d{4}$/;

const SMS = ({onSubmit, options, onBack, reqKey}: IProps) => {
    const locale: Dictionary['auth']['sms'] = getLocale().auth.sms;
    const [validation, toggleValidation] = useToggle(false);
    const [state, onChange] = useForm<State>({otp: ''});
    const [valid, errors] = useValidation<State>(state, format<State>(['otp'], codeFormat));
    const sendReq = useMutation<Response, ResponseError, RequestData>({
        mutationFn: sendSMS,
    });
    const handleSubmit = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            if (valid) {
                sendReq.mutate({...state, key: reqKey});
            } else {
                toggleValidation(true);
            }
        },
        [valid, state, sendReq],
    );
    const fieldErrors = useMemo(() => {
        return sendReq.error
            ? {
                  global: findError(sendReq.error),
                  otp: findError(sendReq.error, 'otp'),
              }
            : {};
    }, [sendReq.isError]);

    useEffect(() => {
        if (sendReq.isSuccess) {
            onSubmit(sendReq.data);
        }
    }, [sendReq.isSuccess]);

    useEffect(() => {
        if (sendReq.isError) {
            sendReq.reset();
        }
    }, [state]);

    return (
        <Box mt={14}>
            <Root>
                <Paper pb={5} pt={2.5} px={2.5}>
                    <Box mb={1}>
                        <Button round small secondary onClick={onBack}>
                            <PointerLeft />
                        </Button>
                    </Box>
                    {fieldErrors.global ? (
                        <Box mb={2}>
                            <Text align="center" color="error">
                                {fieldErrors.global.message}
                            </Text>
                        </Box>
                    ) : null}
                    <Box df jc="center" mb={2}>
                        <img src={sms} alt="Leobank" width="64px" height="64px" />
                    </Box>
                    <Box mb={1}>
                        <Text size="xl" align="center" bold>
                            {locale.header}
                        </Text>
                    </Box>
                    <Box mb={2.5}>
                        <Text size="xs" color="secondary" align="center">
                            {locale.hint}
                        </Text>
                        <Text size="xs" color="secondary" align="center">
                            {options?.login}
                        </Text>
                    </Box>
                    <form method="POST" action="/" onSubmit={handleSubmit}>
                        <Box mb={2.5}>
                            <Input
                                name="otp"
                                value={state.otp}
                                onChange={onChange}
                                label={locale.label}
                                error={sendReq.isError || (validation && !!errors.otp)}
                                maxLength={4}
                            />
                            <FieldError>
                                {(validation && errors.otp?.message) || fieldErrors.otp?.message}
                            </FieldError>
                        </Box>
                        <Box mb={2.5}>
                            <LoadingButton primary type="submit" loading={sendReq.isPending} wide>
                                {locale.btnSubmit}
                            </LoadingButton>
                        </Box>
                        {options?.ivr?.available ? <Alternate delay={options.ivr.delay} /> : null}
                    </form>
                </Paper>
            </Root>
        </Box>
    );
};

export default SMS;
