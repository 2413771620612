'use client';
'use strict';
import React, {useEffect, useRef} from 'react';
import getScrollWidth from 'utils/getScrollWidth';
import ReactDOM from 'react-dom';
import Global from './style/global';

export interface IProps {
    children?: React.ReactNode;
}

let scrollWidth;

const ModalBase = ({children}: IProps) => {
    const root = useRef<HTMLDivElement>(document.createElement('div'));

    useEffect(() => {
        if (!scrollWidth) {
            scrollWidth = getScrollWidth();
        }

        document.body.appendChild(root.current);

        return () => {
            document.body.removeChild(root.current);
        };
    }, []);

    return ReactDOM.createPortal(
        <>
            <Global $scrollWidth={scrollWidth} $paddingRight={document.body.style.paddingRight} />
            {children}
        </>,
        root.current,
    );
};

export default ModalBase;
