import React, {useEffect} from 'react';
import Head from './head';
import Root from './style/root';
import Statement from './statement';
import {pageView} from '@/utils/gtag';

const Dashboard = () => {
    useEffect(() => {
        pageView({path: '/dashboard', title: 'Dashboard'});
    }, []);

    return (
        <Root>
            <Head />
            <Statement />
        </Root>
    );
};

export default Dashboard;
