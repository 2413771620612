import {Theme} from 'themes/index';
import STATE from '../../../globals';

const dropDownSearchTheme = function (theme: Theme) {
    return {
        theme,
        background: theme.palette?.grey['200'],
        fontFamily: theme.text.fontFamily,
        fontSize: theme.text.fontSize.m,
        lineHeight: theme.text.lineHeight.m,
        color: theme.palette.text.primary,
        borderRadius: (theme.gutter ?? 0) * 2 + 'px',
        placeholder: theme.palette.text.secondary,
        [STATE.HOVER]: {
            background: theme.palette.grey['400'],
        },
        [STATE.FOCUS]: {
            background: theme.palette.grey['400'],
        },
        [STATE.ERROR]: {
            background: theme.palette.error['600'],
        },
        [STATE.DISABLED]: {
            background: theme.palette?.grey['200'],
            opacity: 0.3,
        },
        label: {
            fontFamily: theme.text.fontFamily,
            fontSize: theme.text.fontSize.xs3,
            lineHeight: theme.text.lineHeight.xs3,
            color: theme.palette.text.secondary,
            [STATE.ERROR]: {
                color: theme.palette.error['900'],
            },
        },
        list: {
            maxHeight: (theme.gutter ?? 0) * 50 + 'px',
            background: theme.palette.background.b2,
        },
    };
};

export default dropDownSearchTheme;

export type IDropDownSearchTheme = ReturnType<typeof dropDownSearchTheme>;
