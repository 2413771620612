import styled from 'styled-components';
import scrollbar from 'themes/utils/scrollbar';

interface SideProps {
    $theme;
}

const Side = styled.div<SideProps>`
    overflow-y: scroll;
    max-height: 100%;
    ${({$theme}) => ({
        marginRight: $theme.theme.gutter / 2 + 'px',
        ...scrollbar($theme.theme),
    })}
`;

export default Side;
