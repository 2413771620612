import React from 'react';
import Box from '@ff/web-components/components/Box';
import Paper from '@ff/web-components/components/Paper';
import Skeleton from '@ff/web-components/components/Skeleton';
import useResponsive from '@/hooks/useResponsive';

const User = () => (
    <Box py={1.25} px={2.5} df ai="center">
        <Skeleton w={5} h={5} rad="50%" mr={2.5} fs={0} />
        <Skeleton h={2.5} fg={2} />
    </Box>
);

const AccountsSkeleton = () => {
    const {isMobile} = useResponsive();

    return (
        <Box pt={8} w={isMobile ? '100%' : 46} center>
            <Paper py={5}>
                <User />
                <User />
                <User />
            </Paper>
        </Box>
    );
};

export default AccountsSkeleton;
