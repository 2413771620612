import styled from 'styled-components';

const UserPicture = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
`;

export default UserPicture;
