import {Rule, ValidationError} from './index';
import {getLocale} from '@ff/web-components/utils/l10n';
import {Dictionary} from '@/locale';

interface Options {
    message: string;
}

const format = /^\+?[0-9]{11,12}$/i;

export default function phone<T extends object>(
    keys: Array<Partial<keyof T>>,
    options?: Options,
): Rule<T> {
    return (state: T): ReturnType<Rule<T>> => {
        let result: ReturnType<Rule<T>>;
        const locale: Dictionary['app'] = getLocale().app;

        keys.forEach(name => {
            if (state && name in state && typeof state[name] === 'string') {
                if (!format.test(state[name] as string)) {
                    result = (result || {}) as ValidationError<T>;
                    result[name] = {
                        message: options?.message || locale.validation.phone,
                        name: name as string,
                    };
                }
            }
        });

        return result;
    };
}
