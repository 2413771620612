import ptn from 'themes/utils/pixelToNumber';

export default function transformLabel({from, to, offset}) {
    const ratio = ptn(to) / ptn(from);
    return {
        transform: `scale(${ratio}) translate(0px, ${offset})`,
        // reduce label width after scale increasing.
        maxWidth: `${(100 / (ratio * 100)) * 100}%`,
    };
}
