export default function parse(val: string): number | typeof NaN {
    let parsed = NaN;

    if (val) {
        val = val.replace(/,/g, '.').replace(/[^0-9.-]/g, '');
        parsed = parseFloat(val);
    }

    return parsed;
}
