import styled from 'styled-components';

interface LabelHolderProps {
    $theme: {
        fontSize: string;
        lineHeight: string | number;
    };
}

const LabelHolder = styled.div<LabelHolderProps>`
    ${({$theme}) => ({
        fontSize: $theme.fontSize,
        lineHeight: $theme.lineHeight,
    })}
`;

export default LabelHolder;
