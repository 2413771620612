import styled from 'styled-components';

interface RootProps {
    $theme;
    $withLabel;
}

const InputWrapper = styled.div<RootProps>`
    ${({$theme, $withLabel}) => ({
        paddingTop: $withLabel ? $theme.theme.gutter / 4 + 'px' : '10px',
        paddingBottom: $withLabel ? $theme.theme.gutter + 'px' : '10px',
    })};
    width: 100%;
    min-width: 100%;
    position: relative;
    box-sizing: border-box;
`;

export default InputWrapper;
