import {useMemo} from 'react';

export type ValidationError<T> = {
    [Property in keyof T]?: {
        message: string;
        name?: string;
    };
};

export type Rule<T> = (state: T) => ValidationError<T> | undefined;

export default function useValidation<T extends object>(
    state: T,
    ...rules: Rule<T>[]
): [boolean, ValidationError<T>] {
    return useMemo(() => {
        let errors = {} as ValidationError<T>,
            valid = true;

        if (state && typeof state === 'object' && Array.isArray(rules) && rules.length) {
            rules = rules.reverse();
            rules.forEach(rule => {
                if (typeof rule === 'function') {
                    const e = rule(state);

                    if (e && typeof e === 'object') {
                        valid = false;
                        errors = {...errors, ...e};
                    }
                }
            });

            return [valid, errors];
        }
    }, [state, ...rules]);
}
