import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from './header';
import Root from '../style/layout';

const Layout = () => (
    <Root>
        <Header />
        <section>
            <Outlet />
        </section>
    </Root>
);

export default Layout;
