import React, {useMemo, useRef} from 'react';
import {Response} from '@/features/Accounts/api/getStatement';
import groupBy from 'lodash/groupBy';
import DateGroup from '@/features/Dashboard/statement/list/dateGroup';
import moment from 'moment';
import {Response as PaymentsResponse} from '@/features/Accounts/api/getPayments';

interface IProps {
    collection: Response['data'];
    payments: PaymentsResponse['data'];
}

const List = ({collection, payments}: IProps) => {
    const byDates = useMemo(
        () => groupBy(collection, item => moment(item.dateTs).format('DD.MM.YYYY')),
        [collection],
    );
    const root = useRef<HTMLDivElement>();

    return (
        <div ref={root}>
            {Object.keys(byDates).map((date, i) => (
                <DateGroup
                    list={byDates[date]}
                    key={date}
                    root={root}
                    payments={i === 0 ? payments : undefined}
                />
            ))}
        </div>
    );
};

export default List;
