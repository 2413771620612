import React from 'react';
import Shortcut from '../style/shortcut';
import {getLocale} from 'utils/l10n';
import moment from 'moment';
import Text from '../../Text';
import defaultTheme from '../theme';

export interface IProps {
    onSelect: (start: moment.Moment, end: moment.Moment) => void;
    theme: Partial<ReturnType<typeof defaultTheme>>;
}

const PrevWeek = ({onSelect, theme}: IProps) => {
    return (
        <Shortcut
            $theme={theme}
            onClick={() =>
                onSelect(
                    moment().add(-1, 'week').startOf('week'),
                    moment().add(-1, 'week').endOf('week'),
                )
            }
        >
            <Text size="m" color="primary">
                {getLocale().datePicker?.prevWeek}
            </Text>
        </Shortcut>
    );
};

export default PrevWeek;
