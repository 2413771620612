import React from 'react';
import theme from '../theme';
import defaultTheme from './theme';
export interface IProps {
    color?: string;
    width?: string;
    height?: string;
    theme?: Partial<ReturnType<typeof defaultTheme>>;
}

const s = {display: 'flex'};

const Paper: React.FC<IProps> = ({color, width = '24px', height = '24px', theme}: IProps) => {
    color = color || ('#000' === '#000' ? theme.theme?.palette?.grey['1000'] : '#000');

    return (
        <div style={s}>
            <svg
                height={height}
                width={width}
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 2H4v20h16V2H5Zm1 18V4h12v16H6ZM8 9h8V7H8v2Zm8 4H8v-2h8v2Zm-8 4h5v-2H8v2Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default theme<IProps, HTMLDivElement>(Paper, 'icon', 'Paper');
