import axios from 'axios';
import {Step} from '@/features/Auth';
import url from '@/api/url';

export interface IVROptions {
    available: boolean;
    delay: number;
}

export interface Response {
    result: Step;
    options?: {
        ivr?: IVROptions;
    };
    key: string;
}

export interface RequestData {
    login: string;
}

export default async function sendLogin(payload: RequestData): Promise<Response> {
    return axios.post(url('auth/login'), payload);
}
