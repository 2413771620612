import axios from 'axios';
import {
    ST_STATUS_DECLINE,
    ST_STATUS_PENDING,
    ST_STATUS_SUCCESS,
} from '@/features/Accounts/constants';
import url from '@/api/url';
import {Category} from '@/features/Payments/api/getCategories';
import moment from 'moment';

export type Status = typeof ST_STATUS_SUCCESS | typeof ST_STATUS_DECLINE | typeof ST_STATUS_PENDING;

export type DetailsButton = {
    action: 'share' | 'askQuestion' | 'moneybox';
    title?: string;
};

export type Param = {
    name: string;
    value: string;
    isCopyable: boolean;
};

export type Statement = {
    id: string;
    type: 'statement';
    content: {
        icon: string;
        title: string;
        subTitle: {
            text: string;
        };
    };
    detailContent: {
        badgeColor: string;
        buttons: DetailsButton[];
        declineReason?: string;
        categoryId: Category['id'];
        params: Param[];
    };
    value: {
        currencySymbol: string;
        amount: number;
        balance: number;
        commission: number;
    };
    dateTs: number;
    status: Status;
    date: moment.Moment;
    isDeletable: boolean;
};

export interface Params {
    tsFrom?: number;
    tsTo?: number;
    searchString?: string;
    size: number;
    page: number;
    cardId?: string;
}

export type Response = {
    data: Statement[];
    lastPage: boolean;
};

export default async function getStatement(params: Params): Promise<Response> {
    return axios.get(url('statements'), {
        params,
        transformResponse: (resp: string, headers, code) => {
            if (code === 200) {
                try {
                    const parsed = JSON.parse(resp);
                    if (parsed.status === 'SUCCESS' && parsed.data?.length) {
                        parsed.lastPage = parsed.data.length < params.size;
                        parsed.data = parsed.data.filter(
                            ({type}: Statement) => type === 'statement',
                        );
                        parsed.data.forEach((item: Statement) => {
                            item.date = moment(item.dateTs);
                        });
                    }
                    return parsed;
                } catch (e) {
                    throw new Error('empty data');
                }
            } else {
                return resp;
            }
        },
    });
}
