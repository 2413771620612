import styled from 'styled-components';

const User = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition-property: background;
    ${({theme}) => ({
        padding: `${theme.c.gutter * 1.25}px ${theme.c.gutter * 2.5}px`,
        transitionDuration: theme.c.transition.duration,
        transitionTimingFunction: theme.c.transition.timingFunction,
        '&:hover': {
            background: theme.c.palette.grey['200'],
        },
    })}
`;

export default User;
