import Today from './today';
import Yesterday from './yesterday';
import Week from './week';
import PrevWeek from './prevWeek';
import Group from './group';
import {getLocale} from 'utils/l10n';
import React from 'react';

export default {
    today: Today,
    yesterday: Yesterday,
    week: Week,
    prevWeek: PrevWeek,
    month: props => <Group {...props} name={getLocale().datePicker?.month} />,
    quarter: props => <Group {...props} name={getLocale().datePicker?.quarter} />,
    year: props => <Group {...props} name={getLocale().datePicker?.year} />,
    manual: props => <Group {...props} name={getLocale().datePicker?.manual} />,
};
