import styled from 'styled-components';
import scrollbar from 'themes/utils/scrollbar';
import {Theme} from 'themes/index';

interface ListProps {
    $theme: {
        maxHeight: string;
        theme: Theme;
    };
}

const List = styled.div<ListProps>`
    ${({$theme}) => ({
        maxHeight: $theme.maxHeight,
        overflowY: 'auto',
        ...scrollbar($theme.theme),
    })}
`;

export default List;
